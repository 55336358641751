import { Close as CloseIcon } from "@mui/icons-material";
import { Button, Drawer, Stack, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { experiencesKeys } from "api/experiences/queries";
import { editFeature as editFeatureAPI } from "api/features";
import { featuresKeys } from "api/features/queries";
import { EditFeatureType } from "api/features/types";
import { Feature } from "api/generated/models/Feature";

import { featureBackgroundColor } from "../../formatters/FeaturesFormatter";
import FeatureAbadaForm from "./SingleFeatureForm/FeatureAbada";
import FeatureAccessForm from "./SingleFeatureForm/FeatureAccessForm";
import FeatureAdminAvatarsForm from "./SingleFeatureForm/FeatureAdminAvatars";
import FeatureAICourseForm from "./SingleFeatureForm/FeatureAICourse";
import FeatureCameraChest from "./SingleFeatureForm/FeatureCameraChest";
import FeatureCameraPresentationForm from "./SingleFeatureForm/FeatureCameraPresentationForm";
import FeatureChatlog from "./SingleFeatureForm/FeatureChatlog";
import FeatureCollectablesForm from "./SingleFeatureForm/FeatureCollectables";
import FeatureExhibitForm from "./SingleFeatureForm/FeatureExhibitForm";
import FeatureExhibitImagesForm from "./SingleFeatureForm/FeatureExhibitImages";
import FeatureExperienceAvatarsForm from "./SingleFeatureForm/FeatureExperienceAvatars";
import FeatureGameForm from "./SingleFeatureForm/FeatureGameForm";
import FeatureHelpForm from "./SingleFeatureForm/FeatureHelpForm";
import FeatureInfoPinForm from "./SingleFeatureForm/FeatureInfoPinForm";
import FeatureLeaderboardForm from "./SingleFeatureForm/FeatureLeaderboardForm";
import FeatureLinkButtonForm from "./SingleFeatureForm/FeatureLinkButtonForm";
import FeatureParticipationForm from "./SingleFeatureForm/FeatureParticipationForm";
import FeaturePortalForm from "./SingleFeatureForm/FeaturePortalForm";
import FeaturePresentationForm from "./SingleFeatureForm/FeaturePresentationForm";
import FeatureQueueForm from "./SingleFeatureForm/FeatureQueueForm";
import FeatureQuizForm from "./SingleFeatureForm/FeatureQuizForm";
import FeatureRedirectForm from "./SingleFeatureForm/FeatureRedirectForm";
import FeatureRestrictedPenDrawingForm from "./SingleFeatureForm/FeatureRestrictedPenDrawingForm";
import FeatureTeleportationPanelForm from "./SingleFeatureForm/FeatureTeleportationPanelForm";
import FeatureTreasureHuntForm from "./SingleFeatureForm/FeatureTreasureHuntForm";
import FeatureVoiceInstallation from "./SingleFeatureForm/FeatureVoiceInstallation";

interface IFeatureDrawer {
  isOpen: boolean;
  onClose: () => void;
  feature: (Feature & { link: string }) | undefined;
  handleOpenDelete: (ctx?: Feature) => void;
}

export const featureTitleFormatted = {
  access: <FormattedMessage id="FEATURES.ACCESS" />,
  chatlog: <FormattedMessage id="FEATURES.CHATLOG" />,
  "experience-avatars": <FormattedMessage id="FEATURES.EXPERIENCE_AVATARS" />,
  "admin-avatars": <FormattedMessage id="FEATURES.ADMIN_AVATARS" />,
  leaderboard: <FormattedMessage id="FEATURES.LEADERBOARD" />,
  "camera-chest": <FormattedMessage id="FEATURES.CAMERA_CHEST" />,
  "camera-presentation": <FormattedMessage id="FEATURES.CAMERA_PRESENTATION" />,
  portal: <FormattedMessage id="FEATURES.PORTAL" />,
  "restricted-pen-drawing": (
    <FormattedMessage id="FEATURES.RESTRICTED_PEN_DRAWING" />
  ),
  "info-pin": <FormattedMessage id="FEATURES.INFO_PIN" />,
  participation: <FormattedMessage id="FEATURES.PARTICIPATION" />,
  "link-button": <FormattedMessage id="FEATURES.LINK_BUTTON" />,
  presentation: <FormattedMessage id="FEATURES.PRESENTATION" />,
  exhibit: <FormattedMessage id="FEATURES.EXHIBIT" />,
  "teleportation-panel": <FormattedMessage id="FEATURES.TELEPORTATION_PANEL" />,
  "clear-drawing": <FormattedMessage id="FEATURES.CLEAR_DRAWING" />,
  redirect: <FormattedMessage id="FEATURES.REDIRECT" />,
  "voice-installation": <FormattedMessage id="FEATURES.VOICE_INSTALLATION" />,
  "ai-course": <FormattedMessage id="FEATURES.AI_COURSE" />,
  quiz: <FormattedMessage id="FEATURES.QUIZ" />,
  collectables: <FormattedMessage id="FEATURES.COLLECTABLES" />,
  "exhibit-images": <FormattedMessage id="FEATURES.EXHIBIT_IMAGES" />,
  "treasure-hunt": <FormattedMessage id="FEATURES.TREASURE_HUNT" />,
  help: <FormattedMessage id="FEATURES.HELP" />,
  abada: <FormattedMessage id="FEATURES.ABADA" />,
  tutorial: <FormattedMessage id="FEATURES.TUTORIAL" />,
  queue: <FormattedMessage id="FEATURES.QUEUE" />,
  game: <FormattedMessage id="FEATURES.GAME" />,
};

const FeatureDrawer: FC<IFeatureDrawer> = ({
  isOpen,
  onClose,
  feature,
  handleOpenDelete,
}) => {
  const queryClient = useQueryClient();

  const { mutateAsync: editFeature, status } = useMutation(
    async (data: EditFeatureType) => {
      const res = await editFeatureAPI(data);
      await queryClient.invalidateQueries(experiencesKeys.list());
      await queryClient.invalidateQueries(featuresKeys.details(data._id));
      return res.data;
    }
  );

  const handleSubmit = async (values: any) => {
    await editFeature(
      { ...values, _id: feature?._id },
      {
        onSuccess: () => onClose(),
      }
    );
  };

  return (
    <Drawer
      PaperProps={{
        sx: {
          resize: "horizontal",
          width: { xs: "100%", lg: "950px" },
          // p: { xs: 2, sm: 3 },
          borderRadius: 0,
          backgroundColor: (t) => t.palette.background.default,
        },
      }}
      variant="temporary"
      anchor="right"
      open={isOpen}
      onClose={onClose}
      data-cy="user-drawer"
    >
      <Stack direction="column" sx={{ height: "100%" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            background: feature?.type
              ? featureBackgroundColor[feature.type]
              : "#000",
            p: 1,
          }}
        >
          <Typography variant="h4" sx={{ p: 1 }}>
            {feature?.type ? featureTitleFormatted[feature?.type] : ""}
          </Typography>
          <Button onClick={onClose}>
            <CloseIcon sx={{ color: "#fff" }} />
          </Button>
        </Stack>

        {/* CONTENT */}
        {feature?.type === "access" && (
          <FeatureAccessForm
            featureId={feature._id}
            link={feature.link}
            handleSubmit={handleSubmit}
            submitStatus={status}
            handleOpenDelete={() => handleOpenDelete(feature)}
            initialValues={{ ...feature, schema: feature?.schema ?? [] }}
          />
        )}
        {feature?.type === "portal" && (
          <FeaturePortalForm
            handleSubmit={handleSubmit}
            submitStatus={status}
            handleOpenDelete={() => handleOpenDelete(feature)}
            initialValues={{ ...feature, schema: feature?.schema ?? [] }}
          />
        )}
        {feature?.type === "leaderboard" && (
          <FeatureLeaderboardForm
            handleSubmit={handleSubmit}
            submitStatus={status}
            handleOpenDelete={() => handleOpenDelete(feature)}
            initialValues={{ ...feature, schema: feature?.schema ?? [] }}
          />
        )}
        {/* CAMERA_CHEST -- NE FORMA */}
        {feature?.type === "camera-chest" && (
          <FeatureCameraChest
            handleOpenDelete={() => handleOpenDelete(feature)}
            schema={feature?.schema ?? []}
          />
        )}
        {/* CHATLOG -- NE FORMA */}
        {feature?.type === "chatlog" && (
          <FeatureChatlog
            featureId={feature._id}
            handleOpenDelete={() => handleOpenDelete(feature)}
            schema={feature?.schema ?? []}
          />
        )}
        {feature?.type === "restricted-pen-drawing" && (
          <FeatureRestrictedPenDrawingForm
            handleSubmit={handleSubmit}
            submitStatus={status}
            handleOpenDelete={() => handleOpenDelete(feature)}
            initialValues={{ ...feature, schema: feature?.schema ?? [] }}
          />
        )}
        {/* TELEPORTATION PANEL */}
        {feature?.type === "teleportation-panel" && (
          <FeatureTeleportationPanelForm
            handleSubmit={handleSubmit}
            submitStatus={status}
            handleOpenDelete={() => handleOpenDelete(feature)}
            initialValues={{ ...feature, schema: feature?.schema ?? [] }}
          />
        )}

        {/* Treasure hunt */}
        {feature?.type === "treasure-hunt" && (
          <FeatureTreasureHuntForm
            handleSubmit={handleSubmit}
            submitStatus={status}
            handleOpenDelete={() => handleOpenDelete(feature)}
            initialValues={{ ...feature, schema: feature?.schema ?? [] }}
          />
        )}
        {feature?.type === "experience-avatars" && (
          <FeatureExperienceAvatarsForm
            handleSubmit={handleSubmit}
            submitStatus={status}
            handleOpenDelete={() => handleOpenDelete(feature)}
            initialValues={{ ...feature, schema: feature?.schema ?? [] }}
          />
        )}

        {feature?.type === "admin-avatars" && (
          <FeatureAdminAvatarsForm
            handleSubmit={handleSubmit}
            submitStatus={status}
            handleOpenDelete={() => handleOpenDelete(feature)}
            initialValues={{ ...feature, schema: feature?.schema ?? [] }}
          />
        )}

        {feature?.type === "help" && (
          <FeatureHelpForm
            handleSubmit={handleSubmit}
            submitStatus={status}
            handleOpenDelete={() => handleOpenDelete(feature)}
            initialValues={{
              ...feature,
              information: feature?.information,
            }}
          />
        )}

        {feature?.type === "voice-installation" && (
          <FeatureVoiceInstallation
            featureId={feature._id}
            handleOpenDelete={() => handleOpenDelete(feature)}
            schema={feature?.schema ?? []}
          />
        )}

        {feature?.type === "ai-course" && (
          <FeatureAICourseForm
            featureId={feature._id}
            handleSubmit={handleSubmit}
            submitStatus={status}
            handleOpenDelete={() => handleOpenDelete(feature)}
            initialValues={{ ...feature, schema: feature?.schema ?? [] }}
          />
        )}

        {feature?.type === "queue" && (
          <FeatureQueueForm
            handleSubmit={handleSubmit}
            submitStatus={status}
            handleOpenDelete={() => handleOpenDelete(feature)}
            initialValues={{
              ...feature,
              schema: feature?.schema ?? [],
              users: feature?.users ?? [],
            }}
          />
        )}

        {feature?.type === "quiz" && (
          <FeatureQuizForm
            featureId={feature._id}
            handleSubmit={handleSubmit}
            submitStatus={status}
            handleOpenDelete={() => handleOpenDelete(feature)}
            initialValues={{
              ...feature,
              schema: feature?.schema ?? [],
              users: feature?.users ?? [],
            }}
          />
        )}

        {/* PRESENTATION */}
        {feature?.type === "presentation" && (
          <FeaturePresentationForm
            handleSubmit={handleSubmit}
            submitStatus={status}
            handleOpenDelete={() => handleOpenDelete(feature)}
            initialValues={{ ...feature, schema: feature?.schema ?? [] }}
          />
        )}
        {/* PARTICIPATION */}
        {feature?.type === "participation" && (
          <FeatureParticipationForm
            handleSubmit={handleSubmit}
            submitStatus={status}
            handleOpenDelete={() => handleOpenDelete(feature)}
            initialValues={{ ...feature, schema: feature?.schema ?? [] }}
          />
        )}
        {/* CAMERA PRESENTATION */}
        {feature?.type === "camera-presentation" && (
          <FeatureCameraPresentationForm
            handleSubmit={handleSubmit}
            submitStatus={status}
            handleOpenDelete={() => handleOpenDelete(feature)}
            initialValues={{ ...feature, schema: feature?.schema ?? [] }}
          />
        )}
        {/* LINK_BUTTON */}
        {feature?.type === "link-button" && (
          <FeatureLinkButtonForm
            handleSubmit={handleSubmit}
            submitStatus={status}
            handleOpenDelete={() => handleOpenDelete(feature)}
            initialValues={{ ...feature, schema: feature?.schema ?? [] }}
          />
        )}
        {feature?.type === "info-pin" && (
          <FeatureInfoPinForm
            handleSubmit={handleSubmit}
            submitStatus={status}
            handleOpenDelete={() => handleOpenDelete(feature)}
            initialValues={{ ...feature, schema: feature?.schema ?? [] }}
          />
        )}

        {feature?.type === "game" && (
          <FeatureGameForm
            handleSubmit={handleSubmit}
            submitStatus={status}
            handleOpenDelete={() => handleOpenDelete(feature)}
            initialValues={{
              ...feature,
              information: feature?.information,
            }}
          />
        )}

        {feature?.type === "redirect" && (
          <FeatureRedirectForm
            handleSubmit={handleSubmit}
            submitStatus={status}
            handleOpenDelete={() => handleOpenDelete(feature)}
            initialValues={{
              ...feature,
              information: feature?.information,
            }}
          />
        )}
        {/* EXHIBIT */}
        {feature?.type === "exhibit" && (
          <FeatureExhibitForm
            handleSubmit={handleSubmit}
            submitStatus={status}
            handleOpenDelete={() => handleOpenDelete(feature)}
            initialValues={{ ...feature, schema: feature?.schema ?? [] }}
          />
        )}

        {feature?.type === "collectables" && (
          <FeatureCollectablesForm
            featureId={feature._id}
            handleSubmit={handleSubmit}
            submitStatus={status}
            handleOpenDelete={() => handleOpenDelete(feature)}
            initialValues={{
              ...feature,
              information: feature?.information,
              schema: feature?.schema ?? [],
            }}
          />
        )}

        {feature?.type === "abada" && (
          <FeatureAbadaForm
            handleSubmit={handleSubmit}
            submitStatus={status}
            handleOpenDelete={() => handleOpenDelete(feature)}
            initialValues={{ ...feature, schema: feature?.schema ?? [] }}
          />
        )}

        {feature?.type === "exhibit-images" && (
          <FeatureExhibitImagesForm
            handleSubmit={handleSubmit}
            submitStatus={status}
            handleOpenDelete={() => handleOpenDelete(feature)}
            initialValues={{ ...feature, schema: feature?.schema ?? [] }}
          />
        )}
      </Stack>
    </Drawer>
  );
};

export default FeatureDrawer;
