import { Paper } from "@mui/material";
import React from "react";

import MapsHeader from "./MapsHeader/MapsHeader";
import MapsTable from "./MapsTable";

const MapsPage = () => {
  return (
    <>
      <Paper>
        <MapsHeader />
        <MapsTable />
      </Paper>
    </>
  );
};

export default MapsPage;
