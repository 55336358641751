import { TextField } from "@mui/material";
import { ChangeEventHandler, FC, useEffect, useState } from "react";
import { CellProps } from "react-table";

interface IEditableCell extends CellProps<any> {}

// Create an editable cell renderer
export const EditableCell: FC<IEditableCell> = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateData, // This is a custom function that we supplied to our table instance
}) => {
  const [value, setValue] = useState(initialValue);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <TextField
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      fullWidth
      variant="standard"
    />
  );
};
