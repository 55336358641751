import { Paper } from "@mui/material";

import RewardsHeader from "./RewardsHeader/RewardsHeader";
import RewardsTable from "./RewardsTable";

const RewardsPage = () => {
  return (
    <>
      <Paper>
        <RewardsHeader />
        <RewardsTable />
      </Paper>
    </>
  );
};

export default RewardsPage;
