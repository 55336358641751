import { Stack } from "@mui/material";
import { CRS } from "leaflet";
import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useHistory, useLocation } from "react-router-dom";

import { MapPointType } from "api/map/types";

import DashboardMapClick from "./DashboardMapClick";
import DashboardMapSales from "./DashboardMapSales";
import DashboardMapSizeFilter from "./DashboardMapSizeFilter";
import DashboardMapURL from "./DashboardMapURL";
import DashboardMapPopup from "./Popup/DashboardMapPopup";
import DashboardMapMinimap from "./Sidebar/DashboardMapMinimap";
import DashboardMapSidebar from "./Sidebar/DashboardMapSidebar";

const TILE_SIZE = 620;
const DEFAULT_PARAMETERS = {
  lat: -150,
  lng: 310,
  zoom: 3,
};

const DashboardMap = () => {
  const history = useHistory();
  const location = useLocation();

  const outsideBounds = TILE_SIZE * 0.3;

  //@ts-expect-error
  const [map, setMap] = useState<Map>(null);
  const [sizes, setSizes] = useState<MapPointType.size[]>([]);

  const params = new URLSearchParams(history.location.search);

  useEffect(() => {
    if (!params.has("lat") && !params.has("lng") && !params.has("zoom")) {
      params.set("lat", DEFAULT_PARAMETERS.lat.toFixed(0));
      params.set("lng", DEFAULT_PARAMETERS.lng.toFixed(0));
      params.set("zoom", DEFAULT_PARAMETERS.zoom.toFixed(0));

      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack sx={{ height: "100%", width: "100%" }} direction="row">
      <DashboardMapPopup />

      <MapContainer
        ref={(mapRef) => setMap(mapRef)}
        maxBounds={[
          [0 + outsideBounds, TILE_SIZE + outsideBounds],
          [-TILE_SIZE - outsideBounds, 0 - outsideBounds],
        ]}
        center={[
          params.get("lat")
            ? parseInt(params.get("lat") as string)
            : DEFAULT_PARAMETERS.lat,
          params.get("lng")
            ? parseInt(params.get("lng") as string)
            : DEFAULT_PARAMETERS.lng,
        ]}
        zoom={
          params.get("zoom")
            ? parseInt(params.get("zoom") as string)
            : DEFAULT_PARAMETERS.zoom
        }
        crs={CRS.Simple}
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#110f0e",
          cursor: "default",
        }}
        doubleClickZoom={false}
        zoomControl={false}
      >
        <TileLayer
          url={`${process.env.REACT_APP_API_URL}/uploads/map/tilelayers_files/{z}/{x}_{y}.jpeg`}
          minNativeZoom={2}
          maxNativeZoom={5}
          minZoom={1}
          maxZoom={5}
          noWrap
          detectRetina
          tileSize={TILE_SIZE}
          bounds={[
            [0, TILE_SIZE],
            [-TILE_SIZE, 0],
          ]}
        />
        <DashboardMapURL />
        <DashboardMapClick />
        <DashboardMapSizeFilter sizes={sizes} />
        <DashboardMapSales map={map} />
      </MapContainer>

      <DashboardMapSidebar setSizes={setSizes}>
        {map && <DashboardMapMinimap map={map} />}
      </DashboardMapSidebar>
    </Stack>
  );
};

export default DashboardMap;
