import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import { FC } from "react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
SwiperCore.use([Autoplay, Navigation]);

interface ILandSlider {
  slides: string[];
}

const LandSlider: FC<ILandSlider> = ({ slides }) => {
  return (
    <Box sx={{ maxWidth: "100%", position: "relative" }}>
      <Box
        className="land-slider-prev"
        sx={{
          position: "absolute",
          top: "50%",
          left: "10px",
          transform: "translateY(-50%)",
          zIndex: 2,
          padding: "10px",
          background: "#fff",
          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        <ChevronLeftIcon sx={{ color: "black" }} />
      </Box>
      <Box
        className="land-slider-next"
        sx={{
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
          zIndex: 2,
          padding: "10px",
          background: "#fff",
          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        <ChevronRightIcon sx={{ color: "black" }} />
      </Box>
      <Swiper
        spaceBetween={15}
        slidesPerView={1}
        loop
        autoplay={{ delay: 4000, disableOnInteraction: false }}
        className="land-slider"
        navigation={{
          prevEl: ".land-slider-prev",
          nextEl: ".land-slider-next",
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <img
              src={slide}
              alt="land"
              height="400"
              width="100%"
              style={{ objectFit: "cover", maxHeight: "100%" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default LandSlider;
