import { Box, Button, Divider, Grid } from "@mui/material";
import { MutationStatus } from "@tanstack/react-query";
import { Form, FormikProvider, useFormik } from "formik";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

import FormikDateTimePicker from "components/forms/FormikDateTimePicker";
import FormikNumberField from "components/forms/FormikNumberField";
import FormikTextField from "components/forms/FormikTextField";
import Table, { TableColumn } from "components/Table/EditTable";

export type FeatureQueueFormValues = {
  information: {
    start: string | null;
    end: string | null;
  };
  schema: {}[];
  users: {}[];
};

interface IFeatureQueueForm {
  handleSubmit: (values: FeatureQueueFormValues) => void;
  initialValues?: FeatureQueueFormValues;
  submitStatus?: MutationStatus;
  handleOpenDelete: () => void;
}

const FeatureQueueForm: FC<IFeatureQueueForm> = ({
  initialValues = {
    information: {
      name: "",
      start: null,
      end: null,
      timerPerUser: null,
    },
    schema: [],
    users: [],
  },
  handleSubmit,
  submitStatus,
  handleOpenDelete,
}) => {
  // const [schema, setSchema] = useState(initialValues.schema ?? []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    // validationSchema: schema,
    onSubmit: handleSubmit,
  });
  const { values, setFieldValue } = formik;

  const hostsColumns: TableColumn<any>[] = [
    {
      id: "host",
      Header: "Host",
      columns: [
        {
          accessor: "email",
          Header: "Email",
        },
      ],
    },
  ];
  const usersColumns: TableColumn<any>[] = [
    {
      id: "user",
      Header: "User",
      columns: [
        {
          accessor: "email",
          Header: "Email",
        },
        {
          accessor: "position",
          Header: "Position in Queue",
        },
      ],
    },
  ];

  // Hosts
  const addHosts = async () => {
    const newSchema = [
      ...values.schema,
      {
        email: "",
      },
    ];
    setFieldValue("schema", newSchema);
  };

  const deleteHosts = async (rowIndex: number) => {
    const newSchema = [...values.schema].filter(
      (row, index) => index !== rowIndex
    );
    setFieldValue("schema", newSchema);
  };

  const updateHosts = async (
    rowIndex: number,
    columnId: string,
    value: any
  ) => {
    const newSchema = [...values.schema].map((row, index) => {
      if (index === rowIndex) {
        return {
          ...row,
          [columnId]: value,
        };
      }
      return row;
    });

    setFieldValue("schema", newSchema);
  };

  // Users
  const addUsers = async () => {
    const newUsers = [
      ...values.users,
      {
        email: "",
        position: null,
      },
    ];
    setFieldValue("users", newUsers);
  };

  const deleteUsers = async (rowIndex: number) => {
    const newUsers = [...values.users].filter(
      (row, index) => index !== rowIndex
    );
    setFieldValue("users", newUsers);
  };

  const updateUsers = async (
    rowIndex: number,
    columnId: string,
    value: any
  ) => {
    const newUsers = [...values.users].map((row, index) => {
      if (index === rowIndex) {
        return {
          ...row,
          [columnId]: value,
        };
      }
      return row;
    });

    setFieldValue("users", newUsers);
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Box sx={{ py: { xs: 1, sm: 2 } }}>
              <Box sx={{ px: { xs: 2, sm: 3 } }}>
                {/* Information */}
                <Divider sx={{ fontSize: "14px" }}>Information</Divider>

                <Grid container columnSpacing={2}>
                  <Grid item xs={12}>
                    <FormikTextField name="information.name" label="Name" />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikDateTimePicker
                      name="information.start"
                      label="Start"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikDateTimePicker name="information.end" label="End" />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikNumberField
                      name="information.timePerUser"
                      label="Time Per User (minutes)"
                    />
                  </Grid>
                </Grid>
              </Box>

              {/* Hosts */}
              <Divider sx={{ fontSize: "14px", mt: 3, mx: { xs: 2, sm: 3 } }}>
                Hosts
              </Divider>

              <Table
                data={values.schema}
                columns={hostsColumns}
                addData={addHosts}
                updateData={updateHosts}
                deleteData={deleteHosts}
                manualPagination
              />

              {/* Participants */}
              <Divider sx={{ fontSize: "14px", mt: 3, mx: { xs: 2, sm: 3 } }}>
                Participants
              </Divider>

              <Table
                data={values.users}
                columns={usersColumns}
                addData={addUsers}
                updateData={updateUsers}
                deleteData={deleteUsers}
                manualPagination
              />
            </Box>

            {/* FOOTER */}
            <Grid
              container
              spacing={2}
              sx={{ mt: "auto", p: { xs: 2, sm: 3 } }}
            >
              <Grid item xs={3}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  size="medium"
                  type="submit"
                  // onClick={async () => {
                  //   await handleSubmit({
                  //     ...initialValues,
                  //     schema,
                  //   });
                  // }}
                >
                  <FormattedMessage id="GLOBAL.SAVE" />
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  sx={{ width: "100%" }}
                  color="error"
                  variant="contained"
                  onClick={handleOpenDelete}
                  size="medium"
                >
                  <FormattedMessage id="GLOBAL.DELETE" />
                </Button>
              </Grid>

              <Grid item xs={3}>
                <input
                  type="file"
                  id="feature-import"
                  style={{ display: "none" }}
                  accept=".csv"
                />
                <Box
                  component="label"
                  sx={{
                    width: "100%",
                    background: (t) => t.palette.success.main,
                    p: "6px 16px",
                    borderRadius: 1,
                    minWidth: 8,
                    fontSize: "0.875rem",
                    fontWeight: "500",
                    height: "100%",
                    display: "block",
                    textTransform: "uppercase",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  color="success"
                  htmlFor={"feature-import"}
                >
                  <FormattedMessage id="GLOBAL.IMPORT" />
                </Box>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
};

export default FeatureQueueForm;
