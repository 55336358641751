import { useQuery } from "@tanstack/react-query";
import React, { useMemo } from "react";
import { Rectangle } from "react-leaflet";

import { getMap } from "api/map";
import { mapKeys } from "api/map/queries";
import { MapPointType } from "api/map/types";

const DashboardMapSizeFilter = ({ sizes }: { sizes: MapPointType.size[] }) => {
  const { data: mapPoints } = useQuery(mapKeys.mapPoints(), async () => {
    const { data: res } = await getMap();
    return res.data;
  });

  const mapSelectedSize = useMemo(() => {
    if (!mapPoints?.length) return [];

    return mapPoints.filter(({ size }) => sizes.includes(size));
  }, [mapPoints, sizes]);

  if (!mapSelectedSize?.length) return null;

  return (
    <>
      {mapSelectedSize.map(({ id, bounds }) => {
        return <Rectangle key={id} bounds={bounds} color="#fff"></Rectangle>;
      })}
    </>
  );
};

export default DashboardMapSizeFilter;
