import { Search as SearchIcon } from "@mui/icons-material";
import { Chip, Grid, InputAdornment, TextField } from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CellProps } from "react-table";

import { getMapPoints } from "api/map";
import { mapKeys } from "api/map/queries";
import { MapPointType } from "api/map/types";
import Table, { TableColumn } from "components/Table";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";
import { calculateX, calculateY } from "utils/map/calculatePosition";

import MapPointActionsFormatter from "./formatters/MapPointActionsFormatter";
import MapPointPosition from "./formatters/MapPointPositionFormatter";
import { useMapFilter } from "./useMapFilter";

interface IMapstable {}

const Mapstable: FC<IMapstable> = () => {
  const intl = useIntl();

  const { filters, searchField } = useMapFilter();

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    mapKeys.listFiltered(filters),
    getMapPoints,
    filters
  );
  const columns: TableColumn<MapPointType>[] = [
    // {
    //
    //   Header: intl.formatMessage({ id: "MAP.TABLE.LAND_IMAGE" }),
    //   Cell: ({ value }) => (
    //     <Box
    //       component="img"
    //       src={
    //         value ??
    //         "https://firebasestorage.googleapis.com/v0/b/africarare-land.appspot.com/o/lands%2Fland.png?alt=media&token=54e25149-fc10-49de-a216-474432673d63"
    //       }
    //       width="80px"
    //       height="80px"
    //       alt="Village Image"
    //       sx={{
    //         objectFit: "contain",
    //         border: "1px solid #363636",
    //       }}
    //     />
    //   ),
    // },
    {
      accessor: "landImage",
      Header: intl.formatMessage({ id: "MAP.TABLE.LAND_IMAGE" }),
      Cell: ({ cell }: CellProps<MapPointType>) => (
        <MapPointPosition point={cell.row.original} />
      ),
    },
    {
      accessor: "id",
      Header: intl.formatMessage({ id: "MAP.TABLE.ID" }),
    },
    {
      id: "coords",
      Header: intl.formatMessage({ id: "MAP.TABLE.COORDINATES" }),
      Cell: ({ cell }: CellProps<MapPointType>) => (
        <>
          ({calculateX(cell.row.original.bounds[0][1])},{" "}
          {calculateY(cell.row.original.bounds[1][0])})
        </>
      ),
    },
    {
      accessor: "size",
      Header: intl.formatMessage({ id: "MAP.TABLE.SIZE" }),
    },
    {
      accessor: "type",
      Header: intl.formatMessage({ id: "MAP.TABLE.TYPE" }),
      Cell: ({ value }: CellProps<MapPointType>) => {
        const colors = {
          "for-sale": "#fff",
          basic: "#676767",
          owned: "#e78725",
          precious: "#6767c1",
        };
        // @ts-expect-error
        return <Chip sx={{ bgcolor: colors[value] }} label={value} />;
      },
    },
    {
      id: "actions",
      Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
      Cell: (cell: CellProps<MapPointType>) => (
        <MapPointActionsFormatter
          {...cell}
          // handleOpenEdit={handleOpenEdit}
          // handleOpenDelete={handleOpenDelete}
        />
      ),
      align: "right",
    },
  ];

  return (
    <>
      <Grid container sx={{ pt: 1, px: 2 }}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={<FormattedMessage id="MAP.TABLE.SEARCH_BY" />}
            {...searchField}
          />
        </Grid>
      </Grid>
      <Table
        data={data}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
      />
    </>
  );
};

export default Mapstable;
