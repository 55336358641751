import {
  FileDownload as FileDownloadIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { FC } from "react";
import {
  FormattedMessage,
  // useIntl
} from "react-intl";
import { CellProps } from "react-table";

import {
  downloadUserWalletAddressesAnalyticsCSV,
  getTotalWalletAddresses,
  getUserWalletAddressesAnalytics,
} from "api/analytics";
import { analyticsKeys } from "api/analytics/queries";
import { UsersType } from "api/users/types";
import { QueryParamsType } from "api/utils";
import Table, { TableColumn } from "components/Table";
import { dateTimeFormatter } from "components/Table/formatters/dateTimeFormatter";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";
import { downloadFile } from "utils/downloadFile";

import { useWalletAnalyticsFilter } from "./useWalletAnalyticsFilter";

interface IWalletAnalyticsTable {
  additionalFilters?: QueryParamsType<UsersType>;
}

const WalletAnalyticsTable: FC<IWalletAnalyticsTable> = ({
  additionalFilters,
}) => {
  // const intl = useIntl();

  const { filters, searchField } = useWalletAnalyticsFilter(additionalFilters);

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    analyticsKeys.awalletAnalyticsFiltered(filters),
    getUserWalletAddressesAnalytics,
    filters
  );

  const { data: totalWallets } = useQuery(
    analyticsKeys.totalWallets(),
    async () => {
      const { data: res } = await getTotalWalletAddresses();
      return res.data;
    }
  );

  const columns: TableColumn<UsersType>[] = [
    {
      accessor: "name",
      Header: "Name",
    },
    {
      accessor: "email",
      Header: "Email",
    },
    {
      accessor: "walletAddress",
      Header: "Wallet Address",
      Cell: ({ row }: CellProps<any>) => (
        <>
          {row.original.walletAddress?.map((el: string, index: any) => (
            <Typography key={index} fontSize={"14px"}>
              {el}
            </Typography>
          ))}
        </>
      ),
    },
    {
      accessor: "createdAt",
      Header: "Created at",
      Cell: ({ value }: CellProps<any>) => <>{dateTimeFormatter(value)}</>,
    },
  ];

  return (
    <>
      <Grid container spacing={1} sx={{ pt: 1, px: 2 }}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={"Search by user name"}
            {...searchField}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Box sx={{ py: 1 }}>
            <Button
              // data-testid="new-port-button"
              variant="contained"
              // onClick={() => handleOpen()}
              sx={{
                backgroundColor: "#1D6F42",
                "&:hover": {
                  backgroundColor: "#1D6F42",
                },
              }}
              onClick={async () => {
                const { data: file } =
                  await downloadUserWalletAddressesAnalyticsCSV();
                downloadFile(file, "Users with Wallet Addresses", "csv");
              }}
            >
              <FileDownloadIcon sx={{ height: 20, my: 0.25, mr: 0.5 }} />
              <FormattedMessage id="GLOBAL.DOWNLOAD_CSV" />
            </Button>
          </Box>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Box sx={{ py: 1 }}>
            <Typography>
              Total Wallets connected:{" "}
              <Typography fontWeight="bold" fontSize={20}>
                {totalWallets ?? "-"}
              </Typography>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Table
        data={data}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
        initialState={
          {
            // pageSize: 100,
          }
        }
      />
    </>
  );
};

export default WalletAnalyticsTable;
