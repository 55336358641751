import { BarChart as BarChartIcon, Circle } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { getAnalytics } from "api/analytics";
import { analyticsKeys } from "api/analytics/queries";
import TableHeader from "components/Table/components/TableHeader";

interface IVisitorsAnalyticsHeader {}

const VisitorsAnalyticsHeader: FC<IVisitorsAnalyticsHeader> = () => {
  const { data } = useQuery(analyticsKeys.list(), async () => {
    const res = await getAnalytics({});
    return res.data;
  });

  return (
    <>
      <TableHeader
        icon={BarChartIcon}
        label={<FormattedMessage id="USERS.VISITORS.ANALYTICS.TABLE.TITLE" />}
      >
        <Chip
          icon={<Circle color="success" />}
          sx={{
            p: 0,
            height: "29px",
            color: "#FFF",
            ".MuiChip-label": {
              paddingTop: "3px",
            },
          }}
          label={`${data?.meta.liveUsers ?? 0} Live User${
            data?.meta.liveUsers !== 1 ? "s" : ""
          }`}
          color="success"
        ></Chip>
      </TableHeader>
    </>
  );
};

export default VisitorsAnalyticsHeader;
