import React from "react";
import { Redirect } from "react-router-dom";

import { UserOrgTypeRoleType } from "api/user/types";
import { useUserStore } from "components/stores/UserStore";

export const getUserRoleBasedLink = (
  role: UserOrgTypeRoleType | null | undefined
) => {
  switch (role) {
    case "customer":
      return "/user/profile";
    default:
      return "/dashboard";
  }
};

const UserRoleBasedRouter = () => {
  const [user] = useUserStore((s) => [s.user]);

  return <Redirect to={getUserRoleBasedLink(user?.role)} />;
};

export default UserRoleBasedRouter;
