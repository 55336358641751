import {
  Add as AddIcon,
  DeleteOutline as DeleteIcon,
} from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Stack } from "@mui/material";
import { MutationStatus } from "@tanstack/react-query";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

import FormikNumberField from "components/forms/FormikNumberField";

export type FeatureParticipationFormValues = {
  schema: {
    posTrigger: {
      x: number | null;
      y: number | null;
      z: number | null;
    };
    radiusTrigger: number | null;
    posTarget: {
      x: number | null;
      y: number | null;
      z: number | null;
    };
  }[];
};

interface IFeatureParticipationForm {
  handleSubmit: (values: FeatureParticipationFormValues) => void;
  initialValues?: FeatureParticipationFormValues;
  submitStatus?: MutationStatus;
  handleOpenDelete: () => void;
}

const FeatureParticipationForm: FC<IFeatureParticipationForm> = ({
  initialValues = {
    schema: [],
  },
  handleSubmit,
  submitStatus,
  handleOpenDelete,
}) => {
  const schema = yup.object({
    schema: yup.array().of(
      yup.object().shape({
        posTrigger: yup.object({}).shape({
          x: yup.number().required(),
          y: yup.number().required(),
          z: yup.number().required(),
        }),
        radiusTrigger: yup.number().nullable().required(),
        posTarget: yup.object({}).shape({
          x: yup.number().required(),
          y: yup.number().required(),
          z: yup.number().required(),
        }),
      })
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const { values } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <Box sx={{ p: { xs: 2, sm: 3 } }}>
            <FieldArray
              name="schema"
              render={(arrayHelpers) => {
                return (
                  <Box sx={{ mb: 1 }}>
                    {values.schema.map((user, index) => {
                      return (
                        <Grid
                          container
                          spacing={1}
                          sx={{ mb: 1.5 }}
                          key={`product-${index}`}
                        >
                          <Grid item xs={4} lg={2}>
                            <FormikNumberField
                              label="Pos Trigger X"
                              name={`schema.${index}.posTrigger.x`}
                            />
                          </Grid>
                          <Grid item xs={4} lg={2}>
                            <FormikNumberField
                              label="Pos Trigger Y"
                              name={`schema.${index}.posTrigger.y`}
                            />
                          </Grid>
                          <Grid item xs={4} lg={2}>
                            <FormikNumberField
                              label="Pos Trigger Z"
                              name={`schema.${index}.posTrigger.z`}
                            />
                          </Grid>

                          <Grid item xs={4} lg={2}>
                            <FormikNumberField
                              label="Pos Target X"
                              name={`schema.${index}.posTarget.x`}
                            />
                          </Grid>
                          <Grid item xs={4} lg={2}>
                            <FormikNumberField
                              label="Pos Target Y"
                              name={`schema.${index}.posTarget.y`}
                            />
                          </Grid>
                          <Grid item xs={4} lg={2}>
                            <FormikNumberField
                              label="Pos Target Z"
                              name={`schema.${index}.posTarget.z`}
                            />
                          </Grid>
                          <Grid item xs={4} lg={2}>
                            <FormikNumberField
                              label="Radius Trigger"
                              name={`schema.${index}.radiusTrigger`}
                            />
                          </Grid>
                          <Grid item xs={12} lg={1}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="center"
                              sx={{ height: "100%", maxHeight: "50px" }}
                            >
                              <IconButton
                                color="primary"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Stack>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Stack
                      sx={{ height: "50px" }}
                      direction="row"
                      alignItems="center"
                      justifyContent="start"
                    >
                      <Button
                        variant="contained"
                        onClick={() =>
                          arrayHelpers.push({
                            avatarSid: null,
                          })
                        }
                      >
                        <AddIcon sx={{ mr: 0.5 }} />
                        <FormattedMessage id="GLOBAL.ADD" />
                      </Button>
                    </Stack>
                  </Box>
                );
              }}
            />
          </Box>

          {/* FOOTER */}
          <Grid container spacing={2} sx={{ mt: "auto", p: { xs: 2, sm: 3 } }}>
            <Grid item xs={3}>
              <Button
                sx={{ width: "100%" }}
                variant="contained"
                size="medium"
                type="submit"
              >
                <FormattedMessage id="GLOBAL.SAVE" />
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                sx={{ width: "100%" }}
                color="error"
                variant="contained"
                onClick={handleOpenDelete}
                size="medium"
              >
                <FormattedMessage id="GLOBAL.DELETE" />
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
};

export default FeatureParticipationForm;
