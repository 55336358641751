import {
  APICall,
  Paginated,
  QueryParamsType,
  normalizeQueryParams,
} from "api/utils";

import request from "../request";
import { MapPointType } from "./types";

export const getMap = (): APICall<{ data: MapPointType[] }> =>
  request({
    method: "GET",
    url: "/map",
  });

export const getMapPointCoordinates = (
  lat: number | string,
  lng: number | string
): APICall<{ data: MapPointType }> =>
  request({
    method: "GET",
    url: "/map/coordinates",
    params: {
      lat,
      lng,
    },
  });

export const getMapPoints = (
  queryParams?: QueryParamsType<MapPointType>
): APICall<Paginated<MapPointType[]>> =>
  request({
    method: "GET",
    url: `/map/point${normalizeQueryParams(queryParams)}`,
  });

export const getSingleMapPoint = (
  id: string
): APICall<{ data: MapPointType }> =>
  request({
    method: "GET",
    url: `/map/point/${id}`,
  });
