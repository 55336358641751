import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
// import { useUnleashContext } from "@unleash/proxy-client-react";
import React, { ElementType, FC, useEffect } from "react";
import { Route, RouteProps, match } from "react-router-dom";
import shallow from "zustand/shallow";

import { axiosInstance } from "api/request";
import { getCurrentUser } from "api/user";
import { UserOrgTypeRoleType } from "api/user/types";
import DefaultLayout from "components/layouts/DefaultLayout";
import { LayoutSplashScreen } from "components/providers/SplashScreenProvider";
import {
  hasPermissions,
  hasRole,
  useUserStore,
} from "components/stores/UserStore";
import { PermissionsType } from "components/stores/UserStore/permissions";

import useBreadcrumbStore, { Breadcrumb } from "../stores/BreadcrumbStore";

interface Props extends RouteProps {
  showBreadcrumbs?: boolean;
  computedMatch?: match;
  breadcrumbs?: Breadcrumb[];
  requiredPermissions?: PermissionsType[];
  requiredRole?: UserOrgTypeRoleType[];
  layout?: ElementType;
}

const PrivateRoute: FC<Props> = ({
  showBreadcrumbs = true,
  requiredPermissions = [],
  requiredRole = [],
  breadcrumbs,
  layout: Layout = DefaultLayout,
  ...rest
}) => {
  const { setBreadcrumbs } = useBreadcrumbStore(
    (s) => ({ setBreadcrumbs: s.setBreadcrumbs }),
    shallow
  );
  // const updateContext = useUnleashContext();
  const [user, setToken, setUser] = useUserStore((s) => [
    s.user,
    s.setToken,
    s.setUser,
  ]);

  const {
    getIdTokenClaims,
    logout,
    // user: auth0User
  } = useAuth0();

  // useEffect(() => {
  //   updateContext({ userId: auth0User.email });
  // }, [updateContext, auth0User]);

  useEffect(() => {
    const getTokenAndInjectItInHeader = async () => {
      try {
        const res = await getIdTokenClaims();

        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res?.__raw}`;

        setToken(res?.__raw);

        const {
          data: { data: currentUser },
        } = await getCurrentUser();

        setUser(currentUser);
      } catch (err) {
        console.error(err);
        logout({ returnTo: `${window.location.origin}/login` });
      }
    };
    getTokenAndInjectItInHeader();
  }, [
    getIdTokenClaims,
    logout,
    setToken,
    setUser,
    // updateContext
  ]);

  if (!!user) {
    if (hasPermissions(requiredPermissions) && hasRole(requiredRole)) {
      if (breadcrumbs) {
        setBreadcrumbs(breadcrumbs, rest.computedMatch!.params);
      }

      return (
        <Layout showBreadcrumbs={showBreadcrumbs}>
          <Route {...rest} />
        </Layout>
      );
    } else {
      return (
        <Layout showBreadcrumbs={showBreadcrumbs}>
          <div>You don't have access to this page</div>
        </Layout>
      );
    }
  }

  return <LayoutSplashScreen />;
};

export default withAuthenticationRequired(PrivateRoute, {
  onRedirecting: () => {
    return <LayoutSplashScreen />;
  },
});
