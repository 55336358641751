import {
  FileDownload as FileDownloadIcon,
  Group as GroupIcon,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { downloadAnalyticsVisitorsCSV } from "api/analytics";
import TableHeader from "components/Table/components/TableHeader";
import { downloadFile } from "utils/downloadFile";

const VisitorsHeader: FC = () => {
  return (
    <>
      <TableHeader
        icon={GroupIcon}
        label={<FormattedMessage id="USERS.VISITORS.TABLE.TITLE" />}
      >
        <Button
          // data-testid="new-port-button"
          variant="contained"
          // onClick={() => handleOpen()}
          sx={{
            backgroundColor: "#1D6F42",
            "&:hover": {
              backgroundColor: "#1D6F42",
            },
          }}
          onClick={async () => {
            const { data: file } = await downloadAnalyticsVisitorsCSV({});
            downloadFile(file, "Analytics-Visitors", "csv");
          }}
        >
          <FileDownloadIcon sx={{ height: 20, my: 0.25, mr: 0.5 }} />
          <FormattedMessage id="GLOBAL.DOWNLOAD_CSV" />
        </Button>
      </TableHeader>
    </>
  );
};

export default VisitorsHeader;
