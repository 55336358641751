import { Box, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Map } from "leaflet";
import React, { useMemo } from "react";
import { Rectangle, Tooltip } from "react-leaflet";

import { getMap } from "api/map";
import { mapKeys } from "api/map/queries";
import EthTokenWEBP from "assets/imgs/eth-token.webp";

const SALES_LAND_PRICING = {
  "1x1": 0.09,
  "3x3": 0.81,
  "9x9": 7.29,
  "12x12": 12.96,
  "24x24": 51.84,
};

const SALES_DISPLAY_AFTER = {
  "1x1": 4,
  "3x3": 3,
  "9x9": 2,
  "12x12": 1,
  "24x24": 0,
};

const DashboardMapSales = ({ map }: { map: Map }) => {
  const mapZoom = map?.getZoom();

  const { data: mapPoints } = useQuery(mapKeys.mapPoints(), async () => {
    const { data: res } = await getMap();
    return res.data;
  });

  const mapForSale = useMemo(() => {
    if (!mapPoints?.length) return [];

    return mapPoints.filter(
      ({ type, size }) =>
        //   @ts-expect-error
        type === "for-sale" && SALES_DISPLAY_AFTER[size] < mapZoom
    );
  }, [mapPoints, mapZoom]);

  if (!mapForSale?.length) return null;

  return (
    <>
      {mapForSale.map(({ id, size, bounds }) => {
        return (
          <Rectangle key={id} bounds={bounds} fill={false} opacity={0}>
            <Tooltip permanent direction="top" className="map-tooltip">
              <Stack direction="row" alignItems="center">
                <Box
                  component="img"
                  src={EthTokenWEBP}
                  width={15}
                  height={15}
                  sx={{ objectFit: "contain" }}
                  mr={0.5}
                />
                <Typography fontSize={10} height={12}>
                  {/* @ts-expect-error */}
                  {SALES_LAND_PRICING[size]} ETH
                </Typography>
              </Stack>
            </Tooltip>
          </Rectangle>
        );
      })}
    </>
  );
};

export default DashboardMapSales;
