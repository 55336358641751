import { Add as AddIcon } from "@mui/icons-material";
import { Dialog, DialogTitle, Divider, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { FormattedMessage } from "react-intl";

import { experiencesKeys } from "api/experiences/queries";
import { LevelTypeWithExperience } from "api/generated";
import { editLevel as editLevelAPI } from "api/levels";
import { EditLevelType } from "api/levels/types";

import LevelForm, { LevelFormValues } from "./LevelForm";

interface EditLevelModalProps {
  isOpen: boolean;
  handleClose: () => void;
  level: LevelTypeWithExperience | undefined;
}

const EditLevelModal = ({
  isOpen,
  handleClose,
  level,
}: EditLevelModalProps) => {
  const queryClient = useQueryClient();

  const initialValues: LevelFormValues = {
    name: level?.name || "",
    slug: level?.slug || "",
    scene: level?.scene || "",
    private: level?.private || false,
    roomCapacity: level?.roomCapacity || 15,
  };

  const { mutateAsync: editLevel, status } = useMutation(
    async (data: EditLevelType) => {
      const res = await editLevelAPI(data);
      await queryClient.invalidateQueries(experiencesKeys.list());
      return res.data;
    }
  );

  const handleSubmit = async (values: LevelFormValues) => {
    await editLevel(
      { ...values, _id: level!._id },
      {
        onSuccess: () => handleClose(),
      }
    );
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <AddIcon sx={{ mr: 1 }} />
          <FormattedMessage id="LEVELS.FORM.NEW_LEVEL.HEADER" />
        </Stack>
      </DialogTitle>
      <Divider />

      <LevelForm
        submitStatus={status}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        initialValues={initialValues}
      />
    </Dialog>
  );
};

export default EditLevelModal;
