import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { visit } from "utils/visit/visit";

const VisitDirectPage = () => {
  const { getIdTokenClaims } = useAuth0();

  const { search } = useLocation();

  const { land, experience, level } = useParams<{
    land: string;
    experience: string;
    level: string;
  }>();

  useEffect(() => {
    const query = new URLSearchParams(search);

    const land = query.get("land") ?? "";
    const experience = query.get("experience") || undefined;
    const level = query.get("level") || undefined;
    const room = query.get("room") || undefined;
    const anchor = query.get("anchor") || undefined;
    (async function () {
      try {
        console.log(experience, level);
        const res = await getIdTokenClaims();

        visit({
          land,
          experience,
          level,
          room,
          anchor,
          token: res.__raw,
        });
      } catch (err) {
        console.log(err);
      }
    })();
  }, [experience, getIdTokenClaims, land, level, search]);

  return (
    <Box
      sx={{ width: "100%", height: "100vh", overflow: "hidden", m: 0 }}
      bgcolor="#24272e"
    ></Box>
  );
};

export default VisitDirectPage;
