const analyticsKeys = {
  all: ["analytics"] as const,
  list: () => [...analyticsKeys.all, "list"],
  listFiltered: (filters: Record<string, unknown>) => [
    ...analyticsKeys.list(),
    filters,
  ],
  visitors: () => [...analyticsKeys.all, "visitors"],
  visitorsFiltered: (filters: Record<string, unknown>) => [
    ...analyticsKeys.visitors(),
    filters,
  ],
  referrals: () => [...analyticsKeys.all, "referrals"],
  referralsFiltered: (filters: Record<string, unknown>) => [
    ...analyticsKeys.referrals(),
    filters,
  ],
  referralsChart: () => [...analyticsKeys.all, "referralsChart"],
  referralsChartFiltered: (filters: Record<string, unknown>) => [
    ...analyticsKeys.referralsChart(),
    filters,
  ],
  abadaAnalytics: () => [...analyticsKeys.all, "abadaAnalytics"],
  abadaAnalyticsFiltered: (filters: Record<string, unknown>) => [
    ...analyticsKeys.abadaAnalytics(),
    filters,
  ],
  friendRequestAnalytics: () => [
    ...analyticsKeys.all,
    "friendRequestAnalytics",
  ],
  friendRequestAnalyticsFiltered: (filters: Record<string, unknown>) => [
    ...analyticsKeys.friendRequestAnalytics(),
    filters,
  ],
  aiCourseAnalytics: () => [...analyticsKeys.all, "aiCourseAnalytics"],
  aiCourseAnalyticsFiltered: (filters: Record<string, unknown>) => [
    ...analyticsKeys.aiCourseAnalytics(),
    filters,
  ],
  walletAnalytics: () => [...analyticsKeys.all, "walletAnalytics"],
  awalletAnalyticsFiltered: (filters: Record<string, unknown>) => [
    ...analyticsKeys.walletAnalytics(),
    filters,
  ],
  totalWallets: () => [...analyticsKeys.all, "totalWallets"],
};

export { analyticsKeys };
