import {
  Add as AddIcon,
  DeleteOutline as DeleteIcon,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import { MutationStatus, useQuery } from "@tanstack/react-query";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

import { downloadFeatureCSV } from "api/features";
import { getCustomers } from "api/users";
import { usersKeys } from "api/users/queries";
import FormikTextField from "components/forms/FormikTextField";
import { downloadFile } from "utils/downloadFile";

export type FeatureAccessFormValues = {
  schema: {
    email: string | null;
  }[];
};

interface IFeatureAccessForm {
  featureId: string;
  link?: string;
  handleSubmit: (values: FeatureAccessFormValues) => void;
  initialValues?: FeatureAccessFormValues;
  submitStatus?: MutationStatus;
  handleOpenDelete: () => void;
}

const FeatureAccessForm: FC<IFeatureAccessForm> = ({
  featureId,
  link,
  initialValues = {
    schema: [],
  },
  handleSubmit,
  submitStatus,
  handleOpenDelete,
}) => {
  const schema = yup.object({
    schema: yup.array().of(
      yup.object().shape({
        email: yup.string().nullable().required(),
      })
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const { values, setFieldValue } = formik;

  const { data: users } = useQuery(usersKeys.customers(), async () => {
    const { data: res } = await getCustomers();
    return res.data;
  });

  const usersOptions = useMemo(() => {
    if (users?.length) {
      return users.map((user) => user.email);
    }

    return [];
  }, [users]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <Box sx={{ p: { xs: 2, sm: 3 } }}>
            <FieldArray
              name="schema"
              render={(arrayHelpers) => {
                return (
                  <Box sx={{ mb: 1 }}>
                    {values.schema.map((user, index) => {
                      return (
                        <Grid
                          container
                          spacing={1}
                          sx={{ mb: 1.5 }}
                          key={`product-${index}`}
                        >
                          <Grid item xs={12} lg={11}>
                            <Autocomplete
                              freeSolo
                              options={usersOptions}
                              defaultValue={
                                initialValues.schema[index]?.email ?? ""
                              }
                              onChange={(e, value) => {
                                setFieldValue(`schema.${index}.email`, value);
                              }}
                              renderInput={(params) => (
                                <FormikTextField
                                  name={`schema.${index}.email`}
                                  label="User Email"
                                  {...params}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} lg={1}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="center"
                              sx={{ height: "100%", maxHeight: "50px" }}
                            >
                              <IconButton
                                color="primary"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Stack>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Stack
                      sx={{ height: "50px" }}
                      direction="row"
                      alignItems="center"
                      justifyContent="start"
                    >
                      <Button
                        variant="contained"
                        onClick={() =>
                          arrayHelpers.push({
                            email: null,
                          })
                        }
                      >
                        <AddIcon sx={{ mr: 0.5 }} />
                        <FormattedMessage id="GLOBAL.ADD" />
                      </Button>
                    </Stack>
                  </Box>
                );
              }}
            />
          </Box>

          {/* FOOTER */}
          <Grid container spacing={2} sx={{ mt: "auto", p: { xs: 2, sm: 3 } }}>
            <Grid item xs={3}>
              <Button
                sx={{ width: "100%" }}
                variant="contained"
                size="medium"
                type="submit"
              >
                <FormattedMessage id="GLOBAL.SAVE" />
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                sx={{ width: "100%" }}
                color="error"
                variant="contained"
                onClick={handleOpenDelete}
                size="medium"
              >
                <FormattedMessage id="GLOBAL.DELETE" />
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                sx={{ width: "100%" }}
                color="success"
                variant="contained"
                onClick={async () => {
                  const { data: file } = await downloadFeatureCSV(featureId);
                  downloadFile(file, "Feature-Access", "csv");
                }}
                size="medium"
              >
                <FormattedMessage id="GLOBAL.EXPORT" />
              </Button>
            </Grid>

            <Grid item xs={3}>
              <input
                type="file"
                id="feature-import"
                style={{ display: "none" }}
                accept=".csv"
              />
              <Box
                component="label"
                sx={{
                  width: "100%",
                  background: (t) => t.palette.success.main,
                  p: "6px 16px",
                  borderRadius: 1,
                  minWidth: 8,
                  fontSize: "0.875rem",
                  fontWeight: "500",
                  height: "100%",
                  display: "block",
                  textTransform: "uppercase",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                color="success"
                htmlFor={"feature-import"}
              >
                <FormattedMessage id="GLOBAL.IMPORT" />
              </Box>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
};

export default FeatureAccessForm;
