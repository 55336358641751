import { Paper } from "@mui/material";
import React from "react";

import VisitorsAnalyticsChart from "./VisitorsAnalyticsChart/VisitorsAnalyticsChart";
import VisitorsAnalyticsHeader from "./VisitorsAnalyticsHeader/VisitorsAnalyticsHeader";
import VisitorsHeader from "./VisitorsHeader/VisitorsHeader";
import VisitorsTable from "./VisitorsTable";

const VisitorsUsersPage = () => {
  return (
    <>
      <Paper>
        <VisitorsAnalyticsHeader />
        <VisitorsAnalyticsChart />
      </Paper>

      <Paper sx={{ mt: 2 }}>
        <VisitorsHeader />
        <VisitorsTable />
      </Paper>
    </>
  );
};

export default VisitorsUsersPage;
