import {
  Add as AddIcon,
  EmojiEvents as EmojiEventsIcon,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { UsersType } from "api/users/types";
import TableHeader from "components/Table/components/TableHeader";
import { useModal } from "utils/hooks/useModal";

import NewRewardModal from "../RewardsTable/components/NewRewardModal";

// import NewUserModal from "../UsersTable/components/NewUserModal";

const UsersHeader: FC = () => {
  const { isOpen, handleClose, handleOpen } = useModal<UsersType>();

  return (
    <>
      <TableHeader
        icon={EmojiEventsIcon}
        label={<FormattedMessage id="REWARDS.TABLE.TITLE" />}
      >
        <Button
          // data-testid="new-port-button"
          variant="contained"
          onClick={() => handleOpen()}
        >
          <AddIcon sx={{ mr: 0.5 }} />
          <FormattedMessage id="REWARDS.REWARDS_FORM.NEW_FORM_TITLE" />
        </Button>
      </TableHeader>
      {/* {hasPermissions("write:users") && (
        <>
          <NewUserModal isOpen={isOpen} handleClose={handleClose} />
        </>
      )} */}
      <NewRewardModal isOpen={isOpen} handleClose={handleClose} />
    </>
  );
};

export default UsersHeader;
