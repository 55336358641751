import { Paper } from "@mui/material";

import WalletAnalyticsHeader from "./WalletAnalyticsHeader/WalletAnalyticsHeader";
import WalletAnalyticsTable from "./WalletAnalyticsTable";

const WalletAddressAnalyticsPage = () => {
  return (
    <>
      <Paper sx={{ mt: 2 }}>
        <WalletAnalyticsHeader />
        <WalletAnalyticsTable />
      </Paper>
    </>
  );
};

export default WalletAddressAnalyticsPage;
