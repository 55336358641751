import { Stack } from "@mui/material";

import DashboardMap from "./map/DashboardMap";

const DashboardPage = () => {
  return (
    <Stack
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        background: (t) => t.palette.background.paper,
      }}
    >
      <DashboardMap />
    </Stack>
  );
};

export default DashboardPage;
