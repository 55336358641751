import { VisibilityOutlined as VisibilityIcon } from "@mui/icons-material";
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import { MapPointType } from "api/map/types";
import IconButtonWithTooltip from "components/Icons/IconButtonWithTooltip";

interface Props<T extends {}> extends CellProps<T> {}

const ActionsFormatter = ({ row }: Props<MapPointType>) => {
  const history = useHistory();
  return (
    <Box>
      <IconButtonWithTooltip
        tooltipText={<FormattedMessage id="GLOBAL.VIEW" />}
        color="primary"
        onClick={() => history.push(`/map/${row.original.id}`)}
      >
        <VisibilityIcon />
      </IconButtonWithTooltip>
    </Box>
  );
};

export default ActionsFormatter;
