import { createBrowserHistory } from "history";
import React from "react";
import { Route, Switch } from "react-router-dom";

import UserRoleBasedRouter from "components/routes/UserRoleBasedRouter";
import AbadaAnalyticsPage from "containers/analytics/abada-analytics";
import CoursesAnalyticsPage from "containers/analytics/courses-analytics";
import FriendsAnalyticsPage from "containers/analytics/friends-analytics";
import ReferralsUsersPage from "containers/analytics/referrals";
import WalletAddressAnalyticsPage from "containers/analytics/wallet-address-analytics";
import LandsPage from "containers/lands";
import LandsDetailsPage from "containers/lands/details";
import LevelsPage from "containers/level";
import LoginPage from "containers/login";
import MapPage from "containers/map";
import MapDetailsPage from "containers/map/details";
import OrganisationsPage from "containers/organisations";
import OrganisationDetails from "containers/organisations/details";
import PrivacyPolicyPage from "containers/privacy-policy";
import RegisterPage from "containers/register";
import RewardsPage from "containers/rewards";
import SupportPage from "containers/support";
import UserNotifications from "containers/user/notifications";
import UserProfile from "containers/user/profile";
import UsersPage from "containers/users";
import CustomersUsersPage from "containers/users/customers";
import VisitorsUsersPage from "containers/users/visitors";
import VisitPage from "containers/visit";
import VisitDirectPage from "containers/visit/direct";

import AuthRoute from "./components/routes/AuthRoute";
import PrivateRoute from "./components/routes/PrivateRoute";
import DashboardPage from "./containers/dashboard";
import Providers from "./Providers";

export const history = createBrowserHistory();

export default function App() {
  return (
    <Providers history={history}>
      <Switch>
        <AuthRoute exact component={LoginPage} path="/login" />
        <AuthRoute exact component={RegisterPage} path="/register/:id" />

        <PrivateRoute exact path="/" component={UserRoleBasedRouter} />

        <PrivateRoute
          exact
          path="/dashboard"
          component={DashboardPage}
          breadcrumbs={[
            { translationId: "HEADER.DASHBOARD", pathname: "/dashboard" },
          ]}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          exact
          path="/map"
          component={MapPage}
          breadcrumbs={[{ translationId: "HEADER.MAP", pathname: "/map" }]}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          exact
          path="/map/:mapPointId"
          component={MapDetailsPage}
          breadcrumbs={[
            { translationId: "HEADER.MAP", pathname: "/map" },
            { translationId: "GLOBAL.DETAILS", pathname: "#" },
          ]}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          exact
          path="/lands"
          component={LandsPage}
          breadcrumbs={[{ translationId: "HEADER.LANDS", pathname: "/lands" }]}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          exact
          path="/rewards"
          component={RewardsPage}
          breadcrumbs={[
            { translationId: "HEADER.REWARDS", pathname: "/rewards" },
          ]}
          // requiredPermissions={[]}
        />

        <PrivateRoute
          exact
          path="/lands/:landId"
          component={LandsDetailsPage}
          breadcrumbs={[
            { translationId: "HEADER.LANDS", pathname: "/lands" },
            { translationId: "GLOBAL.DETAILS", pathname: "#" },
          ]}
          // requiredPermissions={[]}
        />
        <PrivateRoute
          exact
          path="/level/:levelId"
          component={LevelsPage}
          breadcrumbs={[
            { translationId: "HEADER.LEVEL", pathname: "/level" },
            {
              translationId: "GLOBAL.DETAILS",
              pathname: "#",
            },
          ]}
          // requiredPermissions={[]}
        />

        <PrivateRoute
          exact
          path="/users/visitors"
          component={VisitorsUsersPage}
          breadcrumbs={[
            { translationId: "HEADER.USERS", pathname: "/users" },
            {
              translationId: "HEADER.USERS.VISITORS",
              pathname: "/users/visitors",
            },
          ]}
          requiredRole={["super-admin"]}
          requiredPermissions={["read:users"]}
        />
        <PrivateRoute
          exact
          path="/analytics/referrals"
          component={ReferralsUsersPage}
          breadcrumbs={[
            { translationId: "HEADER.ANALYTICS", pathname: "#!" },
            {
              translationId: "HEADER.USERS.REFERRALS",
              pathname: "/analytics/referrals",
            },
          ]}
          requiredRole={["super-admin"]}
          requiredPermissions={["read:users"]}
        />
        <PrivateRoute
          exact
          path="/analytics/abada-analytics"
          component={AbadaAnalyticsPage}
          breadcrumbs={[
            { translationId: "HEADER.ANALYTICS", pathname: "#!" },
            {
              translationId: "HEADER.ABADA_ANALYTICS",
              pathname: "/analytics/abada-analytics",
            },
          ]}
          requiredRole={["super-admin"]}
          requiredPermissions={["read:users"]}
        />
        <PrivateRoute
          exact
          path="/analytics/friends-analytics"
          component={FriendsAnalyticsPage}
          breadcrumbs={[
            { translationId: "HEADER.ANALYTICS", pathname: "#!" },
            {
              translationId: "HEADER.FRIENDS_ANALYTICS",
              pathname: "/analytics/friends-analytics",
            },
          ]}
          requiredRole={["super-admin"]}
          requiredPermissions={["read:users"]}
        />
        <PrivateRoute
          exact
          path="/analytics/courses-analytics"
          component={CoursesAnalyticsPage}
          breadcrumbs={[
            { translationId: "HEADER.ANALYTICS", pathname: "#!" },
            {
              translationId: "HEADER.COURSES_ANALYTICS",
              pathname: "/analytics/courses-analytics",
            },
          ]}
          requiredRole={["super-admin"]}
          requiredPermissions={["read:users"]}
        />
        <PrivateRoute
          exact
          path="/analytics/wallet-addresses"
          component={WalletAddressAnalyticsPage}
          breadcrumbs={[
            { translationId: "HEADER.ANALYTICS", pathname: "#!" },
            {
              translationId: "ANALYTICS.WALLET_ANALYTICS.TABLE.TITLE",
              pathname: "/analytics/wallet-addresses",
            },
          ]}
          requiredRole={["super-admin"]}
          requiredPermissions={["read:users"]}
        />
        <PrivateRoute
          exact
          path="/users/organisation"
          component={UsersPage}
          breadcrumbs={[
            { translationId: "HEADER.USERS", pathname: "/users" },
            {
              translationId: "HEADER.USERS.ORGANISATION_USERS",
              pathname: "/users/organisation",
            },
          ]}
          requiredRole={["portal-admin", "land-owner", "super-admin"]}
          requiredPermissions={["read:users"]}
        />
        <PrivateRoute
          exact
          path="/users/customers"
          component={CustomersUsersPage}
          breadcrumbs={[
            { translationId: "HEADER.USERS", pathname: "/users" },
            {
              translationId: "HEADER.USERS.CUSTOMERS",
              pathname: "/users/customers",
            },
          ]}
          requiredRole={["super-admin"]}
          requiredPermissions={["read:users"]}
        />

        <PrivateRoute
          exact
          path="/organisations"
          component={OrganisationsPage}
          breadcrumbs={[
            {
              translationId: "HEADER.ORGANISATIONS",
              pathname: "/organisations",
            },
          ]}
          requiredRole={["portal-admin", "super-admin"]}
          requiredPermissions={["read:organisations"]}
        />
        <PrivateRoute
          exact
          path="/organisations/:organisationId"
          component={OrganisationDetails}
          breadcrumbs={[
            {
              translationId: "HEADER.ORGANISATIONS",
              pathname: "/organisations",
            },
            {
              translationId: "GLOBAL.DETAILS",
              pathname: "#",
            },
          ]}
          requiredRole={["portal-admin", "super-admin"]}
          requiredPermissions={["read:organisations"]}
        />

        <PrivateRoute
          exact
          path="/user/profile"
          component={UserProfile}
          breadcrumbs={[
            {
              translationId: "HEADER.USER",
              pathname: "/user/profile",
            },
            {
              translationId: "HEADER.PROFILE",
              pathname: "/user/profile",
            },
          ]}
        />
        <PrivateRoute
          exact
          path="/user/notifications"
          component={UserNotifications}
          breadcrumbs={[
            {
              translationId: "HEADER.USER",
              pathname: "/user/profile",
            },
            {
              translationId: "HEADER.NOTIFICATIONS",
              pathname: "/user/notifications",
            },
          ]}
        />

        <AuthRoute exact component={PrivacyPolicyPage} path="/privacy-policy" />
        <PrivateRoute exact component={SupportPage} path="/support" />

        <Route component={VisitDirectPage} path="/visit/direct" />
        <Route component={VisitPage} path="/:land/:experience/:level" />
        <Route component={VisitPage} path="/:land/:experience" />
        <Route component={VisitPage} path="/:land" />
      </Switch>
    </Providers>
  );
}
