import {
  AutoAwesome as AutoAwesomeIcon,
  ContentCopy as ContentCopyIcon,
  DeleteOutlined as DeleteIcon,
  EditOutlined as EditIcon,
  MeetingRoom as MeetingRoomIcon,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import { LevelTypeWithExperience } from "api/generated";
import IconButtonWithTooltip from "components/Icons/IconButtonWithTooltip";
import { hasPermissions } from "components/stores/UserStore";

interface Props<T extends {}> extends CellProps<T> {
  handleOpenAddFeature: (ctx?: T) => void;
  handleOpenEdit: (ctx?: T) => void;
  handleOpenDuplicate: (ctx?: T) => void;
  handleOpenDelete: (ctx?: T) => void;
}

const ActionsFormatter = ({
  row,
  handleOpenAddFeature,
  handleOpenEdit,
  handleOpenDuplicate,
  handleOpenDelete,
}: Props<LevelTypeWithExperience>) => {
  const history = useHistory();
  return (
    <Box>
      <IconButtonWithTooltip
        tooltipText={<FormattedMessage id="GLOBAL.ROOMS" />}
        color="primary"
        onClick={() => history.push(`/level/${row.original._id}`)}
      >
        <MeetingRoomIcon />
      </IconButtonWithTooltip>
      {hasPermissions("update:levels") && (
        <IconButtonWithTooltip
          tooltipText={<FormattedMessage id="GLOBAL.ADD_FEATURE" />}
          // data-testid="edit-port-button"
          color="primary"
          onClick={() => handleOpenAddFeature(row.original)}
        >
          <AutoAwesomeIcon />
        </IconButtonWithTooltip>
      )}
      {hasPermissions("write:levels") && (
        <IconButtonWithTooltip
          tooltipText={<FormattedMessage id="GLOBAL.DUPLICATE" />}
          // data-testid="edit-port-button"
          color="primary"
          onClick={() => handleOpenDuplicate(row.original)}
        >
          <ContentCopyIcon />
        </IconButtonWithTooltip>
      )}
      {hasPermissions("update:levels") && (
        <IconButtonWithTooltip
          tooltipText={<FormattedMessage id="GLOBAL.EDIT" />}
          // data-testid="edit-port-button"
          color="primary"
          onClick={() => handleOpenEdit(row.original)}
        >
          <EditIcon />
        </IconButtonWithTooltip>
      )}
      {hasPermissions("delete:levels") && (
        <IconButtonWithTooltip
          tooltipText={<FormattedMessage id="GLOBAL.DELETE" />}
          // data-testid="delete-port-button"
          color="primary"
          onClick={() => handleOpenDelete(row.original)}
        >
          <DeleteIcon />
        </IconButtonWithTooltip>
      )}
    </Box>
  );
};

export default ActionsFormatter;
