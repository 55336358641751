import { Search as SearchIcon } from "@mui/icons-material";
import { Checkbox, Grid, InputAdornment, TextField } from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CellProps } from "react-table";

import { getCustomers, updateMeetingSubscriptionGrant } from "api/users";
import { usersKeys } from "api/users/queries";
import { UsersType } from "api/users/types";
import { QueryParamsType } from "api/utils";
import Table, { TableColumn } from "components/Table";
import { dateTimeFormatter } from "components/Table/formatters/dateTimeFormatter";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";

import { useUsersFilter } from "./useUsersFilter";

interface ICustomersTable {
  additionalFilters?: QueryParamsType<UsersType>;
}

const CustomersTable: FC<ICustomersTable> = ({ additionalFilters }) => {
  const intl = useIntl();

  const { filters, searchField } = useUsersFilter(additionalFilters);

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    usersKeys.customers(),
    getCustomers,
    filters
  );

  const columns: TableColumn<UsersType>[] = [
    {
      accessor: "name",
      Header: intl.formatMessage({ id: "USERS.TABLE.NAME" }),
    },
    {
      accessor: "email",
      Header: intl.formatMessage({ id: "GLOBAL.EMAIL" }),
    },
    {
      accessor: "grantedMeetingCreation",
      Header: "Meeting Rooms Subscription Granted",
      Cell: ({ row, value }: CellProps<UsersType>) => (
        <>
          <Checkbox
            defaultChecked={value}
            title="Check to enable meeting rooms subscription"
            onChange={async (_e, active) => {
              await updateMeetingSubscriptionGrant(row.original._id, active);
            }}
          />
        </>
      ),
    },
    {
      accessor: "createdAt",
      Header: intl.formatMessage({ id: "USERS.TABLE.REGISTERED" }),
      Cell: ({ value }: CellProps<UsersType>) => (
        <>{dateTimeFormatter(value)}</>
      ),
    },
  ];

  return (
    <>
      <Grid container sx={{ pt: 1, px: 2 }}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={<FormattedMessage id="USERS.TABLE.SEARCH_BY" />}
            {...searchField}
          />
        </Grid>
      </Grid>
      <Table
        data={data}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
      />
    </>
  );
};

export default CustomersTable;
