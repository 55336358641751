import { Add, Delete } from "@mui/icons-material";
import { Box, Button, Divider, Grid, IconButton, Stack } from "@mui/material";
import { MutationStatus, useQuery } from "@tanstack/react-query";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { getRelationalFeatures } from "api/features";
import { featuresKeys } from "api/features/queries";
import FormikAutocomplete from "components/forms/FormikAutocomplete";
import FormikTextField from "components/forms/FormikTextField";
import Table, { TableColumn } from "components/Table/EditTable";

export type FeatureCollectablesFormValues = {
  information: {
    collectables: string[];
    trackProgress: boolean;
    collectablesText: string;
    enableTutorial: boolean;
  };
  schema: {
    nameInSpoke: string | null;
  }[];
};

interface IFeatureCollectablesForm {
  featureId: string;
  handleSubmit: (values: FeatureCollectablesFormValues) => void;
  initialValues?: FeatureCollectablesFormValues;
  submitStatus?: MutationStatus;
  handleOpenDelete: () => void;
}

const FeatureCollectablesForm: FC<IFeatureCollectablesForm> = ({
  featureId,
  initialValues = {
    information: {
      collectables: [],
      trackProgress: false,
      collectablesText: "",
      enableTutorial: false,
    },
    schema: [],
  },
  handleSubmit,
  submitStatus,
  handleOpenDelete,
}) => {
  const { data: relationalFeatures } = useQuery(
    featuresKeys.relations(featureId),
    async () => {
      const { data: res } = await getRelationalFeatures(featureId);
      return res.data;
    },
    {
      enabled: Boolean(featureId),
    }
  );

  const collectableFeatures = useMemo(() => {
    if (relationalFeatures?.length) {
      return relationalFeatures
        .filter(
          (filter) => filter.type === "collectables" && filter._id !== featureId
        )
        .map((feature) => ({
          value: feature._id,
          label: `Collectable - ${feature.parent}`,
        }));
    }

    return [];
  }, [relationalFeatures, featureId]);

  // const [schema, setSchema] = useState(initialValues.schema ?? []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    // validationSchema: schema,
    onSubmit: handleSubmit,
  });
  const { values, setFieldValue } = formik;

  const columns: TableColumn<any>[] = [
    {
      id: "object",
      Header: "Object",
      columns: [
        {
          accessor: "nameInSpoke",
          Header: "Name In Spoke",
        },
      ],
    },
    {
      id: "popup",
      Header: "Popup",
      columns: [
        {
          accessor: "image",
          Header: "Image",
        },
        {
          accessor: "name",
          Header: "Name",
        },
        {
          accessor: "title",
          Header: "Title",
        },
        {
          accessor: "description",
          Header: "Description",
        },
      ],
    },
  ];

  const addData = async () => {
    const newSchema = [
      ...values.schema,
      {
        nameInSpoke: "",
        image: "",
        name: "",
        title: "",
        description: "",
      },
    ];
    setFieldValue("schema", newSchema);
  };

  const deleteData = async (rowIndex: number) => {
    const newSchema = [...values.schema].filter(
      (row, index) => index !== rowIndex
    );
    setFieldValue("schema", newSchema);
  };

  const updateData = async (rowIndex: number, columnId: string, value: any) => {
    const newSchema = [...values.schema].map((row, index) => {
      if (index === rowIndex) {
        return {
          ...row,
          [columnId]: value,
        };
      }
      return row;
    });

    setFieldValue("schema", newSchema);
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Box sx={{ py: { xs: 1, sm: 2 } }}>
              <Box sx={{ px: { xs: 2, sm: 3 } }}>
                {/* Relations */}
                <Divider sx={{ fontSize: "14px" }}>Relations</Divider>

                <FieldArray
                  name="information.collectables"
                  render={(arrayHelpers) => {
                    return (
                      <Box sx={{ mb: 1 }}>
                        {values.information?.collectables?.map((_id, index) => {
                          return (
                            <Stack direction="row" width="100%" spacing={1}>
                              <FormikAutocomplete
                                name={`information.collectables.${index}`}
                                label="Collectable"
                                options={collectableFeatures}
                                fullWidth
                              />
                              <IconButton
                                onClick={() => arrayHelpers.remove(index)}
                                color="error"
                                sx={{ minWidth: "51px" }}
                              >
                                <Delete />
                              </IconButton>
                            </Stack>
                          );
                        })}
                        <Stack
                          sx={{ height: "50px" }}
                          direction="row"
                          alignItems="center"
                          justifyContent="start"
                        >
                          <Button
                            variant="contained"
                            onClick={() => arrayHelpers.push("")}
                          >
                            <Add sx={{ mr: 0.5 }} />
                            <FormattedMessage id="GLOBAL.ADD" />
                          </Button>
                        </Stack>
                      </Box>
                    );
                  }}
                ></FieldArray>

                {/* Information */}
                <Divider sx={{ fontSize: "14px" }}>Information</Divider>

                <Grid container columnSpacing={2}>
                  <Grid item xs={12}>
                    <FormikAutocomplete
                      name="information.trackProgress"
                      label="Track Progress"
                      options={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="information.collectablesText"
                      label="Collectables Text"
                    />
                  </Grid>
                </Grid>

                <Divider sx={{ fontSize: "14px" }}>Tutorial</Divider>
                <Grid container columnSpacing={2}>
                  <Grid item xs={12}>
                    <FormikAutocomplete
                      name="information.enableTutorial"
                      label="Enable Tutorial"
                      options={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                      ]}
                    />
                  </Grid>

                  {values.information?.enableTutorial ? (
                    <>
                      <Grid item xs={12}>
                        <FormikTextField
                          name="information.image"
                          label="Image"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikTextField
                          name="information.title"
                          label="Title"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikTextField
                          name="information.description"
                          label="Description"
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <FormikTextField
                          name="information.position.x"
                          label="Position X"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormikTextField
                          name="information.position.y"
                          label="Position Y"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormikTextField
                          name="information.position.z"
                          label="Position Z"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormikTextField
                          name="information.radius"
                          label="Radius"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormikTextField
                          name="information.unlockedQuizDescription"
                          label="Unlocked Quiz Description"
                        />
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Box>

              {/* Collectables */}
              <Divider sx={{ fontSize: "14px", mt: 3, mx: { xs: 2, sm: 3 } }}>
                Collectables
              </Divider>

              <Table
                data={values.schema}
                columns={columns}
                addData={addData}
                updateData={updateData}
                deleteData={deleteData}
                manualPagination
              />
            </Box>

            {/* FOOTER */}
            <Grid
              container
              spacing={2}
              sx={{ mt: "auto", p: { xs: 2, sm: 3 } }}
            >
              <Grid item xs={3}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  size="medium"
                  type="submit"
                  // onClick={async () => {
                  //   await handleSubmit({
                  //     ...initialValues,
                  //     schema,
                  //   });
                  // }}
                >
                  <FormattedMessage id="GLOBAL.SAVE" />
                </Button>
              </Grid>
              <Grid item xs={3}>
                <input
                  type="file"
                  id="feature-import"
                  style={{ display: "none" }}
                  accept=".csv"
                />
                <Box
                  component="label"
                  sx={{
                    width: "100%",
                    background: (t) => t.palette.success.main,
                    p: "6px 16px",
                    borderRadius: 1,
                    minWidth: 8,
                    fontSize: "0.875rem",
                    fontWeight: "500",
                    height: "100%",
                    display: "block",
                    textTransform: "uppercase",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  color="success"
                  htmlFor={"feature-import"}
                >
                  <FormattedMessage id="GLOBAL.IMPORT" />
                </Box>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
};

export default FeatureCollectablesForm;
