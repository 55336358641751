import {
  KeyboardArrowDown,
  KeyboardArrowRight,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Grid, InputAdornment, Stack, TextField } from "@mui/material";
import React, { FC, useCallback } from "react";
import {
  FormattedMessage,
  // useIntl
} from "react-intl";
import { CellProps } from "react-table";

import { getUsersFriendsCount } from "api/users";
import { usersKeys } from "api/users/queries";
import { UserWithFriendsType, UsersType } from "api/users/types";
import { QueryParamsType } from "api/utils";
import Table, { TableColumn } from "components/Table";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";

import { useFriendsAnalyticsFilter } from "./useFriendsAnalyticsFilter";

interface IFriendsAnalyticsTable {
  additionalFilters?: QueryParamsType<UsersType & { friendCount: number }>;
}

const FriendsAnalyticsTable: FC<IFriendsAnalyticsTable> = ({
  additionalFilters,
}) => {
  // const intl = useIntl();

  const { filters, searchField } = useFriendsAnalyticsFilter(additionalFilters);

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    usersKeys.friendCountFiltered(filters),
    getUsersFriendsCount,
    filters
  );

  const columns: TableColumn<UserWithFriendsType>[] = [
    {
      accessor: "friends",
      Header: "Name",
      Aggregated: ({ value, row }: CellProps<any>) => {
        return (
          <Stack
            direction="row"
            alignItems="center"
            spacing={0.5}
            {...row.getToggleRowExpandedProps()}
          >
            <>
              {row.isExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
              {row.original.name}
            </>
          </Stack>
        );
      },
      Cell: ({ row }: CellProps<any>) => (
        <>
          {"#" + (row.index + 1)} {row.original.name}
        </>
      ),
    },
    {
      accessor: "email",
      Header: "User Email",
      Cell: ({ row }: CellProps<any>) => <>{row.original.email}</>,
      Aggregated: ({ row }) => <>{row.original.email}</>,
    },
    {
      accessor: "friendCount",
      Header: "Friends Count",
      Cell: () => <></>,
      Aggregated: ({ row }) => <>{row.original.friendCount}</>,
    },
  ];

  const getSubRows = useCallback(
    (
      userFriendsRow: UsersType & { friendCount: number; friends: UsersType[] }
    ) => (userFriendsRow.friends ? (userFriendsRow.friends as any[]) : []),
    []
  );

  return (
    <>
      <Grid container sx={{ pt: 1, px: 2 }}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={"Search by name"}
            {...searchField}
          />
        </Grid>
      </Grid>
      <Table
        data={data}
        getSubRows={getSubRows}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
        initialState={{
          sortBy: [
            {
              id: "friendsCount",
              desc: true,
            },
          ],
        }}
      />
    </>
  );
};

export default FriendsAnalyticsTable;
