import { Search as SearchIcon } from "@mui/icons-material";
import { Grid, InputAdornment, TextField } from "@mui/material";
import React, { FC } from "react";
import {
  FormattedMessage,
  // useIntl
} from "react-intl";
import { CellProps } from "react-table";

import { getReferralsAnalytics } from "api/analytics";
import { analyticsKeys } from "api/analytics/queries";
import { RefferalUserType } from "api/analytics/types";
import { QueryParamsType } from "api/utils";
import Table, { TableColumn } from "components/Table";
import { dateTimeFormatter } from "components/Table/formatters/dateTimeFormatter";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";

import { useReferralsFilter } from "./useReferralsFilter";

interface IReferralsTable {
  additionalFilters?: QueryParamsType<RefferalUserType>;
}

const ReferralsTable: FC<IReferralsTable> = ({ additionalFilters }) => {
  // const intl = useIntl();

  const { filters, searchField } = useReferralsFilter(additionalFilters);

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    analyticsKeys.referralsFiltered(filters),
    getReferralsAnalytics,
    filters
  );

  const columns: TableColumn<RefferalUserType>[] = [
    {
      accessor: "email",
      Header: "Email",
      disableSortBy: true,
    },
    {
      id: "referredBy",
      Header: "Referred By",
      Cell: ({ row }: CellProps<RefferalUserType>) => (
        <>{row.original.referredBy?.email}</>
      ),
    },
    {
      accessor: "createdAt",
      Header: "Joined",
      Cell: ({ value }: CellProps<RefferalUserType>) => (
        <>{dateTimeFormatter(value)}</>
      ),
    },
  ];

  return (
    <>
      <Grid container sx={{ pt: 1, px: 2 }}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={
              <FormattedMessage id="USERS.REFERRALS.TABLE.SEARCH_BY" />
            }
            {...searchField}
          />
        </Grid>
      </Grid>
      <Table
        data={data}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
        initialState={{
          pageSize: 100,
          sortBy: [
            {
              id: "createdAt",
              desc: true,
            },
          ],
        }}
      />
    </>
  );
};

export default ReferralsTable;
