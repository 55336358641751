import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { MutationStatus } from "@tanstack/react-query";
import { Formik, Form as FormikForm } from "formik";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

import FormikAutocomplete from "components/forms/FormikAutocomplete";
import FormikNumberField from "components/forms/FormikNumberField";
import FormikTextField from "components/forms/FormikTextField";

export type RewardFormValues = {
  title: string;
  reward: number;
  imgUrl: string;
  description: string;
  type: string;
  region: string;
  quantity: number;
};

interface IRewardForm {
  handleSubmit: (values: RewardFormValues) => void;
  handleClose: () => void;
  initialValues?: RewardFormValues;
  submitStatus?: MutationStatus;
  confirmed?: boolean;
}

const RewardForm: FC<IRewardForm> = ({
  handleSubmit,
  handleClose,
  initialValues = {
    title: "",
    reward: 0,
    imgUrl: "",
    description: "",
    type: "",
    region: "",
    quantity: 0,
  },
  submitStatus,
  confirmed,
}) => {
  const schema = yup.object({
    title: yup.string().required(),
    reward: yup.number().required(),
    imgUrl: yup.string().required(),
    description: yup.string().required(),
    type: yup.string().required(),
    region: yup.string().required(),
    quantity: yup.number().required(),
  });

  const typeOptions = [
    { label: "Physical", value: "Physical" },
    { label: "Digital NFT", value: "Digital NFT" },
    { label: "Avatar NFT", value: "Avatar NFT" },
    { label: "Wearable NFT", value: "Wearable NFT" },
    { label: "Digital", value: "Digital" },
  ];

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      <FormikForm>
        <DialogContent>
          <FormikTextField name="title" type="text" label="Title" />
          <FormikNumberField name="reward" label="Reward Points" />
          <FormikTextField name="imgUrl" type="text" label="Image URL" />
          <FormikTextField
            name="description"
            multiline
            type="text"
            label="Description"
          />
          <FormikAutocomplete options={typeOptions} name="type" label="Type" />
          <FormikTextField name="region" label="Region" />
          <FormikNumberField name="quantity" label="Quantity" />
        </DialogContent>

        <DialogActions>
          <Button
            // data-testid="new-edit-port-close-button"
            onClick={handleClose}
          >
            <FormattedMessage id="GLOBAL.CLOSE" />
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={submitStatus === "loading"}
          >
            <FormattedMessage id="GLOBAL.SAVE" />
          </LoadingButton>
        </DialogActions>
      </FormikForm>
    </Formik>
  );
};

export default RewardForm;
