import { BarChart as BarChartIcon } from "@mui/icons-material";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import TableHeader from "components/Table/components/TableHeader";

const AbadaAnalyticsChartHeader: FC = () => {
  return (
    <>
      <TableHeader
        icon={BarChartIcon}
        label={
          <FormattedMessage id="ANALYTICS.COURSES_ANALYTICS.TABLE.TITLE" />
        }
      ></TableHeader>
    </>
  );
};

export default AbadaAnalyticsChartHeader;
