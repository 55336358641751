import { Add as AddIcon } from "@mui/icons-material";
import { Dialog, DialogTitle, Divider, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";

import { addReward as addRewardAPI } from "api/rewards";
import { rewardsKeys } from "api/rewards/queries";
import { NewRewardItemPayloadType, RewardItemType } from "api/rewards/types";
import { QueryParamsType } from "api/utils";
import RewardForm, {
  RewardFormValues,
} from "containers/rewards/RewardsTable/components/RewardForm";

interface NewRewardModalProps {
  isOpen: boolean;
  handleClose: () => void;
  additionalFilters?: QueryParamsType<RewardItemType>;
}

const NewRewardModal = ({
  isOpen,
  handleClose,
  additionalFilters,
}: NewRewardModalProps) => {
  const queryClient = useQueryClient();

  const { mutateAsync: addReward, status } = useMutation(
    async (data: NewRewardItemPayloadType) => {
      const res = await addRewardAPI(data);
      await queryClient.invalidateQueries(rewardsKeys.list());
      return res.data;
    }
  );

  const handleSubmit = async (values: RewardFormValues) => {
    await addReward(values, {
      onSuccess: () => handleClose(),
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <AddIcon sx={{ mr: 1 }} />
          New Reward
        </Stack>
      </DialogTitle>
      <Divider />

      <RewardForm
        submitStatus={status}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
      />
    </Dialog>
  );
};

export default NewRewardModal;
