import { postVisit } from "api/visit";

export const visit = async ({
  land,
  experience = "default",
  level = "default",
  room,
  anchor,
  token,
}: {
  land: string;
  experience?: string;
  level?: string;
  room?: string;
  anchor?: string;
  token?: string;
}) => {
  const res = await postVisit(
    {
      land,
      level,
      experience,
      room,
      anchor,
    },
    token
  );
  window.location.href = res.data.link;
};
