import { Paper } from "@mui/material";
import React from "react";

import AbadaAnalyticsChart from "./AbadaAnalyticsChart/AbadaAnalyticsChart";
import AbadaAnalyticsChartHeader from "./AbadaAnalyticsChartHeader/AbadaAnalyticsChartHeader";
import AbadaAnalyticsHeader from "./AbadaAnalyticsHeader/AbadaAnalyticsHeader";
import AbadaAnalyticsTable from "./AbadaAnalyticsTable";

const AbadaAnalytics = () => {
  return (
    <>
      <Paper>
        <AbadaAnalyticsChartHeader />
        <AbadaAnalyticsChart />
      </Paper>

      <Paper sx={{ mt: 2 }}>
        <AbadaAnalyticsHeader />
        <AbadaAnalyticsTable />
      </Paper>
    </>
  );
};

export default AbadaAnalytics;
