import request, { APICall } from "../request";

export const getMozillaMedia = ({
  filter,
  cursor,
}: {
  filter: string;
  cursor: number;
}): APICall<any> =>
  request({
    baseURL: "https://appafricarare.io",
    url: `/api/v1/media/search`,
    params: {
      filter,
      source: "avatar_listings",
      cursor,
    },
    method: "GET",
    headers: {
      Authorization: false,
    },
  });
