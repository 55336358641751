import { Box, Paper } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";

import { getSingleMapPoint } from "api/map";
import { mapKeys } from "api/map/queries";

import MapPointMap from "./MapPointMap/MapPointMap";
import MapPointMapHeader from "./MapPointMap/MapPointMapHeader";

const MapDetailsPage = () => {
  const { mapPointId } = useParams<{ mapPointId: string }>();

  const { data: point } = useQuery(
    mapKeys.mapPointId(mapPointId as string),
    async () => {
      const { data: res } = await getSingleMapPoint(mapPointId as string);
      return res.data;
    },
    {
      enabled: Boolean(mapPointId),
    }
  );

  return (
    <>
      <Paper>
        <MapPointMapHeader />
        <Box sx={{ height: "700px", position: "relative" }}>
          <MapPointMap point={point} />
        </Box>
      </Paper>
    </>
  );
};

export default MapDetailsPage;
