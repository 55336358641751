import { Terrain as TerrainIcon } from "@mui/icons-material";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import TableHeader from "components/Table/components/TableHeader";

interface ILandsDetailsHeader {
  landName: string | undefined;
}

const LandsDetailsHeader: FC<ILandsDetailsHeader> = ({ landName }) => {
  return (
    <>
      <TableHeader
        icon={TerrainIcon}
        label={
          <FormattedMessage
            id="LANDS.DETAILS.TABLE.TITLE"
            values={{ landName }}
          />
        }
      ></TableHeader>
    </>
  );
};

export default LandsDetailsHeader;
