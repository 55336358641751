import { useAuth0 } from "@auth0/auth0-react";
import React, { FC } from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";

import { LayoutSplashScreen } from "components/providers/SplashScreenProvider";

import { AuthPageWrapper } from "./AuthPageWrapper";

const AuthRoute: FC<RouteProps> = (props) => {
  const { isAuthenticated, isLoading, logout } = useAuth0();
  const location = useLocation<{ unauthorized401?: boolean }>();

  if (isLoading) {
    return <LayoutSplashScreen />;
  }

  if (isAuthenticated) {
    if (location.state?.unauthorized401) {
      logout({ returnTo: `${window.location.origin}/login` });
      return <LayoutSplashScreen />;
    }

    if (location.pathname === "/login") {
      return <Redirect to="/" />;
    }
  }

  return (
    <AuthPageWrapper>
      <Route {...props} />
    </AuthPageWrapper>
  );
};

export default AuthRoute;
