// import { Reward } from '@api/generated';
import { RewardItem } from "api/generated";
import { Paginated, QueryParamsType, normalizeQueryParams } from "api/utils";

import request, { APICall } from "../request";
import {
  EditRewardItemType,
  NewRewardItemPayloadType,
  RedeemRewardType,
} from "./types";

export const getUserRewards = (): APICall<{ data: { data: any } }> =>
  request({
    url: `/reward`,
    method: "GET",
  });

// TODO: update when type is generated
export const getAllRewards = (
  queryParams?: QueryParamsType<RewardItem>
): APICall<Paginated<RewardItem[]>> =>
  request({
    url: `/reward-item${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const redeemReward = (data: RedeemRewardType): APICall<{ data: any }> =>
  request({
    data: data,
    url: "/reward/user/rewardRedem",
    method: "POST",
  });

export const updateReward = (
  data: EditRewardItemType
): APICall<{ data: any }> =>
  request({
    data: data,
    url: `/reward-item/${data._id}`,
    method: "PUT",
  });

export const addReward = (
  data: NewRewardItemPayloadType
): APICall<{ data: any }> =>
  request({
    data: data,
    url: "/reward-item",
    method: "POST",
  });

export const deleteReward = (id: string): APICall<{ data: any }> =>
  request({
    url: `/reward-item/${id}`,
    method: "DELETE",
  });
