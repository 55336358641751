import { useMapEvents } from "react-leaflet";
import { useHistory, useLocation } from "react-router";

const DashboardMapURL = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(history.location.search);

  const map = useMapEvents({
    moveend() {
      const zoom = map.getZoom();
      const { lat, lng } = map.getCenter();

      params.set("lat", lat.toFixed(0));
      params.set("lng", lng.toFixed(0));
      params.set("zoom", zoom.toFixed(0));

      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      });
    },
  });

  return null;
};

export default DashboardMapURL;
