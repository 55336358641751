const mapKeys = {
  all: ["map"] as const,
  list: () => [...mapKeys.all, "list"],
  listFiltered: (filters: Record<string, unknown>) => [
    ...mapKeys.list(),
    filters,
  ],
  mapPoints: () => [...mapKeys.all, "map-points"] as const,
  mapPoint: (lat: string, lng: string) =>
    [...mapKeys.all, "map-point", lat, lng] as const,
  mapPointId: (id: string) => [...mapKeys.all, "map-point", id] as const,
};

export { mapKeys };
