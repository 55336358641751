import { Search as SearchIcon } from "@mui/icons-material";
import { Grid, InputAdornment, TextField } from "@mui/material";
import React, { FC } from "react";
import {
  FormattedMessage,
  // useIntl
} from "react-intl";
import { CellProps } from "react-table";

import { getAbadaAnalytics } from "api/analytics";
import { analyticsKeys } from "api/analytics/queries";
import { RefferalUserType } from "api/analytics/types";
import { QueryParamsType } from "api/utils";
import Table, { TableColumn } from "components/Table";
import { dateTimeFormatter } from "components/Table/formatters/dateTimeFormatter";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";

import { useAbadaAnalyticsFilter } from "./useAbadaAnalyticsFilter";

interface IAbadaAnalyticsTable {
  additionalFilters?: QueryParamsType<RefferalUserType>;
}

const AbadaAnalyticsTable: FC<IAbadaAnalyticsTable> = ({
  additionalFilters,
}) => {
  // const intl = useIntl();

  const { filters, searchField } = useAbadaAnalyticsFilter(additionalFilters);

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    analyticsKeys.abadaAnalyticsFiltered(filters),
    getAbadaAnalytics,
    filters
  );

  const columns: TableColumn<any>[] = [
    {
      id: "email",
      Header: "User Email",
      disableSortBy: true,
      Cell: ({ row }: CellProps<any>) => <>{row.original.user?.email}</>,
    },
    {
      accessor: "dayStreak",
      Header: "Day Streak",
    },
    {
      accessor: "balance",
      Header: "Balance",
    },
    {
      accessor: "collectedDate",
      Header: "Collected Time",
      Cell: ({ value }: CellProps<any>) => <>{dateTimeFormatter(value)}</>,
    },
  ];

  return (
    <>
      <Grid container sx={{ pt: 1, px: 2 }}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={
              <FormattedMessage id="USERS.ABADA_ANALYTICS.TABLE.SEARCH_BY" />
            }
            {...searchField}
          />
        </Grid>
      </Grid>
      <Table
        data={data}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
        initialState={{
          pageSize: 100,
          sortBy: [
            {
              id: "collectedDate",
              desc: true,
            },
          ],
        }}
      />
    </>
  );
};

export default AbadaAnalyticsTable;
