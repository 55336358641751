import { FC } from "react";
import { useIntl } from "react-intl";
import { CellProps } from "react-table";

import { getAllRewards } from "api/rewards";
import { rewardsKeys } from "api/rewards/queries";
import { RewardItemType } from "api/rewards/types";
import { QueryParamsType } from "api/utils";
import Table, { TableColumn } from "components/Table";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";
import { useModal } from "utils/hooks/useModal";

import DeleteRewardModal from "./components/DeleteRewaradModal";
import EditRewardModal from "./components/EditRewardModal";
import RewardsActionsFormatter from "./formatters/RewardsActionsFormatters";
import { useRewardsFilter } from "./useRewardsFilter";
// import { hasPermissions } from "components/stores/UserStore";

interface IRewardsTable {
  additionalFilters?: QueryParamsType<RewardItemType>;
}

const RewardsTable: FC<IRewardsTable> = () => {
  const intl = useIntl();

  const { filters } = useRewardsFilter();

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    rewardsKeys.listFiltered(filters),
    getAllRewards,
    filters
  );

  const {
    isOpen: isOpenDelete,
    handleClose: handleCloseDelete,
    handleOpen: handleOpenDelete,
    context: deleteContext,
  } = useModal<RewardItemType>();

  const {
    isOpen: isEditOpen,
    handleClose: handleCloseEdit,
    handleOpen: handleOpenEdit,
    context: editContext,
  } = useModal<RewardItemType>();

  const columns: TableColumn<RewardItemType>[] = [
    {
      accessor: "imgUrl",
      Header: "Image",
      Cell: (cell: CellProps<RewardItemType>) => (
        <img
          src={cell.value}
          alt={cell.value}
          style={{ width: "100px", height: "100px", objectFit: "contain" }}
        />
      ),
      disableSortBy: true,
    },
    {
      accessor: "title",
      Header: "Title",
      disableSortBy: true,
    },

    {
      accessor: "type",
      Header: "Type",
      disableSortBy: true,
    },
    {
      accessor: "region",
      Header: "Region",
      disableSortBy: true,
    },
    {
      accessor: "quantity",
      Header: "Quantity",
      disableSortBy: true,
    },
    {
      accessor: "reward",
      Header: "Reward Points",
      disableSortBy: true,
    },
    {
      id: "actions",
      Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
      Cell: (cell: CellProps<RewardItemType>) => (
        <RewardsActionsFormatter
          {...cell}
          handleOpenEdit={handleOpenEdit}
          handleOpenDelete={handleOpenDelete}
        />
      ),
      align: "right",
    },
  ];

  return (
    <>
      <Table
        data={data}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
      />
      <DeleteRewardModal
        isOpen={isOpenDelete}
        handleClose={handleCloseDelete}
        reward={deleteContext}
      />
      <EditRewardModal
        isOpen={isEditOpen}
        handleClose={handleCloseEdit}
        reward={editContext}
      />
    </>
  );
};

export default RewardsTable;
