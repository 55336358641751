import { Circle, Search as SearchIcon } from "@mui/icons-material";
import { Chip, Grid, InputAdornment, TextField } from "@mui/material";
import React, { FC } from "react";
import {
  FormattedMessage,
  // useIntl
} from "react-intl";
import { CellProps } from "react-table";

import { getAnalyticVisitors } from "api/analytics";
import { analyticsKeys } from "api/analytics/queries";
import { VisitorsType } from "api/analytics/types";
import { QueryParamsType } from "api/utils";
import Table, { TableColumn } from "components/Table";
import { dateTimeFormatter } from "components/Table/formatters/dateTimeFormatter";
import { milisecondsFormatter } from "components/Table/formatters/milisecondsFormatter";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";

import { useVisitorsFilter } from "./useVisitorsFilter";

interface IVisitorsTable {
  additionalFilters?: QueryParamsType<VisitorsType>;
}

const VisitorsTable: FC<IVisitorsTable> = ({ additionalFilters }) => {
  // const intl = useIntl();

  const { filters, searchField } = useVisitorsFilter(additionalFilters);

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    analyticsKeys.visitorsFiltered(filters),
    getAnalyticVisitors,
    filters
  );

  const columns: TableColumn<VisitorsType>[] = [
    {
      accessor: "active",
      Header: "Active",
      Cell: ({ value }: CellProps<VisitorsType>) => (
        <>
          <Chip
            icon={
              <Circle
                color="success"
                // sx={{ animation: `${blink} 1s linear infinite` }}
              />
            }
            sx={{
              p: 0,
              height: "29px",
              color: "#FFF",
              ".MuiChip-label": {
                paddingTop: "3px",
              },
            }}
            label={value ? "Active" : "Inactive"}
            color={value ? "success" : "error"}
          ></Chip>
        </>
      ),
    },
    {
      accessor: "visitor",
      Header: "Visitor",
      Cell: ({ value }: CellProps<VisitorsType>) => (
        <>{value?.username || value?.uid}</>
      ),
    },
    {
      accessor: "target",
      Header: "Target",
      Cell: ({ value }: CellProps<VisitorsType>) => (
        <>
          {value.land?.name} / {value.experience?.name} / {value.level?.name} /{" "}
          {value.room?.hash}/{value.room?.name}
        </>
      ),
    },
    {
      accessor: "duration",
      Header: "Duration",
      Cell: ({ value }: CellProps<VisitorsType>) => (
        <>{milisecondsFormatter(value)}</>
      ),
    },
    {
      accessor: "createdAt",
      Header: "Joined",
      Cell: ({ value }: CellProps<VisitorsType>) => (
        <>{dateTimeFormatter(value)}</>
      ),
    },
    {
      accessor: "updatedAt",
      Header: "Last Change",
      Cell: ({ value }: CellProps<VisitorsType>) => (
        <>{dateTimeFormatter(value)}</>
      ),
    },
  ];

  return (
    <>
      <Grid container sx={{ pt: 1, px: 2 }}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={<FormattedMessage id="USERS.TABLE.SEARCH_BY" />}
            {...searchField}
          />
        </Grid>
      </Grid>
      <Table
        data={data}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
        initialState={{
          pageSize: 100,
          sortBy: [
            {
              id: "updatedAt",
              desc: true,
            },
          ],
        }}
      />
    </>
  );
};

export default VisitorsTable;
