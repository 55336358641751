import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import { Box, Fade, Stack, Typography } from "@mui/material";
import React from "react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.min.css";
import { MapPointType } from "api/map/types";
SwiperCore.use([Autoplay, Navigation]);

const colors = {
  "for-sale": "#fff",
  basic: "#676767",
  owned: "#e78725",
  precious: "#6767c1",
};

const MapPointPopup = ({ point }: { point: MapPointType }) => {
  return (
    <Fade in={Boolean(point)} unmountOnExit>
      <Box
        className="leaflet-control"
        sx={{
          position: "absolute",
          top: 12,
          left: 12,
          width: "420px",
          height: "calc(100% - 24px)",
          background: "#181818",
          border: "1px solid #363636",
          p: 3,
          color: "#fff",
          overflow: "scroll",
        }}
      >
        {/* Header */}
        <Stack direction="row" spacing={2}>
          <Box
            component="img"
            width={100}
            height={100}
            sx={{ border: "1px solid #363636", objectFit: "contain" }}
            src={
              point?.landImage ??
              "https://firebasestorage.googleapis.com/v0/b/africarare-land.appspot.com/o/lands%2Fland.png?alt=media&token=54e25149-fc10-49de-a216-474432673d63"
            }
          ></Box>

          <Stack direction="column" justifyContent="space-between">
            <Typography
              fontWeight="bold"
              fontSize={15}
              lineHeight={1.2}
              height={30}
              pr={2}
            >
              {point?.name || "Africarare Village"} - {point?.id}
            </Typography>

            <Box>
              <Typography fontSize={13} height={13} mb={1}>
                Type:{" "}
                <Typography
                  component="span"
                  color={colors[point?.type ?? "basic"]}
                  fontSize="inherit"
                >
                  {point?.type ?? "-"}
                </Typography>
              </Typography>

              <Typography fontSize={13} height={13} mb={1}>
                Size: {point?.size ?? "-"}
              </Typography>

              <Typography fontSize={13} height={13}>
                Coordinates:{" "}
                {point
                  ? Math.floor(
                      Math.floor(point.bounds[0][0]) +
                        (Math.floor(point.bounds[1][0]) -
                          Math.floor(point.bounds[0][0])) /
                          2
                    )
                  : "-"}
                ,{" "}
                {point
                  ? Math.floor(
                      Math.floor(point.bounds[0][1]) +
                        (Math.floor(point.bounds[1][1]) -
                          Math.floor(point.bounds[0][1])) /
                          2
                    )
                  : "-"}
              </Typography>
            </Box>
          </Stack>
        </Stack>

        {/* Slider */}
        <Box sx={{ mt: 4, position: "relative" }}>
          <Box
            className="village-slider-prev"
            sx={{
              position: "absolute",
              top: "50%",
              left: "10px",
              transform: "translateY(-50%)",
              zIndex: 2,
              padding: "10px",
              background: "#fff",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            <ChevronLeftIcon sx={{ color: "black" }} />
          </Box>
          <Box
            className="village-slider-next"
            sx={{
              position: "absolute",
              top: "50%",
              right: "10px",
              transform: "translateY(-50%)",
              zIndex: 2,
              padding: "10px",
              background: "#fff",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            <ChevronRightIcon sx={{ color: "black" }} />
          </Box>
          <Swiper
            slidesPerView={1}
            loop
            autoplay={{ delay: 4000, disableOnInteraction: false }}
            style={{ border: "1px solid #363636" }}
            className="village-slider"
            navigation={{
              prevEl: ".village-slider-prev",
              nextEl: ".village-slider-next",
            }}
          >
            {(point?.sliderImages?.length
              ? (point?.sliderImages as string[])
              : [
                  "https://firebasestorage.googleapis.com/v0/b/africarare-land.appspot.com/o/lands%2Fland.png?alt=media&token=54e25149-fc10-49de-a216-474432673d63",
                ]
            ).map((slide, index) => (
              <SwiperSlide key={index}>
                <img
                  src={slide}
                  alt="Village"
                  height="190"
                  width="100%"
                  style={{ objectFit: "contain", maxHeight: "100%" }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>

        {/* Description */}
        <Box sx={{ mt: 2, color: "#fff" }}>
          <Typography fontSize={14}>
            {point?.description || "Empty Land"}
          </Typography>
        </Box>
      </Box>
    </Fade>
  );
};

export default MapPointPopup;
