import { Box, Button, Grid } from "@mui/material";
import { MutationStatus } from "@tanstack/react-query";
import { Form, FormikProvider, useFormik } from "formik";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

import FormikNumberField from "components/forms/FormikNumberField";
import FormikTextField from "components/forms/FormikTextField";

export type FeatureGameFormValues = {
  information: {
    players: number | null;
  };
};

interface IFeatureGameForm {
  handleSubmit: (values: FeatureGameFormValues) => void;
  initialValues?: FeatureGameFormValues;
  submitStatus?: MutationStatus;
  handleOpenDelete: () => void;
}

const FeatureGameForm: FC<IFeatureGameForm> = ({
  initialValues = {
    information: {
      players: null,
      redirect: "",
    },
  },
  handleSubmit,
  submitStatus,
  handleOpenDelete,
}) => {
  const schema = yup.object({
    information: yup.object().shape({
      players: yup.number().required(),
      redirect: yup.string().optional(),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <FormikProvider value={formik}>
        <Form
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <Box sx={{ p: { xs: 2, sm: 3 } }}>
            <Grid container spacing={1} sx={{ mb: 1.5 }}>
              <Grid item xs={12} lg={12}>
                <FormikNumberField
                  label="Number of players"
                  name={`information.players`}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <FormikTextField
                  label="Redirect"
                  name={`information.redirect`}
                />
              </Grid>
            </Grid>
          </Box>

          {/* FOOTER */}
          <Grid container spacing={2} sx={{ mt: "auto", p: { xs: 2, sm: 3 } }}>
            <Grid item xs={3}>
              <Button
                sx={{ width: "100%" }}
                variant="contained"
                size="medium"
                type="submit"
              >
                <FormattedMessage id="GLOBAL.SAVE" />
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                sx={{ width: "100%" }}
                color="error"
                variant="contained"
                onClick={handleOpenDelete}
                size="medium"
              >
                <FormattedMessage id="GLOBAL.DELETE" />
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
};

export default FeatureGameForm;
