import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { visit } from "utils/visit/visit";

const VisitPage = () => {
  const { getIdTokenClaims, loginWithRedirect } = useAuth0();

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const room = query.get("room") ?? undefined;
  const anchor = query.get("anchor") ?? undefined;

  const { land, experience, level } = useParams<{
    land: string;
    experience: string;
    level: string;
  }>();

  const handleJoinAsUser = async () => {
    const res = await getIdTokenClaims();
    const token = query.get("token") ?? "";

    if (token) {
      visit({
        land,
        experience,
        level,
        room,
        anchor,
        token: token,
      });
    } else if (res) {
      visit({
        land,
        experience,
        level,
        room,
        anchor,
        token: res.__raw,
      });
    } else {
      const urlParams = {
        land: land ?? "",
        experience: experience ?? "",
        level: level ?? "",
        room: room ?? "",
        anchor: anchor ?? "",
      };
      const query = new URLSearchParams(urlParams);

      loginWithRedirect({
        redirectUri: `https://app.africarare.io/visit/direct?${query.toString()}`,
      });
    }
  };

  useEffect(() => {
    handleJoinAsUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default VisitPage;
