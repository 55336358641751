const rewardsKeys = {
  all: ["rewards"] as const,
  list: () => [...rewardsKeys.all, "list"],
  listFiltered: (filters: Record<string, unknown>) => [
    ...rewardsKeys.list(),
    filters,
  ],
  allRewards: () => [...rewardsKeys.all, "allRewards"],
};

export { rewardsKeys };
