import { Box, Button, Divider, Paper, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { getSingleLand } from "api/lands";
import { landsKeys } from "api/lands/queries";
import PreviewImageDefaultPNG from "assets/imgs/preview-image-default.png";
import { hasPermissions } from "components/stores/UserStore";
import VisitorsTable from "containers/users/visitors/VisitorsTable";

import ExperiencesHeader from "./ExperiencesHeader/ExperiencesHeader";
import ExperiencesTable from "./ExperiencesTable";
import LandsAnalyticsChart from "./LandsAnalyticsChart/LandsAnalyticsChart";
import LandsAnalyticsHeader from "./LandsAnalyticsHeader/LandsAnalyticsHeader";
import LandsDetailsHeader from "./LandsDetailsHeader/LandsDetailsHeader";
import LandSlider from "./LandSlider/LandSlider";

const ExperiencesPage = () => {
  const [expandVisitors, setExpandVisitors] = useState(false);
  const { landId } = useParams<{ landId: string }>();

  const { data: land } = useQuery(landsKeys.details(landId), async () => {
    const { data: res } = await getSingleLand(landId);
    return res.data;
  });

  return (
    <>
      <Paper>
        <LandsDetailsHeader landName={land?.name} />
        <Stack direction="row">
          <LandSlider
            slides={
              land?.previewImages?.length
                ? land.previewImages
                : [PreviewImageDefaultPNG]
            }
          />
        </Stack>
      </Paper>

      {hasPermissions("read:analytics") && (
        <>
          <Paper sx={{ mt: 2 }}>
            <LandsAnalyticsHeader landId={landId} />
            <LandsAnalyticsChart landId={landId} />

            <Divider sx={{ mt: 1.5 }} />
            <Button
              fullWidth
              sx={{
                display: !expandVisitors ? "block" : "none",
                fontSize: "16px",
                py: 1.5,
              }}
              onClick={() => setExpandVisitors(true)}
            >
              EXPAND
            </Button>

            <Box sx={{ display: expandVisitors ? "block" : "none" }}>
              <VisitorsTable
                additionalFilters={{
                  filter: [
                    {
                      //@ts-expect-error
                      field: "target.land",
                      operator: "is",
                      value: landId ?? "",
                    },
                  ],
                }}
              />
            </Box>
          </Paper>
        </>
      )}

      <Paper sx={{ mt: 2 }}>
        <ExperiencesHeader landId={landId} />
        <ExperiencesTable land={land} />
      </Paper>
    </>
  );
};

export default ExperiencesPage;
