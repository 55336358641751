import { Add } from "@mui/icons-material";
import { Box, Button, Divider, Grid, Stack } from "@mui/material";
import { MutationStatus, useQuery } from "@tanstack/react-query";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { getRelationalFeatures } from "api/features";
import { featuresKeys } from "api/features/queries";
import FormikAutocomplete from "components/forms/FormikAutocomplete";
import FormikCheckbox from "components/forms/FormikCheckbox";
import FormikNumberField from "components/forms/FormikNumberField";
import FormikTextField from "components/forms/FormikTextField";

export type FeatureAICourseFormValues = {
  information: {
    quizes: string[];
    title: string;
    courseType: "free" | "paid";
    payCurrency: "creditCard" | "ubu";
    isHidden: boolean;
  };
  schema: {}[];
};

interface IFeatureAICourseForm {
  featureId: string;
  handleSubmit: (values: FeatureAICourseFormValues) => void;
  initialValues?: FeatureAICourseFormValues;
  submitStatus?: MutationStatus;
  handleOpenDelete: () => void;
}

const FeatureAICourseForm: FC<IFeatureAICourseForm> = ({
  featureId,
  initialValues = {
    information: {
      quizes: [],
      title: "",
      courseType: "free",
      payCurrency: "creditCard",
      isHidden: false,
    },
    schema: [],
  },
  handleSubmit,
  submitStatus,
  handleOpenDelete,
}) => {
  const { data: relationalFeatures } = useQuery(
    featuresKeys.relations(featureId),
    async () => {
      const { data: res } = await getRelationalFeatures(featureId);
      return res.data;
    },
    {
      enabled: Boolean(featureId),
    }
  );

  const quizesFeatures = useMemo(() => {
    if (relationalFeatures?.length) {
      return relationalFeatures
        .filter((filter) => filter.type === "quiz")
        .map((feature) => ({
          value: feature._id,
          label: `Quiz - ${feature.parent}`,
        }));
    }

    return [];
  }, [relationalFeatures]);

  // const [schema, setSchema] = useState(initialValues.schema ?? []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    // validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const { values } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Box sx={{ py: { xs: 1, sm: 2 } }}>
              <Box sx={{ px: { xs: 2, sm: 3 } }}>
                {/* Relations */}
                <Divider sx={{ fontSize: "14px" }}>Relations</Divider>

                <FieldArray
                  name="information.quizes"
                  render={(arrayHelpers) => {
                    return (
                      <Box sx={{ mb: 1 }}>
                        {values.information?.quizes?.map((_id, index) => {
                          return (
                            <FormikAutocomplete
                              name={`information.quizes.${index}`}
                              label="Quiz"
                              options={quizesFeatures}
                            />
                          );
                        })}
                        <Stack
                          sx={{ height: "50px" }}
                          direction="row"
                          alignItems="center"
                          justifyContent="start"
                        >
                          <Button
                            variant="contained"
                            onClick={() => arrayHelpers.push("")}
                          >
                            <Add sx={{ mr: 0.5 }} />
                            <FormattedMessage id="GLOBAL.ADD" />
                          </Button>
                        </Stack>
                      </Box>
                    );
                  }}
                ></FieldArray>

                {/* Information */}
                <Divider sx={{ fontSize: "14px" }}>Information</Divider>

                <FormikTextField name="information.image" label="Image" />
                <FormikTextField
                  name="information.bannerImage"
                  label="Banner Image"
                />
                <FormikTextField name="information.title" label="Title" />
                <FormikTextField
                  name="information.instructorName"
                  label="Instructor Name"
                />
                <FormikTextField
                  name="information.certificateDescription"
                  label="Certificate Description"
                />
                <FormikTextField
                  name="information.certificateLevel"
                  label="Certificate Level"
                />
                <FormikTextField name="information.edition" label="Edition" />
                <FormikTextField
                  required
                  name="information.certificateImagePlaceholder"
                  label="Certificate Image Placeholder"
                />
                <FormikTextField
                  required
                  name="information.mainColor"
                  label="Main Color"
                />
                <FormikCheckbox name="information.isHidden" label="Is Hidden" />
                <FormikTextField
                  name="information.landingPageUrl"
                  label="Landing Page URL"
                />
                <FormikTextField
                  name="information.contractAddress"
                  label="Contract Address"
                />
                <FormikTextField
                  name="information.mintCode"
                  label="Mint Code"
                />
                <FormikTextField
                  name="information.mintPrice"
                  label="Mint Price"
                />
                <FormikTextField
                  name="information.mintCurrency"
                  label="Mint Currency"
                />
                <FormikAutocomplete
                  name="information.courseType"
                  label="Course Type"
                  options={[
                    { value: "free", label: "Free" },
                    { value: "paid", label: "Paid" },
                  ]}
                />
                {values.information?.courseType === "paid" && (
                  <FormikAutocomplete
                    name="information.payCurrency"
                    label="Pay Currency"
                    options={[
                      { value: "creditCard", label: "Credit Card" },
                      { value: "ubu", label: "UBU" },
                    ]}
                  />
                )}
                {values.information?.courseType === "paid" &&
                  values.information?.payCurrency !== "ubu" && (
                    <>
                      <FormikTextField
                        name="information.stripeProductId"
                        required
                        label="Stripe Product ID"
                      />
                      <FormikTextField
                        name="information.stripeBuyUrl"
                        required
                        label="Stripe Buy URL"
                      />
                    </>
                  )}
                {values.information?.courseType === "paid" && (
                  <FormikNumberField
                    name="information.buyPrice"
                    required
                    label="Buy Price"
                  />
                )}
              </Box>
            </Box>

            {/* FOOTER */}
            <Grid
              container
              spacing={2}
              sx={{ mt: "auto", p: { xs: 2, sm: 3 } }}
            >
              <Grid item xs={3}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  size="medium"
                  type="submit"
                  // onClick={async () => {
                  //   await handleSubmit({
                  //     ...initialValues,
                  //     schema,
                  //   });
                  // }}
                >
                  <FormattedMessage id="GLOBAL.SAVE" />
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  sx={{ width: "100%" }}
                  color="error"
                  variant="contained"
                  onClick={handleOpenDelete}
                  size="medium"
                >
                  <FormattedMessage id="GLOBAL.DELETE" />
                </Button>
              </Grid>

              <Grid item xs={3}></Grid>
            </Grid>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
};

export default FeatureAICourseForm;
