import { Box, Button, Grid } from "@mui/material";
import { MutationStatus } from "@tanstack/react-query";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";

import Table, { TableColumn } from "components/Table/EditTable";

export type FeatureExhibitImagesFormValues = {
  schema: {
    nameInSpoke: string | null;
  }[];
};

interface IFeatureExhibitImagesForm {
  handleSubmit: (values: FeatureExhibitImagesFormValues) => void;
  initialValues?: FeatureExhibitImagesFormValues;
  submitStatus?: MutationStatus;
  handleOpenDelete: () => void;
}

const FeatureExhibitImagesForm: FC<IFeatureExhibitImagesForm> = ({
  initialValues = {
    schema: [],
  },
  handleSubmit,
  submitStatus,
  handleOpenDelete,
}) => {
  const [schema, setSchema] = useState(initialValues.schema ?? []);
  const columns: TableColumn<any>[] = [
    {
      id: "object",
      Header: "Object",
      columns: [
        {
          accessor: "nameInSpoke",
          Header: "Name In Spoke",
        },
      ],
    },
    {
      id: "popup",
      Header: "Popup",
      columns: [
        {
          accessor: "image",
          Header: "Image",
        },
        {
          accessor: "name",
          Header: "Name",
        },
        {
          accessor: "title",
          Header: "Title",
        },
        {
          accessor: "description",
          Header: "Description",
        },
      ],
    },
  ];

  const addData = async () => {
    const newSchema = [
      ...schema,
      {
        nameInSpoke: "",
        image: "",
        name: "",
        title: "",
        description: "",
      },
    ];
    setSchema(newSchema);
  };

  const deleteData = async (rowIndex: number) => {
    const newSchema = [...schema].filter((row, index) => index !== rowIndex);
    setSchema(newSchema);
  };

  const updateData = async (rowIndex: number, columnId: string, value: any) => {
    const newSchema = [...schema].map((row, index) => {
      if (index === rowIndex) {
        return {
          ...row,
          [columnId]: value,
        };
      }
      return row;
    });

    setSchema(newSchema);
  };

  return (
    <>
      <Box style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Box sx={{ py: { xs: 1, sm: 2 } }}>
          <Table
            data={schema}
            columns={columns}
            addData={addData}
            updateData={updateData}
            deleteData={deleteData}
            manualPagination
          />
        </Box>

        {/* FOOTER */}
        <Grid container spacing={2} sx={{ mt: "auto", p: { xs: 2, sm: 3 } }}>
          <Grid item xs={3}>
            <Button
              sx={{ width: "100%" }}
              variant="contained"
              size="medium"
              onClick={async () => {
                await handleSubmit({
                  ...initialValues,
                  schema,
                });
              }}
            >
              <FormattedMessage id="GLOBAL.SAVE" />
            </Button>
          </Grid>
          <Grid item xs={3}>
            <input
              type="file"
              id="feature-import"
              style={{ display: "none" }}
              accept=".csv"
            />
            <Box
              component="label"
              sx={{
                width: "100%",
                background: (t) => t.palette.success.main,
                p: "6px 16px",
                borderRadius: 1,
                minWidth: 8,
                fontSize: "0.875rem",
                fontWeight: "500",
                height: "100%",
                display: "block",
                textTransform: "uppercase",
                textAlign: "center",
                cursor: "pointer",
              }}
              color="success"
              htmlFor={"feature-import"}
            >
              <FormattedMessage id="GLOBAL.IMPORT" />
            </Box>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Box>
    </>
  );
};

export default FeatureExhibitImagesForm;
