import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Typography } from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

const Login: FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect();
  };

  return (
    <Box
      sx={{ width: "100%", maxWidth: { sm: "100%", md: "350px", lg: "400px" } }}
    >
      <Box sx={{ mb: 5, textAlign: "center" }}>
        <Typography variant="h1" component="h3" sx={{ mb: 1 }}>
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </Typography>
        <Typography
          sx={{ fontWeight: (t) => t.typography.fontWeightMedium }}
          className="text-muted font-weight-bold"
        >
          <FormattedMessage id="AUTH.LOGIN.SUBTITLE" />
        </Typography>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Button variant="contained" size="large" onClick={handleLogin}>
          <FormattedMessage id="AUTH.LOGIN.BUTTON" />
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
