import {
  Add as AddIcon,
  DeleteOutline as DeleteIcon,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import { MutationStatus, useQuery } from "@tanstack/react-query";
import {
  FieldArray,
  Form as FormikForm,
  FormikProvider,
  useFormik,
} from "formik";
import React, { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

import { getCustomers } from "api/users";
import { usersKeys } from "api/users/queries";
import FormikAutocomplete from "components/forms/FormikAutocomplete";
export type LandHostsFormValues = {
  hosts: any[];
};

interface ILandHostsForm {
  handleSubmit: (values: LandHostsFormValues) => void;
  handleClose: () => void;
  initialValues?: LandHostsFormValues;
  submitStatus?: MutationStatus;
}

const LandHostsForm: FC<ILandHostsForm> = ({
  handleSubmit,
  handleClose,
  initialValues = {
    hosts: [],
  },
  submitStatus,
}) => {
  const schema = yup.object({
    hosts: yup.array().of(yup.string().nullable().required()),
  });

  const { data: users } = useQuery(usersKeys.customers(), async () => {
    const { data: res } = await getCustomers();
    return res.data;
  });

  const usersOptions = useMemo(() => {
    if (users?.length) {
      return users.map((user) => ({
        value: user._id,
        label: user.email,
      }));
    }

    return [];
  }, [users]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const { values } = formik;

  console.log("VALUES", values);

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <DialogContent>
          <Box>
            <FieldArray
              name="hosts"
              render={(arrayHelpers) => {
                return (
                  <Box sx={{ mb: 1 }}>
                    {values.hosts.map((user, index) => {
                      return (
                        <Grid
                          container
                          spacing={1}
                          sx={{ mb: 1.5 }}
                          key={`host-${index}`}
                        >
                          <Grid item xs={12} lg={11}>
                            <FormikAutocomplete
                              name={`hosts.${index}`}
                              label="User Email"
                              options={usersOptions}
                            />
                          </Grid>
                          <Grid item xs={12} lg={1}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="center"
                              sx={{ height: "100%", maxHeight: "50px" }}
                            >
                              <IconButton
                                color="primary"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Stack>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Stack
                      sx={{ height: "50px" }}
                      direction="row"
                      alignItems="center"
                      justifyContent="start"
                    >
                      <Button
                        variant="contained"
                        onClick={() => arrayHelpers.push(null)}
                      >
                        <AddIcon sx={{ mr: 0.5 }} />
                        <FormattedMessage id="GLOBAL.ADD" />
                      </Button>
                    </Stack>
                  </Box>
                );
              }}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            // data-testid="new-edit-port-close-button"
            onClick={handleClose}
          >
            <FormattedMessage id="GLOBAL.CLOSE" />
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={submitStatus === "loading"}
          >
            <FormattedMessage id="GLOBAL.SAVE" />
          </LoadingButton>
        </DialogActions>
      </FormikForm>
    </FormikProvider>
  );
};

export default LandHostsForm;
