import { EmailOutlined as EmailOutlinedIcon } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import EthTokenWEBP from "assets/imgs/eth-token.webp";
import { calculateX, calculateY } from "utils/map/calculatePosition";
import { WithChildren } from "utils/types";

interface IDashboardMapSidebar {
  setSizes: any;
}

const DashboardMapSidebar: FC<WithChildren<IDashboardMapSidebar>> = ({
  children,
  setSizes,
}) => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);

  const handleSetSize = (val: boolean, selectedSize: string) => {
    if (val === true) {
      setSizes((p: any) => [...p, selectedSize]);
    } else {
      setSizes((p: any) => p.filter((size: string) => size !== selectedSize));
    }
  };

  return (
    <Box
      className="leaflet-control"
      sx={{
        // position: "absolute",
        top: 0,
        right: 0,
        minWidth: "300px",
        width: "300px",
        height: "100%",
        borderLeft: "1px solid #363636",
        background: "#181818",
        color: "#717171",
        overflowY: "scroll",
      }}
    >
      {/* Minimap */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ borderBottom: "1px solid #363636" }}
      >
        {children}
      </Stack>

      {/* Type */}
      <Stack
        direction="column"
        sx={{ px: 2, py: 1.5, borderBottom: "1px solid #363636" }}
      >
        <Box>
          <Typography textTransform="uppercase" mb={1}>
            Type
          </Typography>
        </Box>
        <Stack direction="row" alignItems="center" mb={1}>
          <Box
            sx={{
              width: 17,
              height: 17,
              background: "#e78725",
              mr: 1,
              border: "3px solid #fff",
            }}
          ></Box>
          <Typography fontSize={14} height={16}>
            My Land
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" mb={1}>
          <Box
            sx={{
              width: 17,
              height: 17,
              background: "#e78725",
              mr: 1,
              border: "1px solid #fff",
            }}
          ></Box>
          <Typography fontSize={14} height={16}>
            Owned
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" mb={1}>
          <Box
            sx={{
              width: 17,
              height: 17,
              background: "#fff",
              mr: 1,
              border: "1px solid #fff",
            }}
          ></Box>
          <Typography fontSize={14} height={16}>
            For Sale
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" mb={1}>
          <Box
            sx={{
              width: 17,
              height: 17,
              background: "#6767c1",
              mr: 1,
              border: "1px solid #fff",
            }}
          ></Box>
          <Typography fontSize={14} height={16}>
            Precious
          </Typography>
        </Stack>
      </Stack>

      {/* Size */}
      <Stack
        direction="column"
        sx={{ px: 2, py: 1.5, borderBottom: "1px solid #363636" }}
      >
        <Box>
          <Typography textTransform="uppercase">Size</Typography>
        </Box>
        <Stack direction="row">
          <FormControlLabel
            control={<Checkbox />}
            label="1x1"
            sx={{
              ".MuiFormControlLabel-label": {
                fontSize: 14,
                height: 16,
              },
            }}
            onChange={(e, val) => {
              handleSetSize(val, "1x1");
            }}
          />
        </Stack>
        <Stack direction="row">
          <FormControlLabel
            control={<Checkbox />}
            label="3x3"
            sx={{
              mt: -1.5,
              ".MuiFormControlLabel-label": {
                fontSize: 14,
                height: 16,
              },
            }}
            onChange={(e, val) => {
              handleSetSize(val, "3x3");
            }}
          />
        </Stack>
        <Stack direction="row">
          <FormControlLabel
            control={<Checkbox />}
            label="6x6"
            sx={{
              mt: -1.5,
              ".MuiFormControlLabel-label": {
                fontSize: 14,
                height: 16,
              },
            }}
            onChange={(e, val) => {
              handleSetSize(val, "6x6");
            }}
          />
        </Stack>

        <Stack direction="row">
          <FormControlLabel
            control={<Checkbox />}
            label="12x12"
            sx={{
              mt: -1.5,
              ".MuiFormControlLabel-label": {
                fontSize: 14,
                height: 16,
              },
            }}
            onChange={(e, val) => {
              handleSetSize(val, "12x12");
            }}
          />
        </Stack>
        <Stack direction="row">
          <FormControlLabel
            control={<Checkbox />}
            label="24x24"
            sx={{
              mt: -1.5,
              ".MuiFormControlLabel-label": {
                fontSize: 14,
                height: 16,
              },
            }}
            onChange={(e, val) => {
              handleSetSize(val, "24x24");
            }}
          />
        </Stack>
      </Stack>

      {/* Position */}
      <Stack
        direction="column"
        sx={{ px: 2, py: 1.5, borderBottom: "1px solid #363636" }}
      >
        <Box>
          <Typography textTransform="uppercase" mb={1}>
            Position
          </Typography>

          <Grid container>
            <Grid item xs={4}>
              <Typography fontSize={14}>X</Typography>
              <Typography fontSize={14} mt={1} color="#c2c2c2">
                {params.get("lng")
                  ? calculateX(parseInt(params.get("lng") as string))
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography fontSize={14}>Y</Typography>
              <Typography fontSize={14} mt={1} color="#c2c2c2">
                {params.get("lat")
                  ? calculateY(parseInt(params.get("lat") as string))
                  : "-"}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography fontSize={14}>Zoom</Typography>
              <Typography fontSize={14} mt={1} color="#c2c2c2">
                {params.get("zoom") ?? "-"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Stack>

      {/* Floor Price */}
      <Stack
        direction="column"
        sx={{ px: 2, py: 1.5, borderBottom: "1px solid #363636" }}
      >
        <Box>
          <Typography textTransform="uppercase" mb={1}>
            Floor Price
          </Typography>

          <Stack direction="row" alignItems="center">
            <Box
              component="img"
              src={EthTokenWEBP}
              width={25}
              height={25}
              sx={{ objectFit: "contain" }}
              mr={1}
            />
            <Typography fontSize={14} height={16}>
              0.09 ETH
            </Typography>
          </Stack>
        </Box>
      </Stack>

      {/* Contact */}
      <Stack direction="column" sx={{ px: 2, py: 1.5 }}>
        <Box>
          <Typography textTransform="uppercase" mb={1}>
            Contact
          </Typography>

          <Stack direction="row" alignItems="center" mb={1}>
            <EmailOutlinedIcon
              sx={{ mr: 1, color: (theme) => theme.palette.primary.main }}
            />
            <Typography fontSize={14} height={18}>
              community@africarare.io
            </Typography>
          </Stack>

          <Stack>
            <Typography fontSize={14}>Copyright 2022 Africare.</Typography>
            <Typography fontSize={14}>All rights reserved</Typography>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default DashboardMapSidebar;
