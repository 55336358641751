import { Balance as BalanceIcon } from "@mui/icons-material";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import TableHeader from "components/Table/components/TableHeader";

interface ILoadBalancingHeader {
  levelName: string | undefined;
}

const LoadBalancingHeader: FC<ILoadBalancingHeader> = ({ levelName }) => {
  return (
    <>
      <TableHeader
        icon={BalanceIcon}
        label={
          <FormattedMessage
            id="LEVEL.LOAD_BALANCING.DETAILS.TABLE.TITLE"
            values={{ levelName }}
          />
        }
      ></TableHeader>
    </>
  );
};

export default LoadBalancingHeader;
