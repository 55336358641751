import { Paper } from "@mui/material";
import React from "react";

import CustomersHeader from "./CustomersHeader/CustomersHeader";
import CustomersTable from "./CustomersTable";

const CustomersUsersPage = () => {
  return (
    <>
      <Paper>
        <CustomersHeader />
        <CustomersTable />
      </Paper>
    </>
  );
};

export default CustomersUsersPage;
