import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent, Grid } from "@mui/material";
import { MutationStatus } from "@tanstack/react-query";
import { Formik, Form as FormikForm } from "formik";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

import FormikNumberField from "components/forms/FormikNumberField";
import FormikTextField from "components/forms/FormikTextField";

export type RoomFormValues = {
  hash: string;
  name: string;
  capacity: number;
};

interface IRoomForm {
  handleSubmit: (values: RoomFormValues) => void;
  handleClose: () => void;
  initialValues?: RoomFormValues;
  submitStatus?: MutationStatus;
}

const RoomForm: FC<IRoomForm> = ({
  handleSubmit,
  handleClose,
  initialValues = {
    hash: "",
    name: "",
    capacity: 15,
  },
  submitStatus,
}) => {
  const schema = yup.object({
    hash: yup.string().required(),
    name: yup.string().required(),
    capacity: yup.number().required(),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      <FormikForm>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormikTextField
                name="hash"
                label={<FormattedMessage id="GLOBAL.HASH" />}
              />
            </Grid>
            <Grid item xs={6}>
              <FormikTextField
                name="name"
                label={<FormattedMessage id="GLOBAL.NAME" />}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikNumberField
                name="capacity"
                label={<FormattedMessage id="GLOBAL.CAPACITY" />}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            // data-testid="new-edit-port-close-button"
            onClick={handleClose}
          >
            <FormattedMessage id="GLOBAL.CLOSE" />
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={submitStatus === "loading"}
          >
            <FormattedMessage id="GLOBAL.SAVE" />
          </LoadingButton>
        </DialogActions>
      </FormikForm>
    </Formik>
  );
};

export default RoomForm;
