import { Add as AddIcon } from "@mui/icons-material";
import { Dialog, DialogTitle, Divider, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { FormattedMessage } from "react-intl";

import { levelsKeys } from "api/levels/queries";
import { addRoom as addRoomAPI } from "api/rooms";
import { roomsKeys } from "api/rooms/queries";
import { NewRoomPayloadType } from "api/rooms/types";

import RoomForm, { RoomFormValues } from "./RoomForm";

interface NewRoomModalProps {
  isOpen: boolean;
  handleClose: () => void;
  levelId: string;
}

const NewRoomModal = ({ isOpen, handleClose, levelId }: NewRoomModalProps) => {
  const queryClient = useQueryClient();

  const { mutateAsync: addRoom, status } = useMutation(
    async (data: NewRoomPayloadType) => {
      const res = await addRoomAPI(data);
      await queryClient.invalidateQueries(roomsKeys.list());
      await queryClient.invalidateQueries(levelsKeys.details(data.level));
      return res.data;
    }
  );

  const handleSubmit = async (values: RoomFormValues) => {
    await addRoom(
      { ...values, level: levelId },
      {
        onSuccess: () => handleClose(),
      }
    );
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <AddIcon sx={{ mr: 1 }} />
          <FormattedMessage id="ROOM.FORM.NEW_ROOM.HEADER" />
        </Stack>
      </DialogTitle>
      <Divider />

      <RoomForm
        submitStatus={status}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
      />
    </Dialog>
  );
};

export default NewRoomModal;
