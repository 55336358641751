import { Box, Grid } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useRef, useState } from "react";

import { levelsKeys } from "api/levels/queries";
import { LevelsType } from "api/levels/types";
import { roomsKeys } from "api/rooms/queries";
import { postVisit, putRoomActivity } from "api/visit";
// import { delay } from "utils/delay";
import { generateId } from "utils/generateId";

import LoadBalancingForm, {
  LoadBalancingFormValues,
} from "./LoadBalancingForm";

interface ILoadBalancing {
  level: LevelsType | undefined;
}

let visitUsersInterval: NodeJS.Timer | undefined;
const updateAcitivityIntervals: NodeJS.Timer[] = [];

const LoadBalancing: FC<ILoadBalancing> = ({ level }) => {
  const [running, setRunning] = useState<boolean>(false);
  const logWindow = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();

  const updateRoomActivity = async (
    room: string,
    hash: string,
    reloadRooms: boolean,
    enableLogs: boolean
  ) => {
    try {
      if (enableLogs) {
        handleAddLog("info", `User ${hash} updated room activity`);
      }

      const res = await putRoomActivity({
        room,
        uid: hash,
      });

      if (reloadRooms) {
        await queryClient.invalidateQueries(roomsKeys.list());
      }

      const { message } = res.data;

      if (enableLogs) {
        handleAddLog("success", `${message}`);
      }
    } catch (err) {
      if (enableLogs) {
        handleAddLog("error", `Error: ${JSON.stringify(err)}`);
      }
    }
  };

  const visitRoom = async (
    updateStatus: boolean,
    updateStatusInterval: number,
    reloadRooms: boolean,
    enableLogs: boolean
  ) => {
    try {
      const hash = `test-${generateId(6)}`;
      if (enableLogs) {
        handleAddLog("info", `User ${hash} made call to join room`);
      }

      const res = await postVisit({
        land: level?.experience.land.slug,
        experience: level?.experience.slug,
        level: level?.slug,
      });

      if (reloadRooms) {
        await queryClient.invalidateQueries(roomsKeys.list());
      }
      await queryClient.invalidateQueries(
        levelsKeys.occupancy(level?._id ?? ""),
        {
          fetchStatus: "idle",
        }
      );

      const { room, link } = res.data;
      if (enableLogs) {
        handleAddLog("success", `Redirected to ${link}`);
      }

      if (room) {
        updateRoomActivity(room, hash, reloadRooms, enableLogs);
        if (updateStatus) {
          updateAcitivityIntervals.push(
            setInterval(() => {
              updateRoomActivity(room, hash, reloadRooms, enableLogs);
            }, updateStatusInterval)
          );
        }
      }
    } catch (err) {
      if (enableLogs) {
        handleAddLog("error", `Error: ${JSON.stringify(err)}`);
      }
    }
  };

  const handleSubmit = async (values: LoadBalancingFormValues) => {
    setRunning(true);

    let addedUsers = 0;
    visitUsersInterval = setInterval(() => {
      let addMore = Math.min(
        values.usersPerInterval,
        values.numberOfUsers - addedUsers
      );
      addedUsers += addMore;

      for (let i = 0; i < addMore; i += 1) {
        visitRoom(
          values.updateStatus,
          values.updateStatusInterval,
          values.reloadRooms,
          values.enableLogs
        );
      }

      if (addedUsers >= values.numberOfUsers) {
        clearInterval(visitUsersInterval);
      }
    }, values.interval);
  };

  const handleStop = async () => {
    updateAcitivityIntervals.forEach((updateActivityInterval) =>
      clearInterval(updateActivityInterval)
    );
    clearInterval(visitUsersInterval);
    setRunning(false);
  };

  const handleAddLog = (
    type: "info" | "success" | "error",
    message: string
  ) => {
    if (logWindow.current) {
      let color = "#4f61fb";
      if (type === "success") {
        color = "#81c784";
      } else if (type === "error") {
        color = "#e57373";
      }

      logWindow.current.innerHTML += `<p style="margin: 0px;font-size: 12px;"><span style="color: ${color};">[${type.toUpperCase()}]</span> ${message}</p>`;
      logWindow.current.scrollTop = logWindow.current.scrollHeight;
    }
  };

  return (
    <Grid container>
      <Grid item xs={4}>
        <Box sx={{ py: 2 }}>
          <LoadBalancingForm
            handleSubmit={handleSubmit}
            handleStop={handleStop}
            running={running}
          />
        </Box>
      </Grid>
      <Grid item xs={8}>
        <Box
          sx={{
            px: 3,
            py: 1,
            backgroundColor: "#424242",
            height: "450px",
            overflow: "scroll",
          }}
          ref={logWindow}
        >
          {/* PLACE FOR LOGS */}
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoadBalancing;
