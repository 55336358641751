import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { MutationStatus } from "@tanstack/react-query";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import slugify from "react-slugify";
import * as yup from "yup";

import FormikTextField from "components/forms/FormikTextField";

export type ExperienceFormValues = {
  name: string;
  slug: string;
  color: string;
};

interface IExperienceForm {
  handleSubmit: (values: ExperienceFormValues) => void;
  handleClose: () => void;
  initialValues?: ExperienceFormValues;
  submitStatus?: MutationStatus;
}

const ExperienceForm: FC<IExperienceForm> = ({
  handleSubmit,
  handleClose,
  initialValues = {
    name: "",
    slug: "",
    color: "",
  },
  submitStatus,
}) => {
  const schema = yup.object({
    name: yup.string().required(),
    slug: yup.string().required(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const { setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <DialogContent>
          <FormikTextField
            name="name"
            label={<FormattedMessage id="GLOBAL.NAME" />}
            onChange={(e) => {
              setFieldValue("name", e.target.value);
              setFieldValue("slug", slugify(e.target.value));
            }}
          />
          <FormikTextField
            name="slug"
            label={<FormattedMessage id="GLOBAL.SLUG" />}
            disabled
          />
          <FormikTextField
            name="color"
            label={<FormattedMessage id="GLOBAL.COLOR" />}
          />
        </DialogContent>

        <DialogActions>
          <Button
            // data-testid="new-edit-port-close-button"
            onClick={handleClose}
          >
            <FormattedMessage id="GLOBAL.CLOSE" />
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={submitStatus === "loading"}
          >
            <FormattedMessage id="GLOBAL.SAVE" />
          </LoadingButton>
        </DialogActions>
      </FormikForm>
    </FormikProvider>
  );
};

export default ExperienceForm;
