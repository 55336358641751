import request from "api/request";
import { APICall } from "api/utils";

export const postVisit = (visit: any, token?: string): APICall<any> =>
  request({
    data: visit,
    url: `/visit`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const putRoomActivity = (visit: any): APICall<any> =>
  request({
    data: visit,
    url: `/visit/update-room-activity`,
    method: "PUT",
  });
