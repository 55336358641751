import { Paper } from "@mui/material";
import React from "react";

import ReferralsAnalyticsChart from "./ReferralsAnalyticsChart/ReferralsAnalyticsChart";
import ReferralsAnalyticsHeader from "./ReferralsAnalyticsHeader/ReferralsAnalyticsHeader";
import ReferralsHeader from "./ReferralsHeader/ReferralsHeader";
import ReferralsTable from "./ReferralsTable";

const ReferralsUsersPage = () => {
  return (
    <>
      <Paper>
        <ReferralsAnalyticsHeader />
        <ReferralsAnalyticsChart />
      </Paper>

      <Paper sx={{ mt: 2 }}>
        <ReferralsHeader />
        <ReferralsTable />
      </Paper>
    </>
  );
};

export default ReferralsUsersPage;
