import { Box, Button, Grid } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

interface IFeatureCameraChest {
  handleOpenDelete: () => void;
  schema: any[];
}

const FeatureCameraChest: FC<IFeatureCameraChest> = ({ handleOpenDelete }) => {
  return (
    <>
      <Box sx={{ padding: "15px" }}></Box>
      {/* FOOTER */}
      <Grid container spacing={2} sx={{ mt: "auto", p: { xs: 2, sm: 3 } }}>
        <Grid item xs={3}>
          <Button
            sx={{ width: "100%" }}
            color="error"
            variant="contained"
            onClick={handleOpenDelete}
            size="medium"
          >
            <FormattedMessage id="GLOBAL.DELETE" />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default FeatureCameraChest;
