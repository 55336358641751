import {
  DeleteOutlined as DeleteIcon,
  EditOutlined as EditIcon,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { CellProps } from "react-table";

import { RewardItemType } from "api/rewards/types";
import IconButtonWithTooltip from "components/Icons/IconButtonWithTooltip";

interface Props<T extends {}> extends CellProps<T> {
  handleOpenEdit: (ctx?: T) => void;
  handleOpenDelete: (ctx?: T) => void;
}

const ActionsFormatter = ({
  row,
  handleOpenDelete,
  handleOpenEdit,
}: Props<RewardItemType>) => {
  return (
    <Box>
      <IconButtonWithTooltip
        tooltipText={<FormattedMessage id="GLOBAL.EDIT" />}
        // data-testid="edit-port-button"
        color="primary"
        onClick={() => handleOpenEdit(row.original)}
      >
        <EditIcon />
      </IconButtonWithTooltip>
      <IconButtonWithTooltip
        tooltipText={<FormattedMessage id="GLOBAL.DELETE" />}
        // data-testid="delete-port-button"
        color="primary"
        onClick={() => handleOpenDelete(row.original)}
      >
        <DeleteIcon />
      </IconButtonWithTooltip>
    </Box>
  );
};

export default ActionsFormatter;
