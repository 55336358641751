import { AutoAwesome as AutoAwesomeIcon } from "@mui/icons-material";
import { Box, BoxProps, Typography } from "@mui/material";
import { FC } from "react";

import { FeaturesTypeType } from "api/features/types";

export const featureBackgroundColor = {
  access: "#3481d6",
  chatlog: "#ff0051",
  "experience-avatars": "#F0A631",
  "admin-avatars": "#Fed123",
  leaderboard: "#D2612B",
  portal: "#a642cb",
  "teleportation-panel": "#777",
  "ai-course": "#bFbb2b",
  "restricted-pen-drawing": "#000",
  "info-pin": "#4f61fb",
  participation: "#6185a3",
  presentation: "#2c892c",
  "link-button": "#008080",
  "camera-chest": "#20F070",
  "camera-presentation": "#3AB2B1",
  exhibit: "#e5a3ea",
  "treasure-hunt": "#e1430a",
  "clear-drawing": "#a33d12",
  redirect: "#12d213",
  "voice-installation": "#15adff",
  quiz: "#f315ab",
  collectables: "#ff7300",
  "exhibit-images": "#e5a3ea",
  help: "#aa1133",
  abada: "#FFC0CB",
  tutorial: "#F1f1f1",
  queue: "#301934",
  game: "#FFEE11",
};

interface IFeatureFormatter extends BoxProps {
  type: FeaturesTypeType;
}

export const FeatureFormatter: FC<IFeatureFormatter> = ({ type, ...rest }) => {
  return (
    <Box
      {...rest}
      sx={{
        p: 0.5,
        px: 1.5,
        borderRadius: 3,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        background: featureBackgroundColor[type],
        ...(rest?.sx ?? {}),
      }}
    >
      <Typography
        variant="body2"
        fontSize="14px"
        mt={0.2}
        mr={0.5}
        fontWeight="medium"
      >
        {type}
      </Typography>
      <AutoAwesomeIcon sx={{ fontSize: "16px" }} />
    </Box>
  );
};
