import { Paper } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";

import { getSingleLevel } from "api/levels";
import { levelsKeys } from "api/levels/queries";
import { hasPermissions } from "components/stores/UserStore";

import LevelDetailsHeader from "./LevelHeader/LevelHeader";
import LevelOccupancy from "./LevelOccupancy/LevelOccupancy";
import LoadBalancing from "./LoadBalancing/LoadBalancing";
import LoadBalancingHeader from "./LoadBalancing/LoadBalancingHeader";
import RoomsHeader from "./RoomsHeader/RoomsHeader";
import RoomsTable from "./RoomsTable";

const LevelPage = () => {
  const { levelId } = useParams<{ levelId: string }>();

  const { data: level } = useQuery(levelsKeys.details(levelId), async () => {
    const { data: res } = await getSingleLevel(levelId);
    return res.data;
  });

  return (
    <>
      <Paper>
        <LevelDetailsHeader levelName={level?.name} />
        <LevelOccupancy level={level} />
      </Paper>

      <Paper sx={{ mt: 2 }}>
        <RoomsHeader levelId={levelId} />
        <RoomsTable level={level} />
      </Paper>

      {hasPermissions("test:levels") && (
        <Paper sx={{ mt: 2 }}>
          <LoadBalancingHeader levelName={level?.name} />
          <LoadBalancing level={level} />
        </Paper>
      )}
    </>
  );
};

export default LevelPage;
