import { Box } from "@mui/material";
import { useEventHandlers } from "@react-leaflet/core";
import { CRS, LatLngBounds } from "leaflet";
import { useCallback, useMemo, useState } from "react";
import {
  MapContainer,
  Rectangle,
  TileLayer,
  useMap,
  useMapEvent,
} from "react-leaflet";

const defaultZoom = 620;
const decreasedBy = defaultZoom / 130;

function MinimapBounds({ parentMap, zoom }: any) {
  const minimap = useMap();

  // Clicking a point on the minimap sets the parent's map center
  const onClick = useCallback(
    (e) => {
      parentMap.setView(
        {
          lat: e.latlng.lat * decreasedBy,
          lng: e.latlng.lng * decreasedBy,
        },
        parentMap.getZoom()
      );
    },
    [parentMap]
  );
  useMapEvent("click", onClick);

  // Keep track of bounds in state to trigger renders
  const [bounds, setBounds] = useState(
    new LatLngBounds(
      {
        lat: parentMap.getBounds().getNorth() / decreasedBy,
        lng: parentMap.getBounds().getEast() / decreasedBy,
      },
      {
        lat: parentMap.getBounds().getSouth() / decreasedBy,
        lng: parentMap.getBounds().getWest() / decreasedBy,
      }
    )
  );

  const onChange = useCallback(() => {
    setBounds(
      new LatLngBounds(
        {
          lat: parentMap.getBounds().getNorth() / decreasedBy,
          lng: parentMap.getBounds().getEast() / decreasedBy,
        },
        {
          lat: parentMap.getBounds().getSouth() / decreasedBy,
          lng: parentMap.getBounds().getWest() / decreasedBy,
        }
      )
    );

    // Update the minimap's view to match the parent map's center and zoom
    minimap.setView(
      {
        lat: parentMap.getCenter().lat / decreasedBy,
        lng: parentMap.getCenter().lng / decreasedBy,
      },
      zoom
    );
  }, [minimap, parentMap, zoom]);

  // Listen to events on the parent map
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlers = useMemo(() => ({ move: onChange, zoom: onChange }), []);
  //@ts-expect-error
  useEventHandlers({ instance: parentMap }, handlers);

  return (
    <Rectangle
      bounds={bounds}
      pathOptions={{
        weight: 1,

        color: "#e5951a",
      }}
    />
  );
}

export default function DashboardMapMinimap({ zoom, map: parentMap }: any) {
  // const parentMap = useMap();

  const mapZoom = zoom || 0;

  // Memoize the minimap so it's not affected by position changes

  const tileSize = 620 / decreasedBy;
  const outsideBounds = tileSize * 0.3;

  const minimap = useMemo(
    () => (
      <MapContainer
        style={{
          height: tileSize * 1.7,
          width: 300,
          backgroundColor: "#181818",
        }}
        center={{
          lat: parentMap.getCenter().lat / decreasedBy,
          lng: parentMap.getCenter().lng / decreasedBy,
        }}
        maxBounds={[
          [0 + outsideBounds, tileSize + outsideBounds],
          [-tileSize - outsideBounds, 0 - outsideBounds],
        ]}
        crs={CRS.Simple}
        zoom={mapZoom}
        dragging={false}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        attributionControl={false}
        zoomControl={false}
      >
        <TileLayer
          bounds={[
            [0, tileSize],
            [-tileSize, 0],
          ]}
          url={`${process.env.REACT_APP_API_URL}/uploads/map/minimap_files/{z}/{x}_{y}.jpeg`}
          minNativeZoom={0}
          noWrap
          tileSize={tileSize}
        />
        <MinimapBounds parentMap={parentMap} zoom={mapZoom} />
      </MapContainer>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ borderRadius: 0 }}>{minimap}</Box>

      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          border: "1px solid #364050",
          width: 195,
          height: 195,
          transform: "translate(-50%,-50%)",
          borderRadius: "50%",
          pointerEvents: "none",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          border: "1px solid #364050",
          width: 185,
          height: 185,
          transform: "translate(-50%,-50%)",
          borderRadius: "50%",
          pointerEvents: "none",
        }}
      />
    </Box>
  );
}
