const usersKeys = {
  all: ["users"] as const,
  list: () => [...usersKeys.all, "list"],
  customers: () => [...usersKeys.all, "customers"],
  statistics: () => [...usersKeys.all, "statistics"],
  history: (id: string) => [...usersKeys.all, id, "history"],
  friendCount: () => [...usersKeys.all, "friendCount"],
  friendCountFiltered: (filters: Record<string, unknown>) => [
    ...usersKeys.friendCount(),
    filters,
  ],
};

export { usersKeys };
