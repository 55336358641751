import { Box, Link, Stack, Theme, useMediaQuery } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

import Logo from "assets/logos/logo-white.svg";
import { WithChildren } from "utils/types/index.js";

export function AuthPageWrapper({ children }: WithChildren) {
  const largeScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up("lg")
  );

  return (
    <Stack
      direction={largeScreen ? "row" : "column"}
      width="100vw"
      height="100vh"
    >
      <Stack
        sx={{
          flex: "0 0 auto",
          backgroundSize: "cover",
          backgroundImage: "url('/media/login-info-bg.png')",
          backgroundRepeat: "no-repeat",
          width: "100%",
          p: 5,
          maxWidth: { sm: "auto", lg: "450px", xl: "600px" },
        }}
      >
        <Stack
          direction="column"
          sx={{ height: "100%", width: "100%" }}
          justifyContent="space-between"
        >
          <Box></Box>
          <Link component={RouterLink} to="/" sx={{ textAlign: "center" }}>
            <img alt="Logo" style={{ height: "80px" }} src={Logo} />
          </Link>

          <Box
            sx={{
              display: largeScreen ? "flex" : "none",
              mt: 5,
            }}
            justifyContent="space-between"
          >
            <Box sx={{ color: "white", fontWeight: "medium", opacity: 0.7 }}>
              &copy; {new Date().getFullYear()}{" "}
              <FormattedMessage id="BRAND.NAME" />
            </Box>
          </Box>
        </Stack>
      </Stack>

      <Stack
        overflow="auto"
        height="100%"
        width="100%"
        sx={{ background: (t) => t.palette.background.default, color: "#fff" }}
      >
        <Box p={4} m="auto">
          {children}
        </Box>
      </Stack>
    </Stack>
  );
}
