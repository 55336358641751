import { Box, Button, Grid } from "@mui/material";
import { MutationStatus } from "@tanstack/react-query";
import { Form, FormikProvider, useFormik } from "formik";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

import FormikAutocomplete from "components/forms/FormikAutocomplete";
import FormikCheckbox from "components/forms/FormikCheckbox";
import FormikTextField from "components/forms/FormikTextField";

export type FeatureRedirectFormValues = {
  information: {
    link: string | null;
    active: boolean | null;
  };
};

interface IFeatureRedirectForm {
  handleSubmit: (values: FeatureRedirectFormValues) => void;
  initialValues?: FeatureRedirectFormValues;
  submitStatus?: MutationStatus;
  handleOpenDelete: () => void;
}

const FeatureRedirectForm: FC<IFeatureRedirectForm> = ({
  initialValues = {
    information: {
      link: "",
      active: false,
    },
  },
  handleSubmit,
  submitStatus,
  handleOpenDelete,
}) => {
  const schema = yup.object({
    information: yup.object().shape({
      link: yup.string().required(),
      // active: yup.boolean(),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  console.log("values", formik.values);

  return (
    <>
      <FormikProvider value={formik}>
        <Form
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <Box sx={{ p: { xs: 2, sm: 3 } }}>
            <Grid container spacing={1} sx={{ mb: 1.5 }}>
              <Grid item xs={12} lg={12}>
                <FormikAutocomplete
                  label="Type"
                  name={`information.type`}
                  options={[
                    { value: "admin", label: "Admin" },
                    { value: "quiz", label: "Quiz" },
                    { value: "video", label: "Video" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <FormikTextField label="Link" name={`information.link`} />
              </Grid>
              <Grid item xs={12} lg={12}>
                <FormikCheckbox label="Active" name={`information.active`} />
              </Grid>
            </Grid>
          </Box>

          {/* FOOTER */}
          <Grid container spacing={2} sx={{ mt: "auto", p: { xs: 2, sm: 3 } }}>
            <Grid item xs={3}>
              <Button
                sx={{ width: "100%" }}
                variant="contained"
                size="medium"
                type="submit"
              >
                <FormattedMessage id="GLOBAL.SAVE" />
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                sx={{ width: "100%" }}
                color="error"
                variant="contained"
                onClick={handleOpenDelete}
                size="medium"
              >
                <FormattedMessage id="GLOBAL.DELETE" />
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
};

export default FeatureRedirectForm;
