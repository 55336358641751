import {
  Add as AddIcon,
  DeleteOutline as DeleteIcon,
} from "@mui/icons-material";
import { Box, Button, Divider, Grid, IconButton, Stack } from "@mui/material";
import { MutationStatus, useQuery } from "@tanstack/react-query";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

import { getRelationalFeatures } from "api/features";
import { featuresKeys } from "api/features/queries";
import FormikAutocomplete from "components/forms/FormikAutocomplete";
import FormikCheckbox from "components/forms/FormikCheckbox";
import FormikTextField from "components/forms/FormikTextField";
import Table, { TableColumn } from "components/Table/EditTable";

export type FeatureQuizFormValues = {
  information: {
    title: string;
    description: string;
    leaderboards: string[];
  };
  schema: {
    question: string | null;
    answers: {
      title: string | null;
      correct: boolean;
    }[];
  }[];
  users: {}[];
};

interface IFeatureQuizForm {
  featureId: string;
  handleSubmit: (values: FeatureQuizFormValues) => void;
  initialValues?: FeatureQuizFormValues;
  submitStatus?: MutationStatus;
  handleOpenDelete: () => void;
}

const FeatureQuizForm: FC<IFeatureQuizForm> = ({
  featureId,
  handleSubmit,
  handleOpenDelete,
  initialValues = {
    information: {
      title: "",
      description: "",
      leaderboards: [],
      dependsOn: null,
    },
    schema: [
      {
        question: "",
        answers: [],
      },
    ],
    users: [],
  },
  submitStatus,
}) => {
  const { data: relationalFeatures } = useQuery(
    featuresKeys.relations(featureId),
    async () => {
      const { data: res } = await getRelationalFeatures(featureId);
      return res.data;
    },
    {
      enabled: Boolean(featureId),
    }
  );

  const leaderboardFeatures = useMemo(() => {
    if (relationalFeatures?.length) {
      return relationalFeatures
        .filter((filter) => filter.type === "leaderboard")
        .map((feature) => ({
          value: feature._id,
          label: `Leaderboard - ${feature.parent}`,
        }));
    }

    return [];
  }, [relationalFeatures]);

  const schema = yup.object({
    schema: yup.array().of(
      yup.object().shape({
        question: yup.string().nullable().required(),
        answers: yup.array().of(
          yup.object().shape({
            title: yup.string().nullable().required(),
            correct: yup.boolean().nullable().required(),
          })
        ),
      })
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const { values, setFieldValue } = formik;
  console.log(values.users);

  const usersColumns: TableColumn<any>[] = [
    {
      id: "user",
      Header: "User",
      columns: [
        {
          accessor: "_id",
          Header: "Id",
        },
        {
          accessor: "score",
          Header: "Score",
        },
      ],
    },
  ];

  // Users
  const addUsers = async () => {
    const newUsers = [
      ...values.users,
      {
        email: "",
        position: null,
      },
    ];
    setFieldValue("users", newUsers);
  };

  const deleteUsers = async (rowIndex: number) => {
    const newUsers = [...values.users].filter(
      (row, index) => index !== rowIndex
    );
    setFieldValue("users", newUsers);
  };

  const updateUsers = async (
    rowIndex: number,
    columnId: string,
    value: any
  ) => {
    const newUsers = [...values.users].map((row, index) => {
      if (index === rowIndex) {
        return {
          ...row,
          [columnId]: value,
        };
      }
      return row;
    });

    setFieldValue("users", newUsers);
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <Box sx={{ p: { xs: 2, sm: 3 } }}>
            {/* Relations */}
            <Divider sx={{ fontSize: "14px" }}>Relations</Divider>

            <FieldArray
              name="information.leaderboards"
              render={(arrayHelpers) => {
                return (
                  <Box sx={{ mb: 1 }}>
                    {values.information?.leaderboards?.map((_id, index) => {
                      return (
                        <FormikAutocomplete
                          name={`information.leaderboards.${index}`}
                          label="Leaderboard"
                          options={leaderboardFeatures}
                        />
                      );
                    })}
                    <Stack
                      sx={{ height: "50px" }}
                      direction="row"
                      alignItems="center"
                      justifyContent="start"
                    >
                      <Button
                        variant="contained"
                        onClick={() => arrayHelpers.push("")}
                      >
                        <AddIcon sx={{ mr: 0.5 }} />
                        <FormattedMessage id="GLOBAL.ADD" />
                      </Button>
                    </Stack>
                  </Box>
                );
              }}
            ></FieldArray>

            {/* Information */}
            <Divider sx={{ fontSize: "14px", mt: 3 }}>Information</Divider>

            <FormikTextField name="information.logo" label="Logo" />
            <FormikTextField name="information.title" label="Title" />
            <FormikTextField
              name="information.description"
              label="Description"
            />
            <FormikAutocomplete
              name="information.dependsOn"
              label="Depends On"
              options={[
                {
                  value: null,
                  label: "None",
                },
                {
                  value: "video",
                  label: "Video",
                },
              ]}
            />

            {/* Users */}
            <Divider sx={{ fontSize: "14px", mt: 3 }}>Users</Divider>

            <Table
              data={values.users}
              columns={usersColumns}
              addData={addUsers}
              updateData={updateUsers}
              deleteData={deleteUsers}
              manualPagination
            />

            {/* Questions */}
            <Divider sx={{ fontSize: "14px", mt: 3 }}>Questions</Divider>
            <FieldArray
              name="schema"
              render={(arrayHelpers) => {
                return (
                  <Box sx={{ mb: 1 }}>
                    {values.schema.map((user, index) => {
                      return (
                        <>
                          <Grid
                            container
                            spacing={1}
                            sx={{ mb: 1.5 }}
                            key={`question-${index}`}
                          >
                            <Grid item xs={12} lg={11}>
                              <FormikTextField
                                label={`Question ${index + 1}`}
                                name={`schema.${index}.question`}
                              />
                            </Grid>
                            <Grid item xs={12} lg={1}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ height: "100%", maxHeight: "50px" }}
                              >
                                <IconButton
                                  color="primary"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Stack>
                            </Grid>
                          </Grid>
                          <FieldArray
                            name={`schema.${index}.answers`}
                            render={(arrayHelpers2) => {
                              return (
                                <Box sx={{ mb: 1 }}>
                                  {values.schema[index] &&
                                    values.schema[index].answers &&
                                    values.schema[index].answers.map(
                                      (ans, index2) => {
                                        return (
                                          <Grid
                                            container
                                            spacing={1}
                                            sx={{ mb: 1.5 }}
                                            key={`answers-${index}-${index2}`}
                                          >
                                            <Grid item xs={12} lg={9}>
                                              <FormikTextField
                                                label={`Answer ${index2 + 1}`}
                                                name={`schema.${index}.answers.${index2}.title`}
                                              />
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              lg={2}
                                              sx={{ display: "flex" }}
                                            >
                                              <FormikCheckbox
                                                label={"Correct"}
                                                sx={{ ml: 1 }}
                                                name={`schema.${index}.answers.${index2}.correct`}
                                              />
                                            </Grid>
                                            <Grid item xs={12} lg={1}>
                                              <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="center"
                                                sx={{
                                                  height: "100%",
                                                  maxHeight: "50px",
                                                }}
                                              >
                                                {index2 ===
                                                values.schema[index].answers
                                                  .length -
                                                  1 ? (
                                                  <IconButton
                                                    color="primary"
                                                    onClick={() =>
                                                      arrayHelpers2.push({
                                                        title: "",
                                                        correct: false,
                                                      })
                                                    }
                                                  >
                                                    <AddIcon />
                                                  </IconButton>
                                                ) : (
                                                  <IconButton
                                                    color="primary"
                                                    onClick={() =>
                                                      arrayHelpers2.remove(
                                                        index2
                                                      )
                                                    }
                                                  >
                                                    <DeleteIcon />
                                                  </IconButton>
                                                )}
                                              </Stack>
                                            </Grid>
                                          </Grid>
                                        );
                                      }
                                    )}
                                  {/* <Stack
                                    sx={{ height: "50px" }}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="start"
                                  >
                                    <Button
                                      variant="contained"
                                      onClick={() =>
                                        arrayHelpers2.push({
                                          title: "",
                                          correct: false,
                                        })
                                      }
                                    >
                                      <AddIcon sx={{ mr: 0.5 }} />
                                      ADD ANSWER
                                    </Button>
                                  </Stack> */}
                                </Box>
                              );
                            }}
                          />
                        </>
                      );
                    })}
                    <Stack
                      sx={{ height: "50px" }}
                      direction="row"
                      alignItems="center"
                      justifyContent="start"
                    >
                      <Button
                        variant="contained"
                        onClick={() =>
                          arrayHelpers.push({
                            question: "",
                            answers: [
                              {
                                title: "",
                                correct: false,
                              },
                            ],
                          })
                        }
                      >
                        <AddIcon sx={{ mr: 0.5 }} />
                        ADD QUESTION
                      </Button>
                    </Stack>
                  </Box>
                );
              }}
            />
          </Box>

          {/* FOOTER */}
          <Grid container spacing={2} sx={{ mt: "auto", p: { xs: 2, sm: 3 } }}>
            <Grid item xs={3}>
              <Button
                sx={{ width: "100%" }}
                variant="contained"
                size="medium"
                type="submit"
              >
                <FormattedMessage id="GLOBAL.SAVE" />
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                sx={{ width: "100%" }}
                color="error"
                variant="contained"
                onClick={handleOpenDelete}
                size="medium"
              >
                <FormattedMessage id="GLOBAL.DELETE" />
              </Button>
            </Grid>

            <Grid item xs={3}>
              <input
                type="file"
                id="feature-import"
                style={{ display: "none" }}
                accept=".csv"
              />
              <Box
                component="label"
                sx={{
                  width: "100%",
                  background: (t) => t.palette.success.main,
                  p: "6px 16px",
                  borderRadius: 1,
                  minWidth: 8,
                  fontSize: "0.875rem",
                  fontWeight: "500",
                  height: "100%",
                  display: "block",
                  textTransform: "uppercase",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                color="success"
                htmlFor={"feature-import"}
              >
                <FormattedMessage id="GLOBAL.IMPORT" />
              </Box>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
};

export default FeatureQuizForm;
