import { Add as AddIcon } from "@mui/icons-material";
import { Dialog, DialogTitle, Divider, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { FormattedMessage } from "react-intl";

import { levelsKeys } from "api/levels/queries";
import { editRoom as editRoomAPI } from "api/rooms";
import { roomsKeys } from "api/rooms/queries";
import { EditRoomType, RoomsType } from "api/rooms/types";

import RoomForm, { RoomFormValues } from "./RoomForm";

interface EditRoomModalProps {
  isOpen: boolean;
  handleClose: () => void;
  levelId: string;
  room: RoomsType | undefined;
}

const EditRoomModal = ({
  isOpen,
  handleClose,
  levelId,
  room,
}: EditRoomModalProps) => {
  const queryClient = useQueryClient();

  const initialValues: RoomFormValues = {
    hash: room?.hash || "",
    name: room?.name || "",
    capacity: room?.capacity || 15,
  };

  const { mutateAsync: editRoom, status } = useMutation(
    async (data: EditRoomType) => {
      const res = await editRoomAPI(data);
      await queryClient.invalidateQueries(roomsKeys.list());
      await queryClient.invalidateQueries(levelsKeys.details(levelId));
      return res.data;
    }
  );

  const handleSubmit = async (values: RoomFormValues) => {
    await editRoom(
      { ...values, _id: room?._id ?? "" },
      {
        onSuccess: () => handleClose(),
      }
    );
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <AddIcon sx={{ mr: 1 }} />
          <FormattedMessage id="ROOM.FORM.EDIT_ROOM.HEADER" />
        </Stack>
      </DialogTitle>
      <Divider />

      <RoomForm
        submitStatus={status}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        initialValues={initialValues}
      />
    </Dialog>
  );
};

export default EditRoomModal;
