import { QueryParamsType, normalizeQueryParams } from "api/utils";

import request, { APICall } from "../request";
import {
  EditFeatureType,
  FeaturesType,
  NewFeaturePayloadType,
  RelationalFeature,
} from "./types";

export const getRelationalFeatures = (
  featureId: string
): APICall<{ data: RelationalFeature[] }> =>
  request({
    url: `/feature/relational/${featureId}`,
    method: "GET",
  });

export const addFeature = (
  feature: NewFeaturePayloadType
): APICall<FeaturesType> =>
  request({
    data: feature,
    url: "/feature",
    method: "POST",
  });

export const editFeature = (
  feature: EditFeatureType,
  queryParams?: QueryParamsType<FeaturesType>
): APICall<EditFeatureType> =>
  request({
    data: feature,
    url: `/feature/${feature._id}${normalizeQueryParams(queryParams)}`,
    method: "PUT",
  });

export const deleteFeature = (featureId: string): APICall<null> =>
  request({
    url: `/feature/${featureId}`,
    method: "DELETE",
  });

export const getSingleFeature = (
  featureId: string
): APICall<{ data: FeaturesType }> =>
  request({
    url: `/feature/${featureId}`,
    method: "GET",
  });

export const downloadFeatureCSV = (featureId: string): APICall<BlobPart> =>
  request({
    url: `/feature/csv/${featureId}`,
    method: "GET",
  });
