const mozillaKeys = {
  all: ["mozilla"] as const,
  media: () => [...mozillaKeys.all, "media"],
  mediaFiltered: (filters: Record<string, unknown>) => [
    ...mozillaKeys.media(),
    filters,
  ],
};

export { mozillaKeys };
