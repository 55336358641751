import { Paginated, QueryParamsType, normalizeQueryParams } from "api/utils";

import request, { APICall } from "../request";
import { EditRoomType, NewRoomPayloadType, RoomsType } from "./types";

export const getRooms = (
  queryParams?: QueryParamsType<RoomsType>
): APICall<Paginated<RoomsType[]>> =>
  request({
    url: `/room${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const addRoom = (room: NewRoomPayloadType): APICall<RoomsType> =>
  request({
    data: room,
    url: "/room",
    method: "POST",
  });

export const editRoom = (
  room: EditRoomType,
  queryParams?: QueryParamsType<RoomsType>
): APICall<EditRoomType> =>
  request({
    data: room,
    url: `/room/${room._id}${normalizeQueryParams(queryParams)}`,
    method: "PUT",
  });

export const deleteRoom = (roomId: string): APICall<null> =>
  request({
    url: `/room/${roomId}`,
    method: "DELETE",
  });

export const getSingleRoom = (roomId: string): APICall<{ data: RoomsType }> =>
  request({
    url: `/room/${roomId}`,
    method: "GET",
  });
