import React from "react";
import { Rectangle } from "react-leaflet";

import { MapPointType } from "api/map/types";

const MapPointRectangle = ({
  bounds,
}: {
  bounds: MapPointType["bounds"] | undefined;
}) => {
  if (!bounds) return null;

  return (
    <>
      <Rectangle bounds={bounds} weight={3} color="#fff" fillOpacity={0} />
    </>
  );
};

export default MapPointRectangle;
