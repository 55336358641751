import { Stack } from "@mui/material";
import { CRS } from "leaflet";
import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";

import "leaflet/dist/leaflet.css";
import { MapPointType } from "api/map/types";

import MapPointImage from "./MapPointImage";
import MapPointPopup from "./MapPointPopup";
import MapPointRectangle from "./MapPointRectangle";

const TILE_SIZE = 620;
const DEFAULT_PARAMETERS = {
  lat: -149,
  lng: 316,
  zoom: 4,
};

const MIN_BOUNDS_OPTIONS = {
  "24x24": [10, 10],
  "12x12": [20, 20],
  "6x6": [30, 30],
  "3x3": [40, 40],
  "1x1": [50, 50],
};

const MapPointMap = ({
  point,
  displayPopup = true,
}: {
  point: MapPointType | undefined;
  displayPopup?: boolean;
}) => {
  return (
    <Stack sx={{ height: "100%", width: "100%" }} direction="row">
      {point ? (
        <>
          {displayPopup && <MapPointPopup point={point} />}

          <MapContainer
            bounds={point.bounds}
            zoom={DEFAULT_PARAMETERS.zoom}
            crs={CRS.Simple}
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#110f0e",
              cursor: "default",
            }}
            doubleClickZoom={false}
            zoomControl={false}
            dragging={false}
            tap={false}
            scrollWheelZoom={false}
            keyboard={false}
            boundsOptions={{
              //@ts-expect-error
              padding: MIN_BOUNDS_OPTIONS[point.size],
            }}
          >
            <TileLayer
              url={`${process.env.REACT_APP_API_URL}/uploads/map/tilelayers_files/{z}/{x}_{y}.jpeg`}
              minNativeZoom={2}
              maxNativeZoom={5}
              minZoom={1}
              maxZoom={6}
              noWrap
              detectRetina
              tileSize={TILE_SIZE}
              bounds={[
                [0, TILE_SIZE],
                [-TILE_SIZE, 0],
              ]}
            />

            {point.landImage && (
              <MapPointImage image={point.landImage} bounds={point.bounds} />
            )}
            <MapPointRectangle bounds={point.bounds} />
          </MapContainer>
        </>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default MapPointMap;
