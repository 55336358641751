import { BarChart as BarChartIcon, Circle } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { getAnalytics } from "api/analytics";
import { analyticsKeys } from "api/analytics/queries";
import TableHeader from "components/Table/components/TableHeader";

interface ILandsAnalyticsHeader {
  landId: string;
}

const LandsAnalyticsHeader: FC<ILandsAnalyticsHeader> = ({ landId }) => {
  const { data } = useQuery(
    analyticsKeys.listFiltered({
      land: landId,
    }),
    async () => {
      const res = await getAnalytics({ land: landId });
      return res.data;
    }
  );

  return (
    <>
      <TableHeader
        icon={BarChartIcon}
        label={<FormattedMessage id="LANDS.ANALYTICS.TABLE.TITLE" />}
      >
        <Chip
          icon={
            <Circle
              color="success"
              // sx={{ animation: `${blink} 1s linear infinite` }}
            />
          }
          sx={{
            p: 0,
            height: "29px",
            color: "#FFF",
            ".MuiChip-label": {
              paddingTop: "3px",
            },
          }}
          label={`${data?.meta.liveUsers ?? 0} Live User${
            data?.meta.liveUsers !== 1 ? "s" : ""
          }`}
          color="success"
        ></Chip>
      </TableHeader>
    </>
  );
};

export default LandsAnalyticsHeader;
