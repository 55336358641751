import { Box } from "@mui/material";
import React from "react";

import { MapPointType } from "api/map/types";
import MapPointMap from "containers/map/details/MapPointMap/MapPointMap";

const MapPointPosition = ({ point }: { point: MapPointType }) => {
  return (
    <Box sx={{ width: 120, height: 120, position: "relative" }}>
      <MapPointMap point={point} displayPopup={false} />
    </Box>
  );
};

export default MapPointPosition;
