import { Paper } from "@mui/material";

import FriendsAnalyticsChart from "./FriendsAnalyticsChart/FriendsAnalyticsChart";
import FriendsAnalyticsChartHeader from "./FriendsAnalyticsChartHeader/FriendsAnalyticsChartHeader";
import FriendsAnalyticsHeader from "./FriendsAnalyticsHeader/FriendsAnalyticsHeader";
import FriendsAnalyticsTable from "./FriendsAnalyticsTable";

const FriendsAnalyticsPage = () => {
  return (
    <>
      <Paper>
        <FriendsAnalyticsChartHeader />
        <FriendsAnalyticsChart />
      </Paper>

      <Paper sx={{ mt: 2 }}>
        <FriendsAnalyticsHeader />
        <FriendsAnalyticsTable />
      </Paper>
    </>
  );
};

export default FriendsAnalyticsPage;
