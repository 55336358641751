import { LatLng } from "leaflet";
import React, { useEffect, useState } from "react";
import { Rectangle, useMapEvents } from "react-leaflet";
import { useHistory, useLocation } from "react-router-dom";

import { getMapPointCoordinates, getSingleMapPoint } from "api/map";

const CLICK_DURATION = 300;
let clickTimeout: NodeJS.Timeout | undefined;

const DashboardMapClick = () => {
  const history = useHistory();
  const location = useLocation();
  const [bounds, setBounds] = useState();

  const params = new URLSearchParams(history.location.search);
  const pointId = params.get("land");

  useEffect(() => {
    if (pointId) {
      (async function () {
        const {
          data: { data: mapPoint },
        } = await getSingleMapPoint(pointId);

        const { bounds } = mapPoint;
        setBounds(bounds);
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!pointId) {
      setBounds(undefined);
    }
  }, [pointId]);

  const map = useMapEvents({
    async click(e) {
      if (clickTimeout) return;

      const { lat, lng } = e.latlng;
      const {
        data: { data: mapPoint },
      } = await getMapPointCoordinates(lat, lng);

      if (!mapPoint) return;

      params.set("land", mapPoint.id);
      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      });

      const { bounds } = mapPoint;
      setBounds(bounds);

      const rectangleLat =
        Math.floor(bounds[0][0]) +
        (Math.floor(bounds[1][0]) - Math.floor(bounds[0][0])) / 2;
      const rectangleLng =
        Math.floor(bounds[0][1]) +
        (Math.floor(bounds[1][1]) - Math.floor(bounds[0][1])) / 2;

      map.flyTo(
        new LatLng(Math.floor(rectangleLat), Math.floor(rectangleLng)),
        4,
        {
          duration: CLICK_DURATION / 1000,
        }
      );

      clickTimeout = setTimeout(() => {
        clickTimeout = undefined;
      }, CLICK_DURATION);
    },
  });

  useEffect(() => {
    return () => {
      clearTimeout(clickTimeout);
    };
  }, []);

  if (!bounds) return null;

  return (
    <>
      <Rectangle bounds={bounds} weight={6} color="#fff" fillOpacity={0} />
    </>
  );
};

export default DashboardMapClick;
