import { Box, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { FC } from "react";

import { getSingleLevelOccupancy } from "api/levels";
import { levelsKeys } from "api/levels/queries";
import { LevelsType } from "api/levels/types";
import PreviewImageDefaultPNG from "assets/imgs/preview-image-default.png";

interface ILevelOccupancy {
  level: LevelsType | undefined;
}

const LevelOccupancy: FC<ILevelOccupancy> = ({ level }) => {
  const { data: levelOccupancy } = useQuery(
    levelsKeys.occupancy(level?._id ?? ""),
    async () => {
      const { data: res } = await getSingleLevelOccupancy(level?._id ?? "");
      return res.data;
    },
    {
      enabled: Boolean(level),
    }
  );

  return (
    <Stack direction="row">
      <img
        src={level?.previewImage || PreviewImageDefaultPNG}
        alt="land"
        height="400"
        width="100%"
        style={{ objectFit: "cover", maxHeight: "100%", maxWidth: "70%" }}
      />
      <Stack
        direction="column"
        textAlign="center"
        sx={{ width: "30%", p: 3 }}
        justifyContent="space-between"
      >
        <Box>
          <Typography fontWeight="bold" fontSize="20px" mb={1}>
            Rooms
          </Typography>
          <Typography fontSize="18px">{levelOccupancy?.rooms}</Typography>
        </Box>

        <Box>
          <Typography fontWeight="bold" fontSize="20px" mb={1}>
            Rooms Full
          </Typography>
          <Typography fontSize="18px">{levelOccupancy?.roomsFull}</Typography>
        </Box>

        <Box>
          <Typography fontWeight="bold" fontSize="20px" mb={1}>
            Visitors
          </Typography>
          <Typography fontSize="18px">
            {levelOccupancy?.visitors ?? 0}/{levelOccupancy?.capacity ?? 0}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

export default LevelOccupancy;
