import React from "react";
import { ImageOverlay } from "react-leaflet";

import { MapPointType } from "api/map/types";

const MapPointImage = ({
  image,
  bounds,
}: {
  image: string;
  bounds: MapPointType["bounds"];
}) => {
  return (
    <>
      <ImageOverlay
        url={image}
        bounds={bounds}
        opacity={1}
        zIndex={10}
        interactive
      />
    </>
  );
};

export default MapPointImage;
