import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { MutationStatus } from "@tanstack/react-query";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import slugify from "react-slugify";
import * as yup from "yup";

import FormikCheckbox from "components/forms/FormikCheckbox";
import FormikTextField from "components/forms/FormikTextField";

export type LevelFormValues = {
  name: string;
  slug: string;
  scene: string;
  private: boolean;
  roomCapacity: number;
};
interface ILevelForm {
  handleSubmit: (values: LevelFormValues) => void;
  handleClose: () => void;
  initialValues?: LevelFormValues;
  submitStatus?: MutationStatus;
}

const LevelForm: FC<ILevelForm> = ({
  handleSubmit,
  handleClose,
  initialValues = {
    name: "",
    slug: "",
    scene: "",
    private: false,
    roomCapacity: 15,
  },
  submitStatus,
}) => {
  const schema = yup.object({
    name: yup.string().required(),
    scene: yup.string().optional(),
    roomCapacity: yup.number().required(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const { setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <DialogContent>
          <FormikTextField
            name="name"
            label={<FormattedMessage id="GLOBAL.NAME" />}
            onChange={(e) => {
              setFieldValue("name", e.target.value);
              setFieldValue("slug", slugify(e.target.value));
            }}
          />
          <FormikTextField
            name="slug"
            label={<FormattedMessage id="GLOBAL.SLUG" />}
            disabled
          />
          <FormikTextField
            name="scene"
            label={<FormattedMessage id="LEVELS.LEVEL_FORM.SCENE" />}
          />
          <FormikTextField
            name="roomCapacity"
            label={<FormattedMessage id="LEVELS.LEVEL_FORM.ROOM_CAPACITY" />}
          />
          <FormikCheckbox
            name="private"
            label={<FormattedMessage id="LEVELS.LEVEL_FORM.PRIVATE" />}
            sx={{ ml: 1 }}
          />
        </DialogContent>

        <DialogActions>
          <Button
            // data-testid="new-edit-port-close-button"
            onClick={handleClose}
          >
            <FormattedMessage id="GLOBAL.CLOSE" />
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={submitStatus === "loading"}
          >
            <FormattedMessage id="GLOBAL.SAVE" />
          </LoadingButton>
        </DialogActions>
      </FormikForm>
    </FormikProvider>
  );
};

export default LevelForm;
