import { Box, Button, Grid, Stack } from "@mui/material";
import { MutationStatus, useQuery } from "@tanstack/react-query";
import { Form, FormikProvider, useFormik } from "formik";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

import { getMozillaMedia } from "api/mozilla";
import { mozillaKeys } from "api/mozilla/queries";

export type FeatureAdminAvatarsFormValues = {
  schema: {
    avatarSid: string | null;
  }[];
};

interface IFeatureAdminAvatarsForm {
  handleSubmit: (values: FeatureAdminAvatarsFormValues) => void;
  initialValues?: FeatureAdminAvatarsFormValues;
  submitStatus?: MutationStatus;
  handleOpenDelete: () => void;
}

const FeatureAdminAvatarsForm: FC<IFeatureAdminAvatarsForm> = ({
  initialValues = {
    schema: [],
  },
  handleSubmit,
  submitStatus,
  handleOpenDelete,
}) => {
  const schema = yup.object({
    schema: yup.array().of(
      yup.object().shape({
        avatarSid: yup.string().nullable().required(),
      })
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const { values, setFieldValue } = formik;

  const { data: avatars } = useQuery(
    mozillaKeys.mediaFiltered({ filter: "admin-avatars" }),
    async () => {
      let cursor: number | null = 1;
      const entries = [];

      while (cursor) {
        const { data: res }: any = await getMozillaMedia({
          filter: "admin-avatars",
          cursor,
        });
        entries.push(...res.entries);

        cursor = res.meta.next_cursor;
      }

      return entries;
    }
  );

  return (
    <>
      <FormikProvider value={formik}>
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            height: "calc(100% - 60px)",
            maxHeight: "100%",
          }}
        >
          <Stack
            direction="column"
            sx={{ p: { xs: 2, sm: 3 }, overflow: "scroll" }}
          >
            <Grid container spacing={2}>
              {avatars?.map((avatar: any) => {
                const avatarSelected = values.schema.some(
                  (single) => single.avatarSid === avatar.id
                );
                return (
                  <Grid item xs={2}>
                    <Box
                      onClick={() => {
                        if (avatarSelected) {
                          setFieldValue("schema", [
                            ...values.schema.filter(
                              (single) => single.avatarSid !== avatar.id
                            ),
                          ]);
                        } else {
                          setFieldValue("schema", [
                            ...values.schema,
                            { avatarSid: avatar.id },
                          ]);
                        }
                      }}
                      sx={{
                        width: "100%",
                        height: "185px",
                        background: `url('${avatar.images.preview.url}') no-repeat center center / contain`,
                        borderWidth: "6px",
                        borderStyle: "solid",
                        borderColor: (t) =>
                          avatarSelected ? t.palette.primary.main : "#2a2a2a",
                        borderRadius: "20px",
                        cursor: "pointer",
                        textAlign: "center",
                        color: "#fff",
                        p: 1,
                      }}
                    >
                      {avatar.id}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Stack>

          {/* FOOTER */}
          <Grid
            container
            columnSpacing={2}
            sx={{ mt: "auto", p: { xs: 2, sm: 3 }, pt: 0, minHeight: "84px" }}
          >
            <Grid item xs={3}>
              <Button
                sx={{ width: "100%" }}
                variant="contained"
                size="medium"
                type="submit"
              >
                <FormattedMessage id="GLOBAL.SAVE" />
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                sx={{ width: "100%" }}
                color="error"
                variant="contained"
                onClick={handleOpenDelete}
                size="medium"
              >
                <FormattedMessage id="GLOBAL.DELETE" />
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
};

export default FeatureAdminAvatarsForm;
