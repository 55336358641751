import { Box, Button } from "@mui/material";
import { MutationStatus } from "@tanstack/react-query";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

import FormikCheckbox from "components/forms/FormikCheckbox";
import FormikNumberField from "components/forms/FormikNumberField";

export type LoadBalancingFormValues = {
  numberOfUsers: number;
  usersPerInterval: number;
  interval: number;
  updateStatus: boolean;
  updateStatusInterval: number;
  reloadRooms: boolean;
  enableLogs: boolean;
};

interface ILoadBalancingForm {
  handleSubmit: (values: LoadBalancingFormValues) => void;
  initialValues?: LoadBalancingFormValues;
  submitStatus?: MutationStatus;
  running: boolean;
  handleStop: () => void;
}

const LoadBalancingForm: FC<ILoadBalancingForm> = ({
  handleSubmit,
  initialValues = {
    numberOfUsers: 20,
    usersPerInterval: 1,
    interval: 1000,
    updateStatus: true,
    updateStatusInterval: 60000,
    reloadRooms: true,
    enableLogs: true,
  },
  submitStatus,
  running,
  handleStop,
}) => {
  const schema = yup.object({
    numberOfUsers: yup.number().required(),
    interval: yup.number().required(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });
  const { values } = formik;
  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <Box sx={{ p: 2, pb: 0 }}>
          <FormikNumberField
            name="numberOfUsers"
            label={
              <FormattedMessage id="LEVEL.LOAD_BALANCING.NUMBER_OF_USERS" />
            }
          />
          <FormikNumberField
            name="usersPerInterval"
            label={
              <FormattedMessage id="LEVEL.LOAD_BALANCING.USERS_PER_INTERVAL" />
            }
          />
          <FormikNumberField
            name="interval"
            label={<FormattedMessage id="LEVEL.LOAD_BALANCING.INTERVAL" />}
            helperText={
              <FormattedMessage id="LEVEL.LOAD_BALANCING.INTERVAL.HELPER_TEXT" />
            }
            suffix="ms"
          />
          <FormikCheckbox
            name="updateStatus"
            label={<FormattedMessage id="LEVEL.LOAD_BALANCING.UPDATE_STATUS" />}
            sx={{ ml: 0.5, mt: -0.5 }}
          />
          {values.updateStatus && (
            <FormikNumberField
              name="updateStatusInterval"
              label={
                <FormattedMessage id="LEVEL.LOAD_BALANCING.UPDATE_STATUS_INTERVAL" />
              }
              helperText={
                <FormattedMessage id="LEVEL.LOAD_BALANCING.UPDATE_STATUS_INTERVAL.HELPER_TEXT" />
              }
              suffix="ms"
            />
          )}
          <FormikCheckbox
            name="reloadRooms"
            label={<FormattedMessage id="LEVEL.LOAD_BALANCING.RELOAD_ROOMS" />}
            sx={{ ml: 0.5, mt: -0.5 }}
          />
          <FormikCheckbox
            name="enableLogs"
            label={<FormattedMessage id="LEVEL.LOAD_BALANCING.ENABLE_LOGS" />}
            sx={{ ml: 0.5, mt: -0.5 }}
          />
        </Box>

        <Box sx={{ pt: 1, p: 2 }}>
          {!running ? (
            <Button type="submit" variant="contained" fullWidth>
              <FormattedMessage id="LEVEL.LOAD_BALANCING.RUN_TEST" />
            </Button>
          ) : (
            <Button
              variant="contained"
              color="error"
              fullWidth
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleStop();
              }}
            >
              <FormattedMessage id="LEVEL.LOAD_BALANCING.STOP" />
            </Button>
          )}
        </Box>
      </FormikForm>
    </FormikProvider>
  );
};

export default LoadBalancingForm;
