import { FileDownload as FileDownloadIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  downloadCourseAnalyticsCSV,
  getAiCourseChartAnalytics,
} from "api/analytics";
import { analyticsKeys } from "api/analytics/queries";
import { downloadFile } from "utils/downloadFile";
import { formatNumber } from "utils/formatNumber";

interface ICoursesAnalyticsChart {}

const CoursesAnalyticsChart: FC<ICoursesAnalyticsChart> = () => {
  const theme = useTheme();
  const [tab, setTab] = useState({
    type: "hour",
    last: 168,
  });

  const { formatMessage } = useIntl();

  const { data: analytics, isLoading } = useQuery(
    analyticsKeys.aiCourseAnalyticsFiltered({
      type: tab.type,
      last: tab.last,
    }),
    async () => {
      const res = await getAiCourseChartAnalytics({
        type: tab.type,
        last: tab.last,
      });

      return res;
    }
  );

  // @ts-ignore
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const chart = chartRef.current?.chart;

  useEffect(() => {
    if (!chart) return;

    if (isLoading) {
      return chart.showLoading(formatMessage({ id: "GLOBAL.CHARTS.LOADING" }));
    }

    if (!!analytics?.data && !analytics?.data.data?.length) {
      return chart.showLoading(formatMessage({ id: "GLOBAL.CHARTS.NO_DATA" }));
    }

    chart.hideLoading();
  }, [chart, isLoading, analytics?.data, formatMessage]);

  const dataVisitors = useMemo(
    () =>
      analytics?.data?.data?.map((data: any) => {
        return [new Date(data.date).getTime(), data.aiCourseCount];
      }) ?? [],
    [analytics?.data]
  );

  const options: Highcharts.Options = useMemo(
    () => ({
      time: {
        useUTC: false,
      },
      tooltip: {
        xDateFormat: "%A, %b %e, %H:%M:%S",
        valueDecimals: 0,
        valueSuffix: " Users",
      },
      colors: [theme.palette.primary.main],
      chart: {
        backgroundColor: "#272727",
        height: 350,
        style: {
          fontFamily: "ICTAvantGarde",
        },
      },
      title: {
        style: {
          display: "none",
        },
      },
      legend: {
        enabled: false,
      },
      yAxis: {
        title: {
          style: {
            display: "none",
          },
        },
      },
      xAxis: {
        type: "datetime",
      },
      series: [
        {
          type: "line",
          title: {
            style: {
              color: "#fff",
            },
          },
          name: "Courses Started",
          data: dataVisitors,
        },
      ],
    }),
    [dataVisitors, theme.palette]
  );

  return (
    <>
      <Tabs
        variant="fullWidth"
        value={`${tab.last}-${tab.type}`}
        onChange={(e, val) => {
          const [last, type] = val.split("-");
          setTab({
            last: parseInt(last),
            type,
          });
        }}
        sx={{ mb: 2, borderBottom: "1px solid #c3c3c3" }}
      >
        <Tab label="Today" value="24-hour" />
        <Tab label="Last 3 Days" value="72-hour" />
        <Tab label="This Week" value="168-hour" />
        <Tab label="This Month" value="744-hour" />
      </Tabs>
      <Box py={2} pr={3}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
      </Box>

      <Box sx={{ p: 2 }} textAlign="center">
        <Button
          // data-testid="new-port-button"
          variant="contained"
          // onClick={() => handleOpen()}
          sx={{
            backgroundColor: "#1D6F42",
            "&:hover": {
              backgroundColor: "#1D6F42",
            },
          }}
          onClick={async () => {
            const { data: file } = await downloadCourseAnalyticsCSV({
              type: tab.type,
              last: tab.last,
            });
            downloadFile(file, "AI Course Analytics", "csv");
          }}
        >
          <FileDownloadIcon sx={{ height: 20, my: 0.25, mr: 0.5 }} />
          <FormattedMessage id="GLOBAL.DOWNLOAD_CSV" />
        </Button>
      </Box>

      {/* Stats */}
      <Grid container spacing={2} sx={{ px: 5 }}>
        <Grid item xs={4}>
          <Box sx={{ py: 2 }}>
            <Typography fontWeight="bold" fontSize="20px" mb={1}>
              Total Courses Started
            </Typography>
            <Typography>
              {analytics?.data.meta?.courseCount
                ? formatNumber(analytics?.data.meta?.courseCount)
                : "-"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CoursesAnalyticsChart;
