import request from "api/request";
import { UsersType } from "api/users/types";
import {
  APICall,
  Paginated,
  QueryParamsType,
  normalizeQueryParams,
} from "api/utils";

import { RefferalUserType, VisitorsType } from "./types";

export const getAnalytics = ({
  land,
  type = "hour",
  last = 7,
}: {
  land?: string;
  type?: string;
  last?: number;
}): APICall<{ data: any; meta: any }> =>
  request({
    url: `/analytics`,
    method: "GET",
    params: {
      land,
      type,
      last,
    },
  });

export const getReferralsChartAnalytics = ({
  type = "hour",
  last = 7,
}: {
  type?: string;
  last?: number;
}): APICall<{ data: any; meta: any }> =>
  request({
    url: `/reward/analytics`,
    method: "GET",
    params: {
      type,
      last,
    },
  });

export const getAbadaChartAnalytics = ({
  type = "hour",
  last = 7,
}: {
  type?: string;
  last?: number;
}): APICall<{ data: any; meta: any }> =>
  request({
    url: `/reward/abada/analytics`,
    method: "GET",
    params: {
      type,
      last,
    },
  });

export const getAiCourseChartAnalytics = ({
  type = "hour",
  last = 7,
}: {
  type?: string;
  last?: number;
}): APICall<{ data: any; meta: any }> =>
  request({
    url: `/ai-course/analytics`,
    method: "GET",
    params: {
      type,
      last,
    },
  });

export const downloadAnalyticsCSV = ({
  land,
  type = "hour",
  last = 7,
}: {
  land?: string;
  type?: string;
  last?: number;
}): APICall<BlobPart> =>
  request({
    url: `/analytics/csv`,
    method: "GET",
    params: {
      land,
      type,
      last,
    },
  });

export const getAnalyticVisitors = (
  queryParams?: QueryParamsType<VisitorsType>
): APICall<Paginated<VisitorsType[]>> =>
  request({
    url: `/analytics/visitors${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const downloadAnalyticsVisitorsCSV = (
  queryParams?: QueryParamsType<VisitorsType>
): APICall<BlobPart> =>
  request({
    url: `/analytics/visitors/csv${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const getReferralsAnalytics = (
  queryParams?: QueryParamsType<RefferalUserType>
): APICall<Paginated<RefferalUserType[]>> =>
  request({
    url: `/reward/referred-users${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const getAbadaAnalytics = (
  queryParams?: QueryParamsType<any>
): APICall<Paginated<any[]>> =>
  request({
    url: `/reward/abada/analytics/users${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const getAiCourseAnalytics = (
  queryParams?: QueryParamsType<any>
): APICall<Paginated<any[]>> =>
  request({
    url: `/ai-course/user-courses-analytics${normalizeQueryParams(
      queryParams
    )}`,
    method: "GET",
  });

export const getFriendRequestAnalytics = ({
  type = "hour",
  last = 7,
}: {
  type?: string;
  last?: number;
}): APICall<{ data: any; meta: any }> =>
  request({
    url: `/friend-request/analytics`,
    method: "GET",
    params: {
      type,
      last,
    },
  });

export const downloadCourseAnalyticsCSV = ({
  type = "hour",
  last = 7,
}: {
  type?: string;
  last?: number;
}): APICall<BlobPart> =>
  request({
    url: `/ai-course/user-courses-analytics/csv`,
    method: "GET",
    params: {
      type,
      last,
    },
  });

export const getUserWalletAddressesAnalytics = (
  queryParams?: QueryParamsType<UsersType>
): APICall<Paginated<UsersType[]>> =>
  request({
    url: `/user/wallet/analytics${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const downloadUserWalletAddressesAnalyticsCSV = (): APICall<BlobPart> =>
  request({
    url: `/user/wallet/analytics/csv`,
    method: "GET",
  });

export const getTotalWalletAddresses = (): APICall<{ data: number }> =>
  request({
    url: `/user/total/wallet-addresses`,
    method: "GET",
  });
