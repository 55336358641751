import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { downloadFeatureCSV } from "api/features";
import { downloadFile } from "utils/downloadFile";

interface IFeatureVoiceInstallation {
  featureId: string;
  handleOpenDelete: () => void;
  schema: any[];
}

const FeatureVoiceInstallation: FC<IFeatureVoiceInstallation> = ({
  featureId,
  handleOpenDelete,
  schema,
}) => {
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 60px)",
        maxHeight: "100%",
      }}
    >
      <Box sx={{ padding: "15px", overflow: "scroll" }}>
        {schema.map((item) => (
          <Box
            sx={{
              background: "#e4e6eb",
              borderRadius: 1,
              mb: 2,
              maxWidth: "700px",
              ml: "auto",
              color: "#000",
              p: 2,
              pb: 1,
            }}
          >
            <Typography sx={{ mb: 0.5, fontWeight: "bold" }}>
              {item.username ?? "Guest"}
            </Typography>
            <Typography sx={{ mb: 1 }}>{item.message}</Typography>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography sx={{ mr: 2, fontSize: "14px" }}>
                Time: {dayjs(item.createdAt).format("DD.MM.YYYY HH:mm:ss")}
              </Typography>
            </Stack>
          </Box>
        ))}
      </Box>
      {/* FOOTER */}
      <Grid
        container
        columnSpacing={2}
        sx={{ mt: "auto", p: { xs: 2, sm: 3 }, pt: 0, minHeight: "84px" }}
      >
        <Grid item xs={3}>
          <Button
            sx={{ width: "100%" }}
            color="error"
            variant="contained"
            onClick={handleOpenDelete}
            size="medium"
          >
            <FormattedMessage id="GLOBAL.DELETE" />
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            sx={{ width: "100%" }}
            color="success"
            variant="contained"
            onClick={async () => {
              const { data: file } = await downloadFeatureCSV(featureId);
              downloadFile(file, "Feature-Voice_Installation", "csv");
            }}
            size="medium"
          >
            <FormattedMessage id="GLOBAL.EXPORT" />
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default FeatureVoiceInstallation;
