import { PeopleOutline as PeopleOutlineIcon } from "@mui/icons-material";
import { Dialog, DialogTitle, Divider, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { FormattedMessage } from "react-intl";

import { editLandHosts as editLandHostsAPI } from "api/lands";
import { landsKeys } from "api/lands/queries";
import { EditLandHostsType, LandsType } from "api/lands/types";

import LandHostsForm, { LandHostsFormValues } from "./LandHostsForm";

interface AddLandHostsModalProps {
  isOpen: boolean;
  handleClose: () => void;
  land: LandsType | undefined;
}

const AddLandHostsModal = ({
  isOpen,
  handleClose,
  land,
}: AddLandHostsModalProps) => {
  const queryClient = useQueryClient();

  const initialValues: LandHostsFormValues = {
    hosts: land?.hosts?.map((host) => host?._id) ?? [],
  };

  const { mutateAsync: editLand, status } = useMutation(
    async (data: EditLandHostsType) => {
      const res = await editLandHostsAPI(data);
      await queryClient.invalidateQueries(landsKeys.list());
      return res.data;
    }
  );

  const handleSubmit = async (values: LandHostsFormValues) => {
    const mappedValues: EditLandHostsType = {
      _id: land!._id,
      ...values,
    };
    await editLand(mappedValues, {
      onSuccess: () => handleClose(),
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <PeopleOutlineIcon sx={{ mr: 1 }} />
          <FormattedMessage id="LANDS.UPDATE_HOSTS.TITLE" />
        </Stack>
      </DialogTitle>
      <Divider />

      <LandHostsForm
        submitStatus={status}
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
      />
    </Dialog>
  );
};

export default AddLandHostsModal;
