import { Edit as EditIcon } from "@mui/icons-material";
import { Dialog, DialogTitle, Divider, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";

import { updateReward as updateRewardAPI } from "api/rewards";
import { rewardsKeys } from "api/rewards/queries";
import { EditRewardItemType, RewardItemType } from "api/rewards/types";
import { QueryParamsType } from "api/utils";
import RewardForm, {
  RewardFormValues,
} from "containers/rewards/RewardsTable/components/RewardForm";

interface EditRewardModalProps {
  isOpen: boolean;
  handleClose: () => void;
  reward: RewardItemType | undefined;
  additionalFilters?: QueryParamsType<RewardItemType>;
}

const EditRewardModal = ({
  isOpen,
  handleClose,
  reward,
  additionalFilters,
}: EditRewardModalProps) => {
  const queryClient = useQueryClient();

  const initialValues: RewardFormValues = {
    title: reward?.title || "",
    description: reward?.description || "",
    reward: reward?.reward || 0,
    quantity: reward?.quantity || 0,
    imgUrl: reward?.imgUrl || "",
    type: reward?.type || "",
    region: reward?.region || "",
  };

  const { mutateAsync: updateReward, status } = useMutation(
    async (data: EditRewardItemType) => {
      const res = await updateRewardAPI(data);
      await queryClient.invalidateQueries(rewardsKeys.list());
      return res.data;
    }
  );

  const handleSubmit = async (values: RewardFormValues) => {
    const mappedValues: EditRewardItemType = {
      _id: reward!._id,
      ...values,
    };

    await updateReward(mappedValues, {
      onSuccess: () => handleClose(),
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <EditIcon sx={{ mr: 1 }} />
          Edit Reward
        </Stack>
      </DialogTitle>
      <Divider />

      <RewardForm
        submitStatus={status}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        initialValues={initialValues}
      />
    </Dialog>
  );
};

export default EditRewardModal;
