import { Search as SearchIcon } from "@mui/icons-material";
import { Grid, InputAdornment, TextField } from "@mui/material";
import React, { FC } from "react";
import {
  FormattedMessage,
  // useIntl
} from "react-intl";
import { CellProps } from "react-table";

import { getAiCourseAnalytics } from "api/analytics";
import { analyticsKeys } from "api/analytics/queries";
import { QueryParamsType } from "api/utils";
import Table, { TableColumn } from "components/Table";
import { dateTimeFormatter } from "components/Table/formatters/dateTimeFormatter";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";

import { useCoursesAnalyticsFilter } from "./useCoursesAnalyticsFilter";

interface ICoursesAnalyticsTable {
  additionalFilters?: QueryParamsType<any>;
}

const CoursesAnalyticsTable: FC<ICoursesAnalyticsTable> = ({
  additionalFilters,
}) => {
  // const intl = useIntl();

  const { filters, searchField } = useCoursesAnalyticsFilter(additionalFilters);

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    analyticsKeys.aiCourseAnalyticsFiltered(filters),
    getAiCourseAnalytics,
    filters
  );

  console.log("data", data);

  const columns: TableColumn<any>[] = [
    {
      id: "userName",
      Header: "User Name",
      disableSortBy: true,
      Cell: ({ row }: CellProps<any>) => <>{row.original.userName}</>,
    },
    {
      id: "title",
      Header: "Course",
      disableSortBy: true,
      Cell: ({ row }: CellProps<any>) => <>{row.original.title}</>,
    },
    {
      accessor: "score",
      Header: "Score",
      Cell: ({ row }: CellProps<any>) => (
        <>
          {row.original.score} / {row.original.total}
        </>
      ),
      disableSortBy: true,
    },
    {
      accessor: "progress",
      Header: "Progress",
      Cell: ({ row }: CellProps<any>) => <>{row.original.progress}%</>,
      disableSortBy: true,
    },
    {
      accessor: "generatedCertificate",
      Header: "Generated certificate",
      Cell: ({ row }: CellProps<any>) => (
        <>{row.original.generatedCertificate ? "Yes" : "No"}</>
      ),
      disableSortBy: true,
    },
    {
      accessor: "certificateMinted",
      Header: "Minted certificate",
      Cell: ({ row }: CellProps<any>) => (
        <>{row.original.certificateMinted ? "Yes" : "No"}</>
      ),
      disableSortBy: true,
    },
    {
      accessor: "createdAt",
      Header: "Started Time",
      Cell: ({ value }: CellProps<any>) => <>{dateTimeFormatter(value)}</>,
    },
  ];

  return (
    <>
      <Grid container sx={{ pt: 1, px: 2 }}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={"Search by user name"}
            {...searchField}
          />
        </Grid>
      </Grid>
      <Table
        data={data}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
        initialState={{
          // pageSize: 100,
          sortBy: [
            {
              id: "createdAt",
              desc: true,
            },
          ],
        }}
      />
    </>
  );
};

export default CoursesAnalyticsTable;
