export const milisecondsFormatter = (duration: number | null | undefined) => {
  if (!duration) return "-";

  let seconds: string | number = Math.floor((duration / 1000) % 60),
    minutes: string | number = Math.floor((duration / (1000 * 60)) % 60),
    hours: string | number = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  if (minutes <= 0) {
    return seconds + " sec";
  } else if (hours <= 0) {
    return `${minutes} min ${seconds} sec`;
  } else {
    return `${hours} hours ${minutes} min ${seconds} sec`;
  }
};
