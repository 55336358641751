import { Paper } from "@mui/material";

import CoursesAnalyticsChart from "./CoursesAnalyticsChart/CoursesAnalyticsChart";
import CoursesAnalyticsChartHeader from "./CoursesAnalyticsChartHeader/CoursesAnalyticsChartHeader";
import CoursesAnalyticsHeader from "./CoursesAnalyticsHeader/CoursesAnalyticsHeader";
import CoursesAnalyticsTable from "./CoursesAnalyticsTable";

const CoursesAnalyticsPage = () => {
  return (
    <>
      <Paper>
        <CoursesAnalyticsChartHeader />
        <CoursesAnalyticsChart />
      </Paper>

      <Paper sx={{ mt: 2 }}>
        <CoursesAnalyticsHeader />
        <CoursesAnalyticsTable />
      </Paper>
    </>
  );
};

export default CoursesAnalyticsPage;
