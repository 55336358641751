import { Circle, Search as SearchIcon } from "@mui/icons-material";
import { Chip, Grid, InputAdornment, Link, TextField } from "@mui/material";
import { keyframes } from "@mui/system";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { CellProps } from "react-table";

import { LevelsType } from "api/levels/types";
import { getRooms } from "api/rooms";
import { roomsKeys } from "api/rooms/queries";
import { RoomsType } from "api/rooms/types";
import { hasPermissions } from "components/stores/UserStore";
import Table, { TableColumn } from "components/Table";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";
import { useModal } from "utils/hooks/useModal";

import DeleteRoomModal from "./components/DeleteRoomModal";
import EditRoomModal from "./components/EditRoomModal";
import RoomsActionsFormatter from "./formatters/RoomsActionsFormatter";
import { useRoomsFilter } from "./useRoomsFilter";

interface IRoomsTable {
  level: LevelsType | undefined;
}

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.7; }
  100% { opacity: 1; }
`;

const RoomsTable: FC<IRoomsTable> = ({ level }) => {
  const intl = useIntl();

  const { filters, searchField } = useRoomsFilter({
    filter: [
      {
        field: "level",
        operator: "is",
        value: level?._id ?? "",
      },
    ],
  });

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    roomsKeys.listFiltered(filters),
    getRooms,
    filters
  );

  const {
    isOpen: isEditRoomOpen,
    handleClose: handleCloseEditRoom,
    handleOpen: handleOpenEditRoom,
    context: editRoomContext,
  } = useModal<RoomsType>();

  const {
    isOpen: isDeleteRoomOpen,
    handleClose: handleCloseDeleteRoom,
    handleOpen: handleOpenDeleteRoom,
    context: deleteRoomContext,
  } = useModal<RoomsType>();

  const columns: TableColumn<RoomsType>[] = [
    {
      id: "link",
      accessor: "hash",
      Header: intl.formatMessage({ id: "ROOMS.TABLE.LINK" }),
      Cell: ({ row }: CellProps<RoomsType>) => (
        <Link
          href={`/${level?.experience.land.slug}/${level?.experience.slug}/${level?.slug}?room=${row.original.hash}`}
        >
          {row.original.hash}/{row.original.name}
        </Link>
      ),
    },
    {
      id: "occupancy",
      Header: intl.formatMessage({ id: "ROOMS.TABLE.OCCUPANCY" }),
      Cell: ({ row }: CellProps<RoomsType>) => {
        const full =
          row.original.capacity <= (row.original.visitors?.length ?? 0);

        return (
          <Chip
            icon={
              <Circle
                color="success"
                sx={{ animation: `${blink} 1s linear infinite` }}
              />
            }
            sx={{
              p: 0,
              height: "29px",
              color: "#FFF",
              ".MuiChip-label": {
                paddingTop: "3px",
              },
            }}
            label={`${row.original.visitors?.length ?? 0}/${
              row.original.capacity
            }`}
            color={full ? "error" : "success"}
          ></Chip>
        );
      },
    },
    {
      accessor: "visitors",
      Header: intl.formatMessage({ id: "ROOMS.TABLE.VISITORS" }),
      Cell: ({ value }: CellProps<RoomsType>) => (
        <>
          {value
            ?.map((visitor: any) => visitor.username ?? visitor.uid)
            .join(", ")}
        </>
      ),
    },
    {
      id: "actions",
      Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
      Cell: (cell: CellProps<RoomsType>) => (
        <RoomsActionsFormatter
          {...cell}
          handleOpenEdit={handleOpenEditRoom}
          handleOpenDelete={handleOpenDeleteRoom}
        />
      ),
      align: "right",
    },
  ];

  const { levelId } = useParams<{ levelId: string }>();

  return (
    <>
      <Grid container sx={{ pt: 1, px: 2 }}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={<FormattedMessage id="ROOMS.TABLE.SEARCH_BY" />}
            {...searchField}
          />
        </Grid>
      </Grid>
      <Table
        data={data}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
      />

      {hasPermissions("update:levels") && (
        <>
          <DeleteRoomModal
            isOpen={isDeleteRoomOpen}
            handleClose={handleCloseDeleteRoom}
            room={deleteRoomContext}
          />
        </>
      )}

      {hasPermissions("update:levels") && (
        <>
          <EditRoomModal
            isOpen={isEditRoomOpen}
            handleClose={handleCloseEditRoom}
            room={editRoomContext}
            levelId={levelId ?? ""}
          />
        </>
      )}
    </>
  );
};

export default RoomsTable;
