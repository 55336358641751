const roomsKeys = {
  all: ["rooms"] as const,
  list: () => [...roomsKeys.all, "list"],
  listFiltered: (filters: Record<string, unknown>) => [
    ...roomsKeys.list(),
    filters,
  ],
  details: (id: string) => [...roomsKeys.all, "detail", id] as const,
};

export { roomsKeys };
